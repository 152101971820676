import React from "react";
import styled from "@emotion/styled";

import { Grid, Card as MuiCard, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../../../components/layouts/header/Header";
import UserHeader from "../../../components/layouts/userHeader/UserHeader";
import { EmptySigningCard } from "./EmptySigningCard";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function SigningContracts() {
  const { currentEmployee } = useSelector((state) => state.commonThings);
  const headerData = {
    title: "Sənədlərin imzalanması sorğuları",
    variant: "h3",
  };

  return (
    <React.Fragment>
      <Grid sx={styles.container}>
        <Grid>
          <Header data={headerData} />
        </Grid>
        <UserHeader employee={currentEmployee} />
      </Grid>

      <ToastContainer />

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptySigningCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SigningContracts;

const styles = {
  container: {
    display: "flex",
    gap: 3,
    justifyContent: "space-between",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
