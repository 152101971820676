/* eslint-disable prettier/prettier */
import {
  Typography,
  Grid,
  Button,
  Dialog,
  Box,
  TextField,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Autocomplete,
} from "@mui/material";

// material icons
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";

import { LEAVES_STATUS, OPERATORS_CONTAINING } from "../../constants";

import { getDescendantProp, normalizeDate } from "../../utils/helperFunctions";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllEmployees } from "../../api/api";
import Column from "../../models/table/Column";
import Table from "../../components/table/Table";
import {
  createNewUnknownReason,
  deleteUnknownReason,
  downloadUnknownReasonsDatagridDatas,
  getUnknownReason,
  getUnknownReasons,
  updateUnknownReason,
} from "./services";
import Operator from "../../models/table/Operator";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import { downloadFile } from "../../utils/downloadCSV";
import { useNavigate } from "react-router-dom";

export default function UnknownReason() {
  const navigate = useNavigate();

  const [show_Accordion, set_show_Accordion] = useState(false);
  const [isLoadingGS, setIsLoadingGS] = useState(false);
  const [employee, set_employee] = useState({});
  const [create_btn_disable, set_create_btn_disable] = useState(false);
  const [leave_state, set_leave_state] = useState({
    employee: "",
    startDate: "",
  });
  const [updateDatas, setUpdateDatas] = useState({
    id: "",
    startDate: "",
    endDate: "",
    noteAndReason: "",
  });
  const [searchValue, setSearchValue] = useState("");

  // secilen namelum sebeb id si
  const [dataID, setDataID] = useState("");

  // dialogs
  const [deleteDataDialogue, setDeleteDataDialogue] = useState(false);
  const [editDataDialogue, setEditDataDialogue] = useState(false);
  const [editStatusDialogue, setEditStatusDialogue] = useState(false);

  // datagrid states
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    set_create_btn_disable(
      leave_state?.startDate === "" || leave_state?.employee === " "
    );
  }, [leave_state]);
  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    paginationFetchingHandler();
  }, [filters, limit, offset]);

  const paginationFetchingHandler = async () => {
    if (filters.length) {
      const trimmedFilters = filters.map((item) => {
        if (Array.isArray(item.value)) {
          return {
            ...item,
            value: item.value.map((e) => e.trim()),
          };
        }

        return {
          ...item,
          value: item.value.trim(),
        };
      });
      const encodedFilter = encodeURIComponent(JSON.stringify(trimmedFilters));
      await getResponse(encodedFilter);
    } else {
      await getResponse();
    }
  };

  // api sorgulari

  // butun namelum sebebler sorgusu
  const getResponse = async (filters = undefined) => {
    try {
      setIsLoading(true);

      const { data } = await getUnknownReasons({
        limit: limit || 5,
        offset: offset || 0,
        filters,
      });
      setTotal(data.total);
      setRowData(
        data.data.map((item, index) => {
          return {
            ...item,
          };
        })
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  // yeni namelum sebeb yaradilma sorgusu
  const createNewUnknownReasonData = async (data) => {
    await createNewUnknownReason(data);
    set_leave_state({
      employee: "",
      startDate: "",
    });
    set_show_Accordion(false)
    paginationFetchingHandler();
  };

  // namelum sebeb silinmesi sorgusu
  const deleteData = async (data) => {
    await deleteUnknownReason(data);
    await getResponse();
  };

  //edit ucun namelum sebeb melumatlari elde etme sorgusu
  const getDataForEdit = async (id) => {
    const data = await getUnknownReason(id);
    const mainData = data?.data?.data;
    setUpdateDatas({
      id: mainData._id,
      startDate: mainData?.startDate,
      endDate: mainData?.endDate,
      noteAndReason: mainData?.noteAndReason,
      status: mainData?.status,
    });

    setEditDataDialogue(true);
  };

  //status edit ucun namelum sebeb melumatlari elde etme sorgusu
  const getDataForEditStatus = async (id) => {
    const data = await getUnknownReason(id);
    const mainData = data?.data?.data;
    setUpdateDatas({
      id: mainData._id,
      startDate: mainData?.startDate,
      endDate: mainData?.endDate,
      noteAndReason: mainData?.noteAndReason,
      status: mainData?.status,
    });

    setEditStatusDialogue(true);
  };

  // namelum sebeb yenileme sorgusu
  const editData = async (data) => {
    await updateUnknownReason(data);
    await getResponse();
    setEditDataDialogue(false);
    setEditStatusDialogue(false);
  };

  //emekdaslar siyahisi elde etme sorgusu
  const getEmployees = async () => {
    const employeeFilter = [
      {
        column: "status",
        operator: "equalsTo",
        value: "active",
      },
    ];
    try {
      const response = await getAllEmployees(
        "",
        "",
        encodeURIComponent(JSON.stringify(employeeFilter))
      );
      if (response?.data?.employees) {
        let emp = response?.data?.employees.map((e) => ({
          firstname: e?.firstname,
          lastname: e?.lastname,
          fathername: e?.fathername,
          id: e?._id,
        }));
        set_employee(emp);
      }
    } catch (error) {
      console.log("general statistics", error);
    }
  };

  const operatorGeneratorHandler = (row, column) => {
    const operators = [
      new Operator({
        icon: <MedicalInformationIcon />,
        onClick: () => {
          navigate(
            `/leaves/bulletincreate?unknownreason=${row._id}&employee=${row.employee.id}&startDate=${row.startDate}`
          );
        },
        type: 0,
      }),
      new Operator({
        icon: <EditIcon />,
        onClick: () => {
          getDataForEdit(row._id);
        },
        type: 0,
      }),
      new Operator({
        icon: (
          <span style={{ margin: "5px", display: "flex" }}>Statusu dəyiş</span>
        ),
        onClick: () => {
          getDataForEditStatus(row._id);
        },
        type: 0,
      }),
      new Operator({
        icon: <DeleteIcon />,
        onClick: () => {
          setDeleteDataDialogue(true);
          setDataID(row._id);
        },
        hideCondition: row.isUsedInStaff,
        type: 0,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };

  // cedvelin excell file ni yukle

  const downlaodHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(JSON.stringify([...filters]));
      const resp = await downloadUnknownReasonsDatagridDatas({
        columns: encodeColumns,
        filters: encodeFilters,
      });
      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const allContColumn = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      widthOfColumn: 200,
      renderCell: operatorGeneratorHandler,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "startDate",
      headerName: "Başlama tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "endDate",
      headerName: "Bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "daysOutOfWork",
      headerName: "Müddət",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "noteAndReason",
      headerName: "Qeyd/Səbəb",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.SINGLE_OPTION,
      renderCell: (row, column) => {
        let status = "";
        if (row.status === true) {
          status = "Üzrlü";
        } else if (row.status === false) {
          status = "Üzrsüz";
        }
        return (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: row.status ? "green" : "red",
              borderRadius: "4px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {status}
          </div>
        );
      },
    }),
  ];

  const headerData = {
    title: "Naməlum səbəbdən işə çıxmama",
    variant: "h3",
  };

  return (
    <React.Fragment>
      <Box>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
        </Grid>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => set_show_Accordion(!show_Accordion)}
            variant="contained"
            sx={{ height: "100%" }}
          >
            <Typography>Səbəb yarat</Typography>
          </Button>
        </div>
        {show_Accordion && (
          <>
            <div className="d-flex">
              <div className="w-25 ">
                <div>Əməkdaş</div>
                <div className="mt-2">
                  <Autocomplete
                    sx={{ width: "100%" }}
                    options={employee}
                    // onChange={onSaveEmployeeId}
                    renderInput={(params) => (
                      <TextField {...params} label="Əməkdaşı seçin" />
                    )}
                    getOptionLabel={(option) =>
                      `${option.firstname} ${option?.lastname} ${option?.fathername}`
                    }
                    inputValue={searchValue}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onInputChange={(event, newInputValue) => {
                      setSearchValue(newInputValue);
                    }}
                    onChange={(e, newInputValue) => {
                      set_leave_state({
                        ...leave_state,
                        employee: newInputValue?.id ? newInputValue.id : "",
                      });
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {`${option.firstname} ${option.lastname} ${option.fathername}`}
                        </li>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="w-25 ms-3">
                <div>Başlama tarixi</div>
                <div className="mt-2">
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={leave_state?.startDate || null}
                    // maxDate={new Date()}
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    onChange={(date) =>
                      set_leave_state({ ...leave_state, startDate: date })
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="w-25 mt-3">
              <Button
                onClick={() => {
                  createNewUnknownReasonData(leave_state);
                  set_show_Accordion(!show_Accordion)
                }}
                variant="contained"
                disabled={create_btn_disable}
              >
                Yarat
              </Button>
            </div>
          </>
        )}
        <div className="mt-4">
          <Table
            columns={allContColumn}
            data={rowData}
            totalRows={total}
            limit={limit}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            setLimit={(value) => setLimit(value)}
            isLoading={isLoading}
            applyFiltersHandler={(filters) => setFilters(filters)}
            cancelAppliedFilters={() => setFilters([])}
            downloadHandler={(cols) => downlaodHandler(cols)}
          />
        </div>
        {/* edit status dialog */}
        <Dialog
          open={editStatusDialogue}
          onClose={() => setEditStatusDialogue(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Status dəyişdirilsin?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setEditStatusDialogue(false);
              }}
            >
              Xeyr
            </Button>
            <Button
              onClick={() => {
                editData({ ...updateDatas, status: !updateDatas.status });
                setEditStatusDialogue(false);
              }}
            >
              Bəli
            </Button>
          </DialogActions>
        </Dialog>
        {/* delete dialog */}
        <Dialog
          open={deleteDataDialogue}
          onClose={() => setDeleteDataDialogue(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Məlumat silinsin?</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteDataDialogue(false);
              }}
            >
              Xeyr
            </Button>
            <Button
              onClick={() => {
                setDeleteDataDialogue(false);
                deleteData(dataID);
              }}
            >
              Bəli
            </Button>
          </DialogActions>
        </Dialog>
        {/* edit dialog */}
        <Dialog
          open={editDataDialogue}
          onClose={() => setEditDataDialogue(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ƏQMİ məlumatlarını dəyiş
          </DialogTitle>
          {updateDatas && (
            <DialogContent
              style={{
                padding: "10px 20px",
                // width: "100vh",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                  display: "column",
                  columnGap: "30px",
                  width: "100%",
                }}
              >
                <div style={styles.formContainer}>
                  <div>
                    <div>Başlama tarixi</div>
                    <div>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={updateDatas?.startDate || null}
                        // maxDate={new Date()}
                        // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                        onChange={(date) => {
                          setUpdateDatas({
                            ...updateDatas,
                            startDate: date,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <div>Bitmə tarixi</div>
                    <div>
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={updateDatas?.endDate || null}
                        // maxDate={new Date()}
                        // minDate={new Date(new Date().getTime() - 86400000 * 30)}
                        onChange={(date) =>
                          setUpdateDatas({ ...updateDatas, endDate: date })
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div style={styles.formContainer}>
                  <div style={{ width: "100%" }}>
                    <div>Səbəb/Qeyd</div>
                    <div>
                      <TextField
                        fullWidth
                        value={updateDatas?.noteAndReason}
                        onChange={(e) => {
                          setUpdateDatas({
                            ...updateDatas,
                            noteAndReason: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      height: "100%",
                    }}
                  >
                    <Grid sx={styles.downBtnCont}>
                      <Button
                        onClick={() => {
                          editData(updateDatas);
                        }}
                        variant="contained"
                      >
                        Dəyişiklik et
                      </Button>
                    </Grid>
                  </div>
                </div>
              </div>
            </DialogContent>
          )}
        </Dialog>
      </Box>
    </React.Fragment>
  );
}

const styles = {
  formContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    marginBottom: "20px",
  },
  downloadBtn: {
    margin: "auto",
    width: "100%",
    backgroundColor: "#4279d4",
    borderRadius: 1,
    color: "rgba(0,0,0,0.7)",
    "&:hover": {
      backgroundColor: "grey",
      color: "#fff",
    },
  },
};
