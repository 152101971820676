import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

// Material UI
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
} from "@mui/material";
import { Add as AddIcon, Visibility } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

//  Components
import Header from "../../../components/layouts/header/Header";
import Breadcrumb from "../../../components/layouts/breadcrumb/Breadcrumb";
import BasicTable from "../../../components/layouts/table/BasicTable";

// Services
import * as templateServices from "../../../services/documentations/TemplateServices";
import { useTheme } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";
import { DOCUMENT_MODELS, OPERATORS_CONTAINING } from "../../../constants";
import { getDescendantProp } from "../../../utils/helperFunctions";
import { downloadFile } from "../../../utils/downloadCSV";
import Operator from "../../../models/table/Operator";
import OperatorGenerator from "../../../components/table/OperatorGenerator";
import SimpleDataTable from "../../../components/layouts/dataTable/SimpleDataTable";
import TemplateEdit from "./edit/TemplateEdit";
import TemplateView from "./view/TemplateView";

// const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Templates = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [typeData, setTypeData] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [value, setValue] = useState(0);

  //Dialog

  const [templateID, setTemplateID] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [viewStaticDialog, setViewStaticDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);

  // static data table
  const [staticLoading, setStaticLoading] = useState(false);
  const [staticData, setStaticData] = useState([]);
  const [staticOffset, setStaticOffset] = useState(0);
  const [staticLimit, setStaticLimit] = useState(5);
  const [staticTotal, setStaticTotal] = useState();
  const [staticFilters, setStaticFilters] = useState([]);

  // dynamic data table
  const [dynamicLoading, setDynamicLoading] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  const [dynamicOffset, setDynamicOffset] = useState(0);
  const [dynamicLimit, setDynamicLimit] = useState(5);
  const [dynamicTotal, setDynamicTotal] = useState();
  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [deleteAction, setDeleteAction] = useState(false);

  useEffect(() => {
    if (staticFilters.length) {
      fetchDataStatic([...staticFilters]);
    } else {
      fetchDataStatic();
    }
  }, [staticOffset, staticLimit, staticFilters]);

  useEffect(() => {
    if (dynamicFilters.length) {
      fetchDataDynamic([...dynamicFilters]);
    } else {
      fetchDataDynamic();
    }
  }, [dynamicOffset, dynamicLimit, dynamicFilters, deleteAction]);

  useEffect(() => {
    const path = window.location.pathname;
    const tabIndex = path[path.length - 1] === "1" ? 1 : 0;
    setValue(tabIndex);
  }, []);

  const fetchDataStatic = async (filter = undefined) => {
    if (filter) {
      try {
        setStaticLoading(true);
        const { records, total } = await templateServices.getTypesForFilter({
          limit: staticLimit ? staticLimit : 5,
          skip: staticOffset,
          filter: filter
            ? encodeURIComponent(
                JSON.stringify([...filter, { isPredefined: true }])
              )
            : [],
        });
        setStaticTotal(total);

        setStaticData(records ? records : []);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setStaticLoading(false);
      }
    } else {
      try {
        setStaticLoading(true);
        const { contractTypes, total } = await templateServices.getTypes({
          limit: staticLimit ? staticLimit : 5,
          skip: staticOffset,
          isPredefined: true,
          filter: filter ? encodeURIComponent(JSON.stringify([...filter])) : [],
        });

        setStaticTotal(total);

        setStaticData(contractTypes ? contractTypes : []);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setStaticLoading(false);
      }
    }
  };

  const fetchDataDynamic = async (filter = undefined) => {
    if (filter) {
      try {
        setDynamicLoading(true);
        const { records, total } = await templateServices.getTypesForFilter({
          limit: dynamicLimit ? dynamicLimit : 5,
          skip: dynamicOffset,
          filter: filter
            ? encodeURIComponent(
                JSON.stringify([...filter, { isPredefined: false }])
              )
            : [],
        });

        setDynamicTotal(total);

        setDynamicData(records ? records : []);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setDynamicLoading(false);
      }
    } else {
      try {
        setDynamicLoading(true);
        const { contractTypes, total } = await templateServices.getTypes({
          limit: dynamicLimit ? dynamicLimit : 5,
          skip: dynamicOffset,
          isPredefined: false,
          filter: filter ? encodeURIComponent(JSON.stringify([...filter])) : [],
        });

        setDynamicTotal(total);

        setDynamicData(contractTypes ? contractTypes : []);
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setDynamicLoading(false);
      }
    }
  };

  // const handleOffsetLimit = (pageSize, tableKey) => {
  //   if (!pageSize) return;
  //   const value = pageSize.page * pageSize.pageSize;
  //   setOffset((prev) => ({ ...prev, [tableKey]: value }));
  //   setLimit(parseInt(pageSize.pageSize));
  //   setIsUpdated(!isUpdated);
  // };

  // const handleDetails = async (id) => {
  //   try {
  //     const data = await templateServices.getTypeById(id);
  //     if (data) {
  //       setOpen(true);
  //       setTypeData(data);
  //     }
  //   } catch (error) {
  //     console.log("error: ", error);
  //   }
  // };

  const handleUpdate = async (id) => {
    navigate(`/documentation/template/edit/${id}`);
    setValue(1);
  };

  // const handleDelete = async (id) => {
  //   try {
  //     const willDelete = await swal({
  //       title: "Əminsiniz mi?",
  //       text: "Bu məlumatı silmək üçün əminsiniz mi?",
  //       icon: "warning",
  //       dangerMode: true,
  //       buttons: ["Ləğv et", "Sil"],
  //     });

  //     if (willDelete) {
  //       const response = await templateServices.deleteTypes(id);
  //       if (response) {
  //         setIsUpdated(!isUpdated);
  //         swal("Silindi!", "Seçdiyiniz məlumat uğurla silindi!", "success");
  //       }
  //     }
  //   } catch (error) {
  //     console.log("error: ", error);
  //   }
  // };

  const handleChangeTab = (e, value) => {
    setValue(value);
    navigate(`/documentation/template/${value}`);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const headerData = {
    title: "Şablon məlumatları",
    variant: "h3",
  };

  // columns

  const staticColumn = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      renderCell: (row, column) => {
        const data = row;

        const operators = [
          new Operator({
            icon: <Visibility />,
            onClick: () => {
              setTemplateID(data._id);
              setViewStaticDialog(true);
              // setDataID(row._id);
              // setIsShow(true);
            },
            type: 0,
          }),
        ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
    new Column({
      path: "label",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "code",
      headerName: "Kod",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "type",
      headerName: "Tip",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: [
        { key: "order", name: "Əmr" },
        { key: "agreement", name: "Müqavilə" },
        { key: "service", name: "Xidməti yazı" },
        { key: "explanation", name: "İzahat" },
      ],

      getValue: (row, column) =>
        DOCUMENT_MODELS[getDescendantProp(row, column.path)],
    }),
    new Column({
      path: "templateName",
      headerName: "Faylın adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
  ];

  const dynamicColumn = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      renderCell: (row, column) => {
        const data = row;
        const operators = [
          new Operator({
            icon: <Visibility />,
            onClick: () => {
              setTemplateID(data._id);
              setViewDialog(true);
              // setDataID(row._id);
              // setIsShow(true);
            },
            type: 0,
          }),
          new Operator({
            icon: <EditIcon />,
            onClick: () => {
              setTemplateID(data._id);
              setEditDialog(true);
            },
            type: 0,
          }),
          new Operator({
            icon: <DeleteIcon />,
            onClick: () => {
              setTemplateID(data._id);
              setOpenDialog(true);
              // deleteTemplate(data);
            },
            type: 0,
          }),
        ];

        return <OperatorGenerator operators={operators} />;
      },
    }),
    new Column({
      path: "label",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "code",
      headerName: "Kod",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "type",
      headerName: "Tip",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: [
        { key: "order", name: "Əmr" },
        { key: "agreement", name: "Müqavilə" },
        { key: "service", name: "Xidməti yazı" },
        { key: "explanation", name: "İzahat" },
      ],

      getValue: (row, column) =>
        DOCUMENT_MODELS[getDescendantProp(row, column.path)],
    }),
    new Column({
      path: "templateName",
      headerName: "Faylın adı",
      filterType: OPERATORS_CONTAINING.OPTION,
    }),
  ];

  // download functions

  const staticDownloadHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(
        JSON.stringify([...staticFilters])
      );
      const resp = await templateServices.getDownloadTemplates({
        columns: encodeColumns,
        filter: encodeFilters,
        isWithFilter: staticFilters.length,
      });
      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const dynamicDownloadHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(
        JSON.stringify([...dynamicFilters])
      );
      const resp = await templateServices.getDownloadTemplates({
        columns: encodeColumns,
        filter: encodeFilters,
        isWithFilter: dynamicFilters.length,
      });
      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  //delete template function

  const deleteTemplate = async (id) => {
    // const id = data._id;
    await templateServices.deleteTypes(id);
    setOpenDialog(false);
    setDeleteAction(!deleteAction);
  };

  return (
    <>
      {/* <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3" textAlign="center">
            {"Ətraflı məlumat:"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6} mb={3}>
            <Grid item md={6}>
              <Typography variant="h5" display="inline" mr={3}>
                Şablonun adı:
              </Typography>
              <span>{typeData?.name}</span>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h5" display="inline" mr={3}>
                Şablonun kodu:
              </Typography>
              <span>{typeData?.code}</span>
            </Grid>
            <Grid item md={5}>
              <Typography variant="h5" display="inline" mr={3}>
                Şablonun tipi:
              </Typography>
              <span>{typeData?.type}</span>
            </Grid>
            <Grid item md={7}>
              <Typography variant="h5" display="inline" mr={3}>
                Sənədin adı:
              </Typography>
              <span>{typeData?.templateName}</span>
            </Grid>

            <div style={{ marginTop: "20px", width: "100%" }}>
              <BasicTable
                columns={["Name", "Type", "Min", "Max", "Label"]}
                data={typeData?.fields}
              />
            </div>
          </Grid>
        </DialogContent>
      </Dialog> */}

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Header data={headerData} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/documentation/template/create")}
          >
            <AddIcon />
            Şablon Yarat
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} mb={6}>
          <Box>
            <Box>
              <Tabs value={value} onChange={handleChangeTab}>
                <Tab
                  label="Statik sənəd şablonları"
                  value={0}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Dinamik sənəd şablonları"
                  value={1}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
            containerStyle={{
              transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
            }}
          >
            <TabPanel value={value} index={0}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                key="static"
              >
                <Table
                  columns={staticColumn}
                  data={staticData}
                  totalRows={staticTotal}
                  offset={staticOffset}
                  setOffset={(value) => setStaticOffset(value)}
                  limit={staticLimit}
                  setLimit={(value) => setStaticLimit(value)}
                  isLoading={staticLoading}
                  applyFiltersHandler={(filters) => {
                    setStaticOffset(0);
                    setStaticFilters(filters);
                  }}
                  cancelAppliedFilters={() => {
                    setStaticOffset(0);
                    setStaticFilters([]);
                  }}
                  downloadHandler={staticDownloadHandler}
                />
                <Dialog
                  PaperProps={{
                    style: {
                      width: "70%",
                      maxWidth: "unset",
                    },
                  }}
                  open={viewStaticDialog}
                  onClose={() => {
                    setViewStaticDialog(false);
                  }}
                >
                  <TemplateView
                    id={templateID}
                    close={() => {
                      setViewStaticDialog(false);
                    }}
                  />
                </Dialog>
              </div>
              {/* )} */}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ width: "100%" }} key="dynamic">
                <Table
                  columns={dynamicColumn}
                  data={dynamicData}
                  totalRows={dynamicTotal}
                  offset={dynamicOffset}
                  setOffset={(value) => setDynamicOffset(value)}
                  limit={dynamicLimit}
                  setLimit={(value) => setDynamicLimit(value)}
                  isLoading={dynamicLoading}
                  applyFiltersHandler={(filters) => {
                    setDynamicOffset(0);

                    setDynamicFilters(filters);
                  }}
                  cancelAppliedFilters={() => {
                    setDynamicOffset(0);

                    setDynamicFilters([]);
                  }}
                  downloadHandler={dynamicDownloadHandler}
                />
                <Dialog
                  open={openDialog}
                  onClose={() => {
                    setOpenDialog(false);
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    <div>Şablonu silmək istədiyinizə əminsinizmi?</div>
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                      <Button onClick={() => deleteTemplate(templateID)}>
                        Bəli
                      </Button>
                      <Button onClick={() => setOpenDialog(false)}>Xeyr</Button>
                    </div>
                  </div>
                </Dialog>

                <Dialog
                  open={editDialog}
                  onClose={() => {
                    setEditDialog(false);
                  }}
                >
                  <TemplateEdit
                    id={templateID}
                    close={() => {
                      setEditDialog(false);
                      setDeleteAction(!deleteAction);
                    }}
                  />
                </Dialog>
                <Dialog
                  open={viewDialog}
                  onClose={() => {
                    setViewDialog(false);
                  }}
                >
                  <TemplateView
                    id={templateID}
                    close={() => {
                      setViewDialog(false);
                    }}
                  />
                </Dialog>
              </div>
              {/* )} */}
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
    </>
  );
};

export default Templates;
