import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Button,
  ButtonGroup,
  Grid,
  Menu,
  MenuItem,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCommonData } from "../../api/api";
import CommonDataTable from "../../components/commonData/CommonDataTable";
import { dataTableReducer, initialState } from "../../redux/DataTableReducer";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function DataTableInformations() {
  const [selectedButton, setSelectedButton] = useState(0);
  const [state, dispatch] = useReducer(dataTableReducer, initialState);
  const { selectedType, dataTable, submitted, selectedDropdown } = state;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Updated data from API
  useEffect(() => {
    const handleCommonData = async () => {
      try {
        if (selectedType[0] === "documentTypes") {
          const response = await getCommonData("", true);

          if (response && response.data) {
            dispatch({ type: "SET_DATA_TABLE", payload: response.data });
            dispatch({
              type: "SET_SELECTED_TYPE",
              payload: Object.entries(response.data)[0],
            });
          }
        } else {
          const dataResponse = await getCommonData("", true);
          const response = await getCommonData(selectedType[0]);

          if (response && response.data) {
            dispatch({ type: "SET_DATA_TABLE", payload: dataResponse.data });

            dispatch({
              type: "SET_SELECTED_TYPE",
              payload: [
                selectedType[0],
                { name: selectedType[1].name, values: response.data },
              ],
            });
          }
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    handleCommonData();
  }, [submitted]);

  // Select for main data
  const handleSelect = (event, index, item) => {
    if (Array.isArray(item?.[1])) {
      setAnchorEl(event.currentTarget);
      dispatch({
        type: "SET_SELECTED_DROPDOWN",
        payload: item,
      });
    } else {
      dispatch({
        type: "SET_SELECTED_TYPE",
        payload: item,
      });
      dispatch({
        type: "SET_SELECTED_DROPDOWN",
        payload: [[], []],
      });
    }
    setSelectedButton(index);
  };

  // Handle dropdown
  const handleDropdownSelect = (event, index, item) => {
    dispatch({
      type: "SET_SELECTED_TYPE",
      payload: item,
    });
    setAnchorEl(null);
  };
  return (
    <>
      {/* Naming button group */}
      <Card mb={6}>
        <ButtonGroup aria-label="outlined primary button group" fullWidth>
          <Grid container>
            {Object.entries(dataTable).map(
              (item, index) =>
                item[0] !== "laborCodeArticles" && (
                  <Grid key={`${index} ${item}`} item xs={12} sm={6} md={3}>
                    <Button
                      onClick={(event) => handleSelect(event, index, item)}
                      variant={selectedButton === index ? "contained" : "text"}
                      endIcon={
                        Array.isArray(item?.[1]) && <KeyboardArrowDownIcon />
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        py: 2,
                      }}
                    >
                      {Array.isArray(item?.[1]) ? (
                        <Box>{item?.[0]}</Box>
                      ) : (
                        item?.[1].name
                      )}
                    </Button>
                  </Grid>
                )
            )}
            {/* Menu */}
            {selectedDropdown?.[1] && selectedDropdown?.[1] !== 0 && (
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ mt: 1 }}
              >
                {selectedDropdown?.[1] &&
                  selectedDropdown?.[1].map((item, index) => (
                    <MenuItem
                      sx={{ my: 3 }}
                      onClick={(event) => {
                        handleDropdownSelect(
                          event,
                          index,
                          Object.entries(item)?.[0]
                        );
                      }}
                    >
                      {Object.entries(item)?.[0]?.[1]?.name}
                    </MenuItem>
                  ))}
              </Menu>
            )}
          </Grid>
        </ButtonGroup>
      </Card>

      {/* Data table */}
      <Card>
        <Grid>
          <CommonDataTable state={state} dispatch={dispatch} />
        </Grid>
      </Card>
    </>
  );
}

function DataTable() {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Məlumatlar cədvəli
      </Typography>
      <ToastContainer />

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DataTableInformations />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default DataTable;
