import React, { useEffect, useReducer, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../../hooks/useAuth";
import { getAllEmployees } from "../../../api/api";

import { useDispatch, useSelector } from "react-redux";
import { setAllEmployee } from "../../../redux/features/slices/commonThings.slice";

import UpdateVacationItem from "../../../components/vacations/update/UpdateVacationItem";
import { getVacationById } from "../../../api/vacationRequests/vacationRequests";
import { VACATION_TYPES } from "../../../constants";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function UpdateVacationComponent() {
  const { id } = useParams();
  const [vacation, setVacation] = useState();
  const dispatch = useDispatch();
  // Selectors
  const { allEmployees } = useSelector((state) => state.commonThings);

  const { user } = useAuth();

  let limit = 9999;

  //   Get all employee
  useEffect(() => {
    const getEmployees = async () => {
      try {
        const response = await getAllEmployees(limit);
        if (response && response.data) {
          dispatch(setAllEmployee(response?.data));
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getEmployees();
  }, []);

  // Get Vacation
  useEffect(() => {
    const getVacation = async () => {
      try {
        const response = await getVacationById(id);
        if (response && response.data) {
          setVacation(response.data);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getVacation();
  }, []);

  if (!user || !allEmployees || !vacation) {
    return <>Loading...</>;
  }

  const sourceOfVacationArray = [
    "2021/2022-ci il iş ili",
    "2022/2023-ci il iş ili",
  ];

  // const reasonArray = [
  //   { value: "free", name: "Free" },
  //   { value: "paid", name: "Paid" },
  //   { value: "maternity", name: "Maternity" },
  // ];

  return (
    <Card mb={6}>
      <CardContent>
        <UpdateVacationItem
          sourceOfVacationArray={sourceOfVacationArray}
          reasonArray={VACATION_TYPES}
          vacation={vacation}
        />
      </CardContent>
    </Card>
  );
}

function UpdateVacationRequest() {
  return (
    <React.Fragment>
      <ToastContainer />

      <Helmet title="Məzuniyyət sorğusunu dəyişdir" />
      <Typography variant="h3" gutterBottom display="inline">
        Məzuniyyət sorğusunu dəyişdir
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <UpdateVacationComponent />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UpdateVacationRequest;
