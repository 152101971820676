import { Close } from "@mui/icons-material";
import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert";
// import "sweetalert2/src/sweetalert2.scss";
import * as Yup from "yup";
import { SocketContext } from "../../../contexts/SocketContext";
import { QRCode } from "antd";

const CustomLinearProgress = ({ value, color }) => {
  const [lineWidth, setLineWidth] = useState(0);

  return (
    <Grid container alignItems="center">
      <Grid
        sx={{
          height: "5px",
          width: "100%",
          backgroundColor: "#fff",
          paddingX: "44px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      ></Grid>
    </Grid>
  );
};

const validationSchema = Yup.object({
  phoneNumber: Yup.string().required("Phone number is required"),
  userId: Yup.string().required("User id is required"),
});

const initialValues = {
  phoneNumber: "",
  userId: "",
};

export default function RequestDialog({
  refreshPage,
  simaData,
  onClose,
  // openCommentDialog,
}) {
  const [method, setMethod] = useState(1);
  const [credentials, setCredentials] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [sima, setSima] = useState();

  const socket = useContext(SocketContext);
  const [isSigned, setIsSigned] = useState(false);

  useEffect(() => {
    const onMessage = (mess) => {
      if (mess.type === "contract-signed") {
        setIsSigned(true);
      }
    };

    if (socket && socket.on) {
      socket.on("notification", onMessage);
    }

    return () => {
      if (socket) {
        socket.off("notification", onMessage);
      }
    };
  }, [socket]);

  useEffect(() => {
    if (isSigned) {
      Swal.fire({
        title: "Sənəd imzalanması",
        text: "Sənəd imzalanmışdır",
        icon: "success",
        confirmButtonText: "Tamam",
      }).then((result) => {
        if (result.isConfirmed) {
          onCloseHandler();
        }
      });
    }
  }, [isSigned]);

  useEffect(() => {
    const simaData = localStorage.getItem("sima");
    setSima(simaData);
  }, [sima]);

  const onChangeHandler = (key, value) => {
    const formattedPhoneNumber = value.replace(/\D/g, "");
    setCredentials((prev) => {
      return { ...prev, [key]: formattedPhoneNumber };
    });
  };

  const sendRequestHandler = (e) => {
    // credentials send to api
    e.preventDefault();

    // Form data is valid, handle submission here
    onCloseHandler();
  };

  const onBlurHandler = (key) => {
    setTouched({ ...touched, [key]: true });

    validationSchema
      .validateAt(key, credentials)
      .then(() => {
        setErrors({ ...errors, [key]: undefined });
      })
      .catch((error) => {
        setErrors({ ...errors, [key]: error.message });
      });
  };

  const onCloseHandler = () => {
    onClose();
    refreshPage();
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 0) {
      return "";
    }

    let formattedNumber = `+${phoneNumber.slice(0, 3)}`;

    if (phoneNumber.length >= 4) {
      formattedNumber += ` (${phoneNumber.slice(3, 5)}`;
    }

    if (phoneNumber.length >= 6) {
      formattedNumber += `) ${phoneNumber.slice(5, 8)}`;
    }

    if (phoneNumber.length >= 9) {
      formattedNumber += ` ${phoneNumber.slice(8)}`;
    }

    return formattedNumber;
  };

  const authPart = (
    <>
      <Grid sx={[styles.authContainer, method === 1 && { paddingY: "20px" }]}>
        {method === 1 && (
          <Grid
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {sima && simaData && (
              <>
                <QRCode value="https://sima.az/az" style={styles.qrCode} />
                <img src={simaData} style={styles.qrCode} />
              </>
            )}
            {!sima ? (
              <Button
                disabled={
                  credentials.phoneNumber.length === 1 ||
                  credentials.userId.length === 1
                }
                variant="contained"
                sx={styles.button}
                onClick={sendRequestHandler}
              >
                İmzala
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );

  return (
    <Grid sx={styles.container}>
      <IconButton
        onClick={onCloseHandler}
        sx={{ position: "absolute", right: "5px" }}
      >
        <Close />
      </IconButton>
      {sima && (
        <>
          <Grid sx={styles.headerContainer}>
            <Grid
              sx={[styles.headerItemContainer]}
              onClick={() => setMethod(1)}
            >
              <Typography variant="h2" color={"white"}>
                SIMA
              </Typography>
              <Paper
                sx={[
                  styles.itemImageContainer,
                  { marginRight: 0, marginLeft: "40px" },
                ]}
              >
                {/* <IconSima style={styles.simaLogo} /> */}
              </Paper>
            </Grid>
          </Grid>
          <CustomLinearProgress value={method} />
        </>
      )}
      <Grid sx={styles.contentContainer}>{authPart}</Grid>
    </Grid>
  );
}

const styles = {
  container: {
    width: "100%",
    // height: "100%",
    borderRadius: 100,
  },
  headerContainer: {
    paddingX: "48px",
    paddingY: "42px",
    height: "139px",
    // borderBottom: "5px solid #fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    width: "100%",
  },
  itemImageContainer: {
    backgroundColor: "#fff",
    width: "60px",
    height: "60px",
    borderRadius: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "40px",
  },
  itemImage: { width: "50px", height: "50px" },
  simaLogo: { position: "relative", top: "-1px" },
  activeMethod: {
    borderBottom: "5px solid red",
    position: "relative",
    bottom: "42px",
  },
  authContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "80%",
    // height: "70%",
    paddingX: "140px",
    // paddingY: "100px",
  },
  inputContainer: {
    width: "100%",
    marginBottom: "20px",
  },
  input: {
    fontSize: "20px",
    width: "100%",
    paddingX: "18px",
  },
  footerContainer: {
    borderTop: "5px solid #fff",
    position: "relative",
    bottom: "-85px",
  },
  qrCode: {
    width: "260px",
    height: "260px",
    marginBottom: "40px",
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "20px",
  },
  label: {
    marginBottom: "10px !important",
    marginLeft: "10px !important",
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    transition: "opacity 0.5s ease-in-out",
    alignItems: "center",
    paddingTop: "76px",
    // height: "100%",
  },
  button: { width: "100%", fontSize: "32px", borderRadius: "30px" },
};
