/* eslint-disable prettier/prettier */
import moment from "moment/moment";
import { structureNamingsEnum, SUBJECT_TYPES_ENUM } from "../constants";
// Services
import * as employeeServices from "../services/employees/EmployeeServices";
import * as managementServices from "../services/management/ManagementServices";
import * as companyStructureServices from "../services/structure/StructureServices";
// Material UI
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export const findExtensionOfFile = (file) => {
  const splittedArray = file?.name.split(".");
  const extension = splittedArray[splittedArray.length - 1];
  return extension;
};

export const removeEmptyStrings = (obj) => {
  if (typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    let data = obj
      .map(removeEmptyStrings)
      .filter((value) => value !== "" && Object.keys(value).length !== 0);
    return data;
  }

  const result = {};
  for (const key in obj) {
    const value = obj[key];
    if (value !== "" && value !== null && value !== undefined) {
      if (typeof value === "object") {
        const cleanedValue = removeEmptyStrings(value);
        if (cleanedValue !== null && cleanedValue !== undefined) {
          result[key] = cleanedValue;
        }
      } else {
        result[key] = value;
      }
    }
  }

  return result;
};

export const convertMinMaxToNumber = (inputArray) => {
  return inputArray.map((item) => {
    const { min, max, ...rest } = item;
    const updatedItem = { ...rest };

    if (min !== undefined) {
      updatedItem.min = Number(min);
    }

    if (max !== undefined) {
      updatedItem.max = Number(max);
    }

    return updatedItem;
  });
};

export const storeSingleArrayKeywords = (data) => {
  const utilsArray = [];
  for (const item of data) {
    utilsArray.push(item.name);
  }

  return utilsArray;
};

export const customizeTemplateArrStructure = (arr) => {
  const newArr = [];
  for (const item of arr) {
    newArr.push({
      name: item,
      type: "",
      min: "",
      max: "",
      label: "",
      // isRequired: true,
      // source: "custom"
    });
  }
  return newArr;
};

export const checkTypePerPage = (url, searchKey) => {
  if (typeof url !== "string") return null;
  if (url.indexOf(searchKey) !== -1) return true;
  return false;
};

export const findCaseHttpRequest = async (_start, _search, _value) => {
  let data = {
    val: "",
    field: "",
    arrKey: "",
  };

  switch (_value) {
    case SUBJECT_TYPES_ENUM.COMPANY_PART:
      break;
    case SUBJECT_TYPES_ENUM.EMPLOYEE:
      data["val"] = await employeeServices.getEmployees({
        limit: 10,
        skip: _start,
        name: _search,
      });
      data["field"] = "firstname";
      data["arrKey"] = "employees";
      break;
    case SUBJECT_TYPES_ENUM.COMPANY_STRUCTURE:
      data["val"] = await companyStructureServices.getCompanyStructures({
        limit: 10,
        skip: _start,
        name: _search,
      });
      data["field"] = "name";
      data["arrKey"] = "structures";
      break;
    case SUBJECT_TYPES_ENUM.MANAGEMENT:
      data["val"] = await managementServices.getManagements({
        limit: 10,
        skip: _start,
        title: _search,
      });
      data["field"] = "title";
      data["arrKey"] = "managements";
      break;
    case SUBJECT_TYPES_ENUM.STAFF:
      break;
    default:
      break;
  }
  return data;
};

export const findStructureIdByName = (allData = [], searchName) => {
  return allData.find((data) => data.name === searchName)?._id || null;
};

export const generateCapatilazeWord = (word) => {
  if (!word) return "";
  return word[0].toUpperCase() + word.substring(1);
};

export const fillToArrayUsingTypeId = (_id) => {
  const idArrays = [];
  if (_id) {
    idArrays.push(_id);
  }
};

export const findPositionById = (item) => {
  return item?.filter(
    (data) => data?.child?.expectedType?.name === structureNamingsEnum.POSITION
  );
};

// Generate TextField with Icon
export const IconTextField = ({ iconStart, iconEnd, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: iconStart ? (
          <InputAdornment position="start">{iconStart}</InputAdornment>
        ) : null,
        endAdornment: iconEnd ? (
          <InputAdornment position="end">{iconEnd}</InputAdornment>
        ) : null,
      }}
    />
  );
};

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const buildEncodedFilter = (isSearchableFilter) => {
  const array = [];
  const isUnusedFilter = {
    column: "isUsedInStaff",
    operator: "notEqual",
    value: true,
  };

  array.push(isUnusedFilter);
  isSearchableFilter && array.push(isSearchableFilter);
  return encodeURIComponent(JSON.stringify(array));
};

export const generateTimeOptions = (interval = 30) => {
  const options = [];
  for (let i = 8; i <= 18; i++) {
    for (let j = 0; j < 60; j += interval) {
      // When the time is 18:00, don’t add further intervals.
      if (i === 18 && j !== 0) break;

      const value = `${i.toString().padStart(2, "0")}:${j
        .toString()
        .padStart(2, "0")}`;
      options.push({ value, label: value });
    }
  }
  return options;
};
export const formatDateTime = (dateTimeString) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    // hour12: false,
  };
  return new Date(dateTimeString).toLocaleDateString("en-GB", options);
};

export const hexToRgba = (hex, opacity) => {
  hex = hex.replace(/^#/, ""); // Remove the '#' if it's present
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export function getDescendantProp(obj, path, isArr = false) {
  if (!path) {
    throw new Error("Invalid path");
  }

  const arr = path.split(".");
  if (isArr) {
    obj = obj[arr.shift()];
    while (!Array.isArray(obj)) {
      if (!obj) return;
      const fieldName = arr.shift();

      obj = obj[fieldName];

      // if (Array.isArray(obj)) break;
    }

    return obj?.map((item) => {
      let tmp = [...arr];
      while (tmp && tmp.length) {
        const fieldName = tmp.shift();

        item = item[fieldName];
        if (!item) break;
      }
      return item;
    });
  }

  while (arr && arr.length) {
    const fieldName = arr.shift();

    obj = obj[fieldName];

    if (!obj) break;
  }

  return obj;
}

export const normalizeDate = (dateString) => {
  if (!dateString) return "-";

  const date = moment(dateString);
  return date.format("DD/MM/YYYY");
};

// vacation types

export const vacationConverter = (vacation) => {
  switch (vacation) {
    case "main":
      return "Əsas əmək məzuniyyəti";
    case "additional":
      return "Əlavə əmək məzuniyyəti";
    case "creative":
      return "Yaradıcılıq məzuniyyəti";
    case "educational":
      return "Təhsil məzuniyyəti";
    case "social":
      return "Sosial məzuniyyət";
    case "unpaid":
      return "Ödənişsiz məzuniyyət";
    default:
      return "";
  }
};

export const getDayOfWeek = (day, month, year) => {
  return new Date(year, month - 1, day).getDay();
};

export const getCurrentMonthRange = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;

  const firstDay = `01.${currentMonth.toString().padStart(2, "0")}.${currentYear}`;

  const lastDay = new Date(currentYear, currentMonth, 0).getDate();
  const lastDayFormatted = `${lastDay.toString().padStart(2, "0")}.${String(
    currentMonth
  ).padStart(2, "0")}.${currentYear}`;

  return `${firstDay}-${lastDayFormatted}`;
};
