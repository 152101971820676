import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OPERATORS_CONTAINING } from "../../../../../../../../constants";

const Container = styled.div`
  width: 100%;
`;

const CustomSelect = styled.div`
  position: relative;
  width: 100%; /* Adjust the width as needed */

  select {
    appearance: none;
    outline: 0;
    cursor: pointer;
    width: 100%;
    background-color: #1b2635;
    padding: 5px 10px;
    border: 1px solid #333;
    border-radius: 5px;
    color: white;
    transition: border-color 0.3s ease-in-out;
    position: relative;
    z-index: 1;

    &:focus,
    &:hover {
      border-color: #fff;
    }
  }

  /* Styling the dropdown indicator */
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: white transparent transparent;
    pointer-events: none;
    z-index: 2;
  }
`;

const Input = styled.input`
  outline: 0;
  width: 100%;
  background-color: #1b2635;
  padding: 5px 10px;
  border: 1px solid #333;
  border-radius: 5px;
  color: white;
  transition: border-color 0.3s ease-in-out;
  position: relative;
  z-index: 1;

  &:focus,
  &:hover {
    border-color: #fff;
  }
`;

export default function ValueInput({
  filterType,
  onChange,
  filter,
  fetchHandler = undefined,
  fetchHandler2 = undefined,
  dataGridName,
  dependencies,
  column,
  values,
  disabled,
  value,
  triggerFetching = true,
  filters,
  applyHandler,
}) {
  const [options, setOptions] = useState([]);
  const [beginDate, setBeginDate] = useState(value?.begin || "");
  const [endDate, setEndDate] = useState(value?.end || "");
  const [betweenStart, setBetweenStart] = useState(value?.start || "");
  const [betweenEnd, setBetweenEnd] = useState(value?.end || "");
  const [inValue, setInValue] = useState(value?.in || "");

  const handleBeginChange = (e) => {
    setBeginDate(e.target.value);
    onChange({ ...value, from: e.target.value });
  };

  const handleEndChange = (e) => {
    setEndDate(e.target.value);
    onChange({ ...value, to: e.target.value });
  };
  const handleBetweenStart = (e) => {
    setBetweenStart(e.target.value);
    onChange({ ...value, from: parseInt(e.target.value) });
  };

  const handleBetweenEnd = (e) => {
    setBetweenEnd(e.target.value);
    onChange({ ...value, to: parseInt(e.target.value) });
  };
  const handleINChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );

    setInValue(selectedValues);
    onChange(selectedValues);
  };

  useEffect(() => {
    fetchHandler2 && triggerFetching && fetchOptions2(fetchHandler2);
    fetchHandler && triggerFetching && fetchOptions(fetchHandler);
    values && setOptions(values);
  }, [
    fetchHandler2,
    fetchHandler,
    dependencies,
    dataGridName,
    values,
    triggerFetching,
  ]);
  const fetchOptions2 = async (fetchHandler) => {
    try {
      const struc = findDependenciesColumnsValueForFetching(
        dependencies?.structures
      );
      const topStruc = findDependenciesColumnsValueForFetching(
        dependencies?.topPartOfStructures
      );

      const data = await fetchHandler({
        dataGridName,
        structures: struc,
        topPartOfStructures: topStruc,
      });
      const currentColumnData =
        data && data[column.keyForResponseDataFromFilter];
      const modifiedArr = currentColumnData?.map((item) => ({
        name: item.name,
        key: item.ids,
      }));
      setOptions(modifiedArr);
    } catch (error) {
      console.log("err", error);
    }
  };

  const findDependenciesColumnsValueForFetching = (arr) => {
    if (!arr) return;
    const newArr = [];

    filters.map((item) => {
      const response = arr.includes(item.column);
      if (response) {
        newArr.push(item.value);
      }
    });
    return newArr;
  };

  const fetchOptions = async (handler) => {
    try {
      const data = await handler();
      data && setOptions(data);
    } catch (error) {
      console.log("err", error);
    }
  };
  const generateValueInput = () => {
    switch (filterType) {
      case OPERATORS_CONTAINING.TEXT:
        return (
          <Input
            style={{ width: "100%" }}
            type="text"
            onChange={(e) => onChange(e.target.value)}
            value={value}
            disabled={disabled}
            placeholder="Dəyər daxil edin..."
            onKeyDown={(e) => e.key === "Enter" && applyHandler()}
          />
        );
      case OPERATORS_CONTAINING.SINGLE_NUMBER:
        return (
          <Input
            style={{ width: "100%" }}
            type="number"
            onChange={(e) => onChange(e.target.value)}
            value={value}
            disabled={disabled}
            placeholder="Dəyər daxil edin..."
            onKeyDown={(e) => e.key === "Enter" && applyHandler()}
          />
        );
      case OPERATORS_CONTAINING.NUMBER:
        return filter?.operator !== "between" ? (
          <Input
            style={{ width: "100%" }}
            type="number"
            onChange={(e) => onChange(e.target.value)}
            value={value}
            disabled={disabled}
            placeholder="Dəyər daxil edin..."
            onKeyDown={(e) => e.key === "Enter" && applyHandler()}
          />
        ) : (
          <div>
            <Input
              style={{ width: "100%" }}
              type="number"
              onChange={handleBetweenStart}
              value={betweenStart}
              disabled={disabled}
              placeholder="Dəyər daxil edin..."
              onKeyDown={(e) => e.key === "Enter" && applyHandler()}
            />
            <Input
              style={{ width: "100%" }}
              type="number"
              onChange={handleBetweenEnd}
              value={betweenEnd}
              disabled={disabled}
              placeholder="Dəyər daxil edin..."
              onKeyDown={(e) => e.key === "Enter" && applyHandler()}
            />
          </div>
        );
      case OPERATORS_CONTAINING.OPTION:
        return (
          <CustomSelect>
            <select
              value={value}
              style={{ width: "100%" }}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
            >
              <option hidden>Dəyər seçin...</option>
              {options?.map((item, index) => (
                <>
                  <option
                    key={(item.key ? item.key : item.value) + index}
                    value={item.key !== undefined ? item.key : item.value}
                  >
                    {item.name}
                  </option>
                </>
              ))}
            </select>
          </CustomSelect>
        );
      case OPERATORS_CONTAINING.IN:
        return (
          <CustomSelect>
            <select
              value={inValue}
              style={{ width: "100%" }}
              onChange={handleINChange}
              disabled={disabled}
            >
              <option hidden>Dəyər seçin...</option>
              {options?.map((item, index) => (
                <>
                  <option
                    key={(item.key ? item.key : item.value) + index}
                    value={item.key !== undefined ? item.key : item.value}
                  >
                    {item.name}
                  </option>
                </>
              ))}
            </select>
          </CustomSelect>
        );
      case OPERATORS_CONTAINING.DATE:
        return filter?.operator !== "dateBetween" ? (
          <Input
            disabled={disabled}
            type={"date"}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            name="begin"
            onKeyDown={(e) => e.key === "Enter" && applyHandler()}
          />
        ) : (
          <div>
            <Input
              disabled={disabled}
              type="date"
              onChange={handleBeginChange}
              value={beginDate}
              name="begin"
              onKeyDown={(e) => e.key === "Enter" && applyHandler()}
            />
            <Input
              disabled={disabled}
              type="date"
              onChange={handleEndChange}
              value={endDate}
              name="end"
              onKeyDown={(e) => e.key === "Enter" && applyHandler()}
            />
          </div>
        );
      case OPERATORS_CONTAINING.OPTION_MULTIPLE:
        return (
          <CustomSelect>
            <select
              multiple={true}
              value={value}
              style={{ width: "100%" }}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
            >
              <option hidden>Dəyər seçin...</option>
              {options?.map((item, index) => (
                <option key={item.key + index} value={item.key}>
                  {item.name}
                </option>
              ))}
            </select>
          </CustomSelect>
        );
      case OPERATORS_CONTAINING.SINGLE_OPTION:
        return (
          <CustomSelect>
            <select
              value={value}
              style={{ width: "100%" }}
              onChange={(e) => onChange(e.target.value)}
              disabled={disabled}
            >
              <option hidden>Dəyər seçin...</option>
              {options?.map((item, index) => (
                <option key={item.key + index} value={item.key}>
                  {item.name}
                </option>
              ))}
            </select>
          </CustomSelect>
        );
      case OPERATORS_CONTAINING.OPTION_NUMBER:
        return (
          <CustomSelect>
            <select
              value={+value}
              style={{ width: "100%" }}
              onChange={(e) => onChange(+e.target.value)}
              disabled={disabled}
            >
              <option hidden>Dəyər seçin...</option>
              {options?.map((item, index) => (
                <>
                  <option
                    key={(item.key ? item.key : item.value) + index}
                    value={item.key !== undefined ? +item.key : +item.value}
                  >
                    {item.name}
                  </option>
                </>
              ))}
            </select>
          </CustomSelect>
        );
      default:
        return noneStandartCase();
    }
  };

  const noneStandartCase = (arr) => {
    if (!arr && arr?.length === 0) {
      return "input";
    }
    return (
      <CustomSelect>
        <select
          value={value}
          style={{ width: "100%" }}
          onChange={onChange}
          disabled={disabled}
        >
          <option hidden>Dəyər seçin...</option>
          {arr?.map((item, index) => (
            <option key={item.key + index} value={item.key}>
              {item.name}
            </option>
          ))}
        </select>
      </CustomSelect>
    );
  };

  return generateValueInput();
}
