import { Visibility } from "@mui/icons-material";
import { Breadcrumbs, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import {
  getChangeRequests,
  getChangeRequestsDownloadCSV,
} from "../../api/changeRequests";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import StatusGenerator from "../../components/table/StatusGenerator";
import Table from "../../components/table/Table";
import { OPERATORS_CONTAINING } from "../../constants";
import Column from "../../models/table/Column";
import Operator from "../../models/table/Operator";
import { downloadFile } from "../../utils/downloadCSV";
import { getDescendantProp } from "../../utils/helperFunctions";
import ChangeRequestsModal from "./ChangeRequestsModal";
import { headObj, obj } from "./ChangeRequestsMock";

export default function ChangeRequests() {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [fieldsData, setFieldsData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(6);
  const [open, setOpen] = useState(false);
  const [graphicId, setGraphicId] = useState(null);
  const [status, setStatus] = useState();
  const [editData, setEditData] = useState({
    status: "",
    description: "",
  });

  useEffect(() => {
    paginationHandler();
  }, [filters, offset, limit]);
  const paginationHandler = async () => {
    if (!filters.length > 0) {
      await getFilterGraphic();
      return;
    } else {
      const encode = encodeURIComponent(JSON.stringify(filters));
      await getFilterGraphic(encode);
    }
  };
  const generateStatus = (datas) => {
    setStatus(datas?.status);
  };

  async function handleGetForId(rowId) {
    setLoading(true);
    handleOpen();
    try {
      setGraphicId(rowId);
      const res = await getChangeRequests({
        id: rowId,
      });

      setLoading(false);
      setDatas(res.data);
      const createKeys = Object.keys(res.data.creator);
      const newObjj = createKeys
        .map((key, index) => {
          if (headObj[key]) {
            return {
              label: headObj[key].label,
              value: headObj[key].renderValue(res.data.creator),
            };
          }
        })
        .filter(Boolean);
      newObjj.sort((a, b) => {
        if (a.label === "Sorğunu növü") return -1;
        if (b.label === "Sorğunu növü") return 1;
        return 0;
      });
      const txtValues =
        res.data.changes &&
        res.data.changes.map((change) => {
          if (change.data) {
            let keys = Object.keys(change.data);
            let values = Object.values(change.data);
            const sectionKey = `${res.data.section}-${change.operation}`;
            const sectionData = keys
              .map((key, index) => {
                let newKey =
                  res.data.section === "academic-degree" && key === "name"
                    ? "name1"
                    : key;

                const objEntry = obj[newKey];
                if (objEntry) {
                  return {
                    label: objEntry.label,
                    value: objEntry.renderValue([values[index]], change),
                  };
                } else {
                  console.error(`Object entry not found for key: ${newKey}`);
                  return null;
                }
              })
              .filter(Boolean);
            return {
              contract_general_info: newObjj,
              [sectionKey]: sectionData,
            };
          }
        });

      const combinedData = txtValues.reduce((acc, curr) => {
        if (Object.keys(acc).includes(Object.keys(curr)[1])) {
          acc[Object.keys(curr)[1]] = [
            ...acc[Object.keys(curr)[1]],
            ...Object.values(curr)[1],
          ];
        } else {
          acc = { ...acc, ...curr };
        }
        return acc;
      }, {});
      setFieldsData(combinedData);
      generateData(res.data);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  const generateData = (data) => {
    if (!data) return;
    // generateComments(data);
    generateStatus(data);
    // generateVersions(data);
  };

  const handleIconClick = (row, action) => {
    const rowId = row._id ? row._id : row.id;
    switch (action) {
      case "visibility":
        handleGetForId(rowId);
        break;
      case "edit":
        break;
      case "delete":
        break;
      default:
        break;
    }
  };
  const handleClose = () => {
    setOpen(false);
    setEditData({
      status: "",
      description: "",
    });
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const operatorGeneratorHandler = (row, column) => {
    const operators = [
      new Operator({
        icon: <Visibility />,
        onClick: () => handleIconClick(row, "visibility"),
        type: 0,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };
  const getFilterGraphic = async (filters = undefined) => {
    try {
      setIsLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        filter: filters,
      };
      const { data } = await getChangeRequests({
        offset: payload.offset,
        limit: payload.limit,
        filter: payload.filter,
      });
      setData(data?.records);
      setTotal(data?.total);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const columns = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      renderCell: operatorGeneratorHandler,
    }),
    new Column({
      path: "requestNO",
      headerName: "Sorğu nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "creator.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "creator.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "creator.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "creator.structure",
      headerName: "Struktur",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row) => {
        return row?.creator?.staffs
          .map((staff) => staff.structure?.organization?.name)
          .join(",");
      },
    }),
    new Column({
      path: "substructure",
      headerName: "Sub-struktur",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row) => {
        return row?.creator?.staffs
          .map((staff) => staff.structure?.organization?.name)
          .join(",");
      },
    }),
    new Column({
      path: "position",
      headerName: "Vəzifə",
      filterColumnKey: "position",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row) => {
        return row?.creator?.staffs
          .map((staff) => staff?.position?.name)
          .join(",");
      },
    }),
    new Column({
      path: "creator.personalCategory",
      headerName: "Kateqoriya",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      renderCell: (row, column) => {
        const approver = [];
        if (row.status === "pending_contract_approve") {
          row.approvers &&
            row.approvers.map((item, index) => {
              approver.push({
                index: index + 1,
                employee:
                  item.staff.employee[0].firstname +
                  " " +
                  item.staff.employee[0].lastname,
                status: item.status,
              });
            });
        } else if (row.status === "pending_order_approve") {
          row.approvers.map((item, index) => {
            approver.push({
              index: index + 1,
              employee:
                item.staff.employee[0].firstname +
                " " +
                item.staff.employee[0].lastname,
              status: item.status,
            });
          });
        } else {
          row.approvers.map((item, index) => {
            approver.push({
              index: index + 1,
              employee:
                item.staff.employee[0].firstname +
                " " +
                item.staff.employee[0].lastname,
              status: item.status,
            });
          });
        }
        return (
          <StatusGenerator
            approvement={approver}
            value={getDescendantProp(row, column.path)}
            docType={"staffStatus"}
          />
        );
      },
    }),
  ];

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };
  const allContExportHandler = async (columnsForExport) => {
    try {
      const forExport = [];
      const isWithFilter =
        filters?.length > 0 && filters[0]?.column?.length > 0 ? true : false;
      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;

        forExport.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      const resp = await getChangeRequestsDownloadCSV({
        isWithFilter: isWithFilter,
        columns: encodedColumns,
        filter: encodedFilter,
      });
      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };
  return (
    <>
      <Helmet title="Əməkdaş əlavə et" />
      <Typography variant="h3" gutterBottom display="inline">
        Əməkdaşların məlumat dəyişikliyi sorğuları
      </Typography>
      <ToastContainer />
      {/* <Breadcrumbs routesData={routeData} mt={2} /> */}

      <Divider my={6} sx={{ marginTop: "20px" }} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid sx={{ width: "100%", marginTop: "50px" }}>
            <Table
              data={data}
              limit={limit}
              offset={offset}
              columns={columns}
              totalRows={total}
              isLoading={isLoading}
              setLimit={(value) => setLimit(value)}
              setOffset={(value) => setOffset(value)}
              applyFiltersHandler={applyFiltersHandler}
              cancelAppliedFilters={cancelAppliedFiltersHandler}
              downloadHandler={(columns) => allContExportHandler(columns)}
            />
          </Grid>
        </Grid>
      </Grid>
      {open && (
        <ChangeRequestsModal
          open={open}
          datas={datas}
          status={status}
          loading={loading}
          graphicId={graphicId}
          isLoading={isLoading}
          fieldsData={fieldsData}
          handleOpen={handleOpen}
          handleClose={handleClose}
          paginationHandler={paginationHandler}
          cancelAppliedFiltersHandler={cancelAppliedFiltersHandler}
        />
      )}
    </>
  );
}
