import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import FilterModel from "../../../../../../../../models/table/Filter/FilterModel";
import ButtonCustom from "../../../../../../../ui/Button/ButtonCustom";
import { Delete } from "@mui/icons-material";
import Eraser from "../../../../../../../../assets/icons/Eraser/index";
import ValueInput from "./ValueInput";
import { OPERATORS_CONTAINING } from "../../../../../../../../constants";

const expand = keyframes`
from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const shrink = keyframes`
from {
    width: 100%;
  }
  to {
    width: 50%;
  }
`;

const shrinkMore = keyframes`
from {
    width: 50%;
  }
  to {
    width: 33.333%;
  }
`;

const appearAnimation = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 45px;
  }
`;

const exitAnimation = keyframes`
  from {
    opacity: 0.1;
    height: 45px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &.delete-animation {
    animation: ${exitAnimation} 0.5s forwards ease-in-out;
  }
`;

// animation: ${appearAnimation} 0.5s forwards ease-in-out;

const SelectContainer = styled.div`
  width: 50%;
  height: 100%;
  padding: 5px 0px;
  margin-left: 2.5px;
  transform-origin: left;
  transition: all 0.5s;

  ${({ $expanded }) =>
    $expanded &&
    css`
      animation: ${expand} 0.5s forwards ease-in-out;
    `}
  ${({ $shrinked }) =>
    $shrinked &&
    css`
      animation: ${shrink} 0.5s forwards ease-in-out;
    `}
  ${({ $shrinkedMore }) =>
    $shrinkedMore &&
    css`
      animation: ${shrinkMore} 0.5s forwards ease-in-out;
    `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
`;

const ButtonContainer = styled.div`
  margin: 0px 2.5px;
  height: 100%;
  width: 50%;
`;

const Select = styled.select`
  appearance: none;
  outline: 0;
  cursor: pointer;
  width: 100%;
  background-color: #1b2635;
  padding: 5px 10px;
  border: 1px solid #333;
  border-radius: 5px;
  color: white;
  transition: all 0.5s ease-in-out;

  &:after {
    background-color: black;
  }

  &:hover {
    border-color: #fff;
  }
  &:active {
    border-color: #fff;
  }
  option {
    color: inherit;
    background-color: #320a28;
  }
`;

const CustomSelect = styled.div`
  position: relative;
  width: 100%; /* Adjust the width as needed */

  select {
    appearance: none;
    outline: 0;
    cursor: pointer;
    width: 100%;
    background-color: #1b2635;
    padding: 5px 10px;
    border: 1px solid #333;
    border-radius: 5px;
    color: white;
    transition: border-color 0.3s ease-in-out;
    position: relative;
    z-index: 1;

    &:focus,
    &:hover {
      border-color: #fff;
    }
  }

  /* Styling the dropdown indicator */
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 3px);
    right: 10px;
    border-style: solid;
    border-width: 6px 6px 0;
    border-color: white transparent transparent;
    pointer-events: none;
    z-index: 2;
  }
`;
const FilterItem = ({
  columns,
  filter = new FilterModel({
    column: "",
    operator: "",
    value: "",
    subColumn: "",
  }),
  index,
  onChange,
  onDelete,
  disabled,
  disableEditRow,
  resetRow,
  filters,
  applyHandler,
}) => {
  const [deleted, setDeleted] = useState(false);
  const [column, setColumn] = useState();
  const [operator, setOperator] = useState([]);
  const updateColumnAndSubColumn = (filter) => {
    if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportReservedMain"
    ) {
      filter.column = "Yaranmış məzuniyyət hüququ";
      filter.subColumn = "vacationReportReservedMain";
    } else if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportReservedAdditional"
    ) {
      filter.column = "Yaranmış məzuniyyət hüququ";
      filter.subColumn = "vacationReportReservedAdditional";
    } else if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportReservedTotal"
    ) {
      filter.column = "Yaranmış məzuniyyət hüququ";
      filter.subColumn = "vacationReportReservedTotal";
    } else if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportUsedMain"
    ) {
      filter.column = "İstifadə edilmiş məzuniyyət";
      filter.subColumn = "vacationReportUsedMain";
    } else if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportUsedAdditional"
    ) {
      filter.column = "İstifadə edilmiş məzuniyyət";
      filter.subColumn = "vacationReportUsedAdditional";
    } else if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportUsedTotal"
    ) {
      filter.column = "İstifadə edilmiş məzuniyyət";
      filter.subColumn = "vacationReportUsedTotal";
    } else if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportUnUsedMain"
    ) {
      filter.column = "Qalıq məzuniyyət günləri";
      filter.subColumn = "vacationReportUnUsedMain";
    } else if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportUnUsedAdditional"
    ) {
      filter.column = "Qalıq məzuniyyət günləri";
      filter.subColumn = "vacationReportUnUsedAdditional";
    } else if (
      filter?.subColumn === "" &&
      filter?.column === "vacationReportUnUsedTotal"
    ) {
      filter.column = "Qalıq məzuniyyət günləri";
      filter.subColumn = "vacationReportUnUsedTotal";
    }
    return filter;
  };
  useEffect(() => {
    // if (!filter.column) return;
    const updatedFilter = updateColumnAndSubColumn(filter);
    setColumn(updatedFilter?.column);
    const founded =
      columns &&
      columns.find(
        (i) =>
          (i.isSubHeader === false && i.path === filter.column) ||
          (i.isSubHeader === false && i.filterColumnKey === filter.column) ||
          (i.isSubHeader === true && i.headerName === filter.column)
      );
    founded && setColumn(founded);
    founded &&
      founded?.filterType &&
      founded?.filterType === OPERATORS_CONTAINING.SINGLE_OPTION &&
      onChange("operator", index, OPERATORS_CONTAINING.SINGLE_OPTION[0].key);
    founded && setOperator(founded.filterType);
  }, [filter.column, filter.subColumn]);
  const handleColumnChange = (e, filter, columns, index, onChange) => {
    const selectedColumn = e.target.value;
    onChange("column", index, selectedColumn);

    const reportColumns = [
      "vacationReportReservedMain",
      "vacationReportReservedAdditional",
      "vacationReportReservedTotal",
      "vacationReportUsedMain",
      "vacationReportUsedAdditional",
      "vacationReportUsedTotal",
      "vacationReportUnUsedMain",
      "vacationReportUnUsedAdditional",
      "vacationReportUnUsedTotal",
    ];

    if (
      filter?.subColumn === "" &&
      reportColumns.includes(filter?.column || selectedColumn)
    ) {
      const selectedColumnObj = columns?.find(
        (col) =>
          col?.filterColumnKey === selectedColumn ||
          col?.path === selectedColumn
      );

      if (selectedColumnObj?.subHeader?.length > 0) {
        onChange(
          "subColumn",
          index,
          selectedColumnObj?.subHeader[0]?.colmun ||
            selectedColumnObj?.subHeader[0]?.path
        );
      } else {
        onChange("subColumn", index, "");
      }
    }
  };

  const handleDelete = (id) => {
    setDeleted(true);
    setTimeout(() => {
      onDelete(id);
    }, 500);
  };

  const width = !filter.column
    ? "100%"
    : filter.column && !filter.operator
    ? "50%"
    : "33%";

  return (
    <Container key={index} className={deleted ? "delete-animation" : ""}>
      <SelectContainer style={{ width }}>
        <CustomSelect>
          <select
            style={{ width: "100%" }}
            value={filter?.column}
            onChange={(e) =>
              handleColumnChange(e, filter, columns, index, onChange)
            }
            disabled={disabled}
          >
            <option hidden>Sütun seçin...</option>
            {columns &&
              columns.map(
                (item) =>
                  !item?.isStaticColumn &&
                  item?.isWithFilter && (
                    <option
                      key={item?.id}
                      value={
                        item?.filterColumnKey
                          ? item?.filterColumnKey
                          : item?.path
                      }
                    >
                      {item?.headerName}
                    </option>
                  )
              )}
          </select>
        </CustomSelect>

        {/* SubColumn Dropdown */}
        {filter?.column &&
          [
            "Yaranmış məzuniyyət hüququ",
            "İstifadə edilmiş məzuniyyət",
            "Qalıq məzuniyyət günləri",
          ].includes(filter?.column) &&
          columns?.find((item) => item?.headerName === filter?.column)
            ?.subHeader?.length > 0 && (
            <CustomSelect>
              <select
                style={{ width: "100%" }}
                value={filter?.subColumn}
                onChange={(e) => {
                  onChange("subColumn", index, e.target.value);
                }}
                disabled={disabled}
              >
                <option hidden>Alt sütunu seçin...</option>
                {columns
                  ?.filter((item) => item?.headerName === filter?.column)
                  ?.map((item) =>
                    item?.subHeader.map((itm, idx) => (
                      <option
                        key={idx}
                        value={itm?.colmun ? itm?.colmun : itm?.path}
                      >
                        {itm?.name}
                      </option>
                    ))
                  )}
              </select>
            </CustomSelect>
          )}
      </SelectContainer>

      {filter.column &&
        column?.filterType !== OPERATORS_CONTAINING.SINGLE_OPTION && (
          <SelectContainer
            style={{
              //   display: !filter.column && "none",
              //   opacity: !filter.column && 0,
              //   pointerEvents: !filter.column && "none",
              width: filter.column && !filter.operator ? "50%" : "33%",
            }}
            // $shrinked={Boolean(filter.column)}
            // $shrinkedMore={Boolean(filter.operator)}
          >
            <CustomSelect>
              <select
                style={{ width: "100%" }}
                value={filter?.operator}
                onChange={(e) => {
                  onChange("operator", index, e.target.value);
                }}
                disabled={disabled}
              >
                <option hidden>Operator seçin...</option>
                {operator?.map((item, index) => (
                  <option key={item.key} value={item.key}>
                    {item.name}
                  </option>
                ))}
              </select>
            </CustomSelect>
          </SelectContainer>
        )}

      <SelectContainer
        style={{
          display: !filter.operator && "none",
          opacity: !filter.operator && 0,
          pointerEvents: !filter.operator && "none",
          width: filter.operator
            ? filter.operator === OPERATORS_CONTAINING.SINGLE_OPTION[0].key
              ? "50%"
              : "33%"
            : 0,
        }}
        // $expanded={Boolean(!filter.value)}
        // $shrinkedMore={Boolean(filter.operator)}
        // $shrinked={column?.filterType === OPERATORS_CONTAINING.SINGLE_OPTION}
      >
        <ValueInput
          column={column}
          filter={filter}
          fetchHandler={column?.fetchFilterValues && column.fetchFilterValues}
          fetchHandler2={
            column?.fetchFilterValuesV2 && column?.fetchFilterValuesV2
          }
          dataGridName={column?.dataGridName}
          dependencies={column?.dependencieKeysOfFilter}
          filterType={operator}
          onChange={(value) => {
            onChange("value", index, value);
          }}
          values={column?.filterValues && column.filterValues}
          disabled={disabled}
          value={filter.value}
          filters={filters}
          applyHandler={applyHandler}
        />
      </SelectContainer>

      {!disableEditRow && (
        <ButtonsContainer>
          <ButtonContainer>
            <ButtonCustom
              style={{ padding: 0 }}
              styleContainer={{ width: "30px" }}
              children={<Eraser style={{ fill: "#fff", width: "13px" }} />}
              onClick={(e) => {
                e.stopPropagation();
                resetRow(index);
              }}
            />
          </ButtonContainer>
          {index !== 0 ? (
            <ButtonContainer>
              <ButtonCustom
                children={<Delete fontSize="10px" />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(filter.id);
                }}
                style={{ backgroundColor: "#710c04", padding: 0 }}
                styleContainer={{ width: "30px" }}
              />
            </ButtonContainer>
          ) : (
            filters.length > 1 && (
              <ButtonContainer>
                <ButtonCustom
                  children={<Delete fontSize="10px" />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(filter.id);
                  }}
                  style={{ backgroundColor: "#710c04", padding: 0 }}
                  styleContainer={{ width: "30px" }}
                />
              </ButtonContainer>
            )
          )}
        </ButtonsContainer>
      )}
    </Container>
  );
};

export default FilterItem;
