import { Typography, Grid, Card, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLORS_STATUS } from "../../../constants";
import DonutChart from "../../../components/chart/DonutChart";
import { getContractChangeStatistics_SERVICE } from "../../../services/documentServices";
import { CONTRACT_CHANGES_HEADERS_STATISTICS } from "./statisticItems";

const LabourContractStatistics = () => {
  const [data, setData] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    var currentDate = new Date();

    // Get the first date of the current year
    var startDate = new Date(currentDate.getFullYear(), 0, 1);

    // Format the start date
    var formattedStartDate =
      startDate.getMonth() +
      1 +
      "/" +
      startDate.getDate() +
      "/" +
      startDate.getFullYear();

    // Format the end date
    var formattedEndDate =
      currentDate.getMonth() +
      1 +
      "/" +
      currentDate.getDate() +
      "/" +
      currentDate.getFullYear();

    fetchData(formattedStartDate, formattedEndDate);
  }, []);

  const fetchData = async (startDate, endDate) => {
    try {
      setLoading(true);

      const { data } = await getContractChangeStatistics_SERVICE(
        startDate,
        endDate
      );
      data &&
        setData({
          pending: data.pending,
          approved: data.approved,
          rejected: data.rejected,
        });
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  const colorHandler = (key) => {
    switch (key) {
      case "pending":
        return "#0F4C75";
      case "rejected":
        return "#903749";
      case "approved":
        return "#0D7377";
      default:
        break;
    }
  };

  const dataDoun = {
    labels: ["a", "a", "a", "a", "a"],
    datasets: [
      {
        data: [5, 7, 3, 6, 8],
        backgroundColor: ["red", "blue", "yellow", "green", "orange"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "right",
      },
    },
  };

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Card sx={{ width: "100%" }}>
        <div
          style={{
            ...styles.container,
            marginBottom: 6,
            paddingTop: 2.5,
            width: "100%",
            // height: "auto",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* <Grid
        pt={2.5}
        marginBottom={6}
        key="statistics"
        width={"100%"}
        height={"auto"}
        sx={styles.container}
      > */}
          {/* <Grid sx={{ display: "flex", flexDirection: "row" }}> */}
          <Grid width={"50%"} p={"5px"} sx={{ display: "flex" }}>
            {CONTRACT_CHANGES_HEADERS_STATISTICS.map((item, index) => (
              <Grid
                key={`${item} ${index}`}
                sx={{
                  width: "33%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: colorHandler(item.key),
                  marginY: "5px",
                  borderRadius: "10px",
                  position: "relative",
                  marginRight: "10px",
                }}
              >
                {/* <Grid
                height={"10px"}
                sx={{ backgroundColor: "red", borderRadius: "5px" }}
                width={"10px"}
                mr={"5px"}
              /> */}
                <Typography
                  sx={{ position: "absolute", left: "10px", top: "10px" }}
                  fontSize={16}
                >
                  {item.label}
                </Typography>
                <Typography
                  sx={{ position: "absolute", right: "10px", bottom: "10px" }}
                  fontSize={18}
                >
                  {data[item.key]}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid width={"50%"} margin={"auto"}>
            <DonutChart colorHandler={colorHandler} data={data} total={20} />
          </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
        </div>
      </Card>
    </Box>
  );
};

const styles = {
  container: {
    // backgroundColor: "rgba(255,255,255,0.05)",
    borderRadius: "10px",
    height: "200px",
  },
};

export default LabourContractStatistics;
