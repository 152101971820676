export const structureOrderGenerator = (data) => {
  const newFields = {};

  for (const key in data.data) {
    let obj;
    if (key === "other") {
      obj = {
        ...newFields,
        [key]: otherGroupHandler(data.data[key]),
      };
      console.log(otherGroupHandler(data.data[key]));
    } else if (key === "contract_general_info") {
      obj = {
        ...newFields,
        [key]: [...data.data[key]],
      };
      obj.contract_general_info[0].value = "Struktur dəyişiklik əmri";
    } else {
      obj = {
        ...newFields,
        [key]: data.data[key],
      };
    }

    Object.assign(newFields, obj);
  }

  return newFields;
};

export const exclusionOrderGenerator = (data) => {
  const newFields = {};
  for (const key in data.data) {
    let obj;
    if (key === "contract_general_info") {
      obj = {
        ...newFields,
        [key]: [...data.data[key]],
      };
      obj.contract_general_info[0].value = "Kənarlaşdırma əmri";
    } else {
      obj = { ...newFields, [key]: data.data[key] };
    }

    Object.assign(newFields, obj);
  }

  return newFields;
};

const otherGroupHandler = (arr) => {
  const field = [];

  arr.forEach((element) => {
    if (element.label === "editRequest") {
      console.log(fieldEditRequest(element));
      field.push(...fieldEditRequest(element));
    } else if (element.label === "structureId") return;
    else field.push(element);
  });

  return field;
};

const fieldEditRequest = (obj) => {
  const fields = [
    {
      label: "Sorğu nömrəsi",
      type: "string",
      value: obj?.value?.requestNO,
    },
    {
      label: "Sorğunun yaradılma tarixi",
      type: "date",
      value: obj?.value?.createdAt,
    },
  ];

  return fields;
};
