import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Avatar,
  Box,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getEmployeeById } from "../../api/api";
import DeleteEmployeeDialog from "../../components/editEmployees/DeleteEmployeeDialog";
import EditAcademicDegree from "../../components/editEmployees/EditAcademicDegree";
import EditBankInfo from "../../components/editEmployees/EditBankInfo";
import EditContactInfo from "../../components/editEmployees/EditContactInfo";
import EditDocuments from "../../components/editEmployees/EditDocuments";
import EditEducationInfo from "../../components/editEmployees/EditEducationInfo";
import EditInventories from "../../components/editEmployees/EditInventories";
import EditLaborActivity from "../../components/editEmployees/EditLaborActivity";
import EditPersonalInformation from "../../components/editEmployees/EditPersonalInformation";
import EditRelationShipInfo from "../../components/editEmployees/EditRelationshipInfo";
import EditRewardsAndReprimands from "../../components/editEmployees/EditRewardsAndReprimands";
import EditSalarySupplements from "../../components/editEmployees/EditSalarySupplements";
import EditSocialStatus from "../../components/editEmployees/EditSocialStatus";
import EditVehicles from "../../components/editEmployees/EditVehicles";
import NewCommonDataDialog from "../../components/employees/NewCommonDataDialog";
import EditMilitaryInfo from "../../components/editEmployees/EditMilitaryInfo";
import EditDriverLicense from "../../components/editEmployees/EditDriveLicense";
import EditCertification from "../../components/editEmployees/EditCertification";
import EditDeductionsWages from "../../components/editEmployees/EditDeductionsWages";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

function Public() {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [employeeById, setEmployeeById] = useState();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedType, setSelectedType] = useState([
    "",
    {
      name: "",
      values: [],
    },
  ]);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getEmployee = async (id) => {
      try {
        const response = await getEmployeeById(id);
        setEmployeeById(response.data);
      } catch {
        setEmployeeById();
      }
    };
    getEmployee(id);
  }, [submitted]);

  if (!employeeById) {
    return <>Loading...</>;
  }
  return (
    <>
      <Card mb={6}>
        <CardContent>
          {employeeById ? (
            <EditPersonalInformation
              employeeById={employeeById}
              submitted={submitted}
              setSubmitted={setSubmitted}
              setSelectedType={setSelectedType}
              setOpenAddDialog={setOpenAddDialog}
            />
          ) : null}
        </CardContent>
      </Card>
      {/*Military*/}
      {employeeById?.isForeigner === false && (
        <>
          <EditMilitaryInfo
            submitted={submitted}
            setSubmitted={setSubmitted}
            employeeById={employeeById}
            setSelectedType={setSelectedType}
            setOpenAddDialog={setOpenAddDialog}
          />
        </>
      )}
      {/*DriveLicense*/}

      <>
        <EditDriverLicense
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setSelectedType={setSelectedType}
          setOpenAddDialog={setOpenAddDialog}
        />
      </>

      {/* Bank info */}
      <EditBankInfo
        submitted={submitted}
        setSubmitted={setSubmitted}
        employeeById={employeeById}
      />

      {/* Labor activity */}
      <>
        <EditLaborActivity
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setSelectedType={setSelectedType}
          setOpenAddDialog={setOpenAddDialog}
        />
      </>

      {/* Relationship info */}

      <>
        <EditRelationShipInfo
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
        />
      </>

      {/* Edit Contact Info */}

      <>
        <EditContactInfo
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
        />
      </>

      {/* Edit Education Info */}
      <>
        <EditEducationInfo
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
        />
      </>

      {/* Edit Academic Degree */}

      <>
        <EditAcademicDegree
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setOpenAddDialog={setOpenAddDialog}
          setSelectedType={setSelectedType}
        />
      </>

      {/* Edit Social Status */}

      <>
        <EditSocialStatus
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setOpenAddDialog={setOpenAddDialog}
          setSelectedType={setSelectedType}
        />
      </>

      {/* Edit Supplemental Wages */}

      <>
        <EditSalarySupplements
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setOpenAddDialog={setOpenAddDialog}
          setSelectedType={setSelectedType}
        />
      </>

      {/* Edit Rewards and reprimands*/}

      <>
        <EditRewardsAndReprimands
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
        />
      </>

      {/* Edit Inventories*/}

      <>
        <EditInventories
          submitted={submitted}
          selectedType={selectedType}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setOpenAddDialog={setOpenAddDialog}
          setSelectedType={setSelectedType}
        />
      </>

      {/* Edit Documents*/}
      <>
        <EditDocuments
          submitted={submitted}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setSelectedType={setSelectedType}
          setOpenAddDialog={setOpenAddDialog}
        />
      </>

      {/* Edit Vehicles */}
      <>
        <EditVehicles
          submitted={submitted}
          selectedType={selectedType}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setSelectedType={setSelectedType}
          setOpenAddDialog={setOpenAddDialog}
        />
      </>

      {/*Edit Certification*/}

      <>
        <EditCertification
          submitted={submitted}
          selectedType={selectedType}
          setSubmitted={setSubmitted}
          employeeById={employeeById}
          setSelectedType={setSelectedType}
          setOpenAddDialog={setOpenAddDialog}
        />
      </>
      <>
        <EditDeductionsWages
          submitted={submitted}
          setSubmitted={setSubmitted}
          selectedType={selectedType}
          employeeById={employeeById}
          setSelectedType={setSelectedType}
          setOpenAddDialog={setOpenAddDialog}
        />
      </>

      {/* Buttons */}
      <Grid sx={{ display: "flex", justifyContent: "flex-end", gap: 3 }}>
        <Button
          disabled={employeeById.isUsedInStaff}
          onClick={() => {
            setDeleteDialog(true);
          }}
          variant="outlined"
          color="error"
        >
          Əməkdaşı sil
        </Button>
        <Button
          onClick={() => {
            navigate("/employees", { replace: true });
          }}
          variant="contained"
          color="primary"
        >
          Geri qayıt
        </Button>
      </Grid>

      <NewCommonDataDialog
        openAddDialog={openAddDialog}
        setOpenAddDialog={setOpenAddDialog}
        submitted={submitted}
        setSubmitted={setSubmitted}
        selectedType={selectedType}
      />

      {/* Delete employee dialog */}
      <DeleteEmployeeDialog
        id={employeeById._id}
        setDeleteDialog={setDeleteDialog}
        deleteDialog={deleteDialog}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
    </>
  );
}

function EditEmployee() {
  return (
    <React.Fragment>
      <Helmet title="Əməkdaş Məlumatlarını dəyişdir" />
      <ToastContainer />
      <Typography variant="h3" gutterBottom display="inline">
        Əməkdaş Məlumatlarını dəyişdir
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Public />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EditEmployee;
