import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";

import Header from "../../components/layouts/header/Header";
import SalaryTable from "../../components/table/SalaryTabel";
import { useEffect, useMemo, useState } from "react";
import Column from "../../models/table/Column";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import { OPERATORS_CONTAINING } from "../../constants";
import { getSalaryHistory, postSalaryCalculation } from "./services";
import { getAllStructures } from "../../api/api";
import SelectInput from "../../components/ui/Select/SelectInput";

const header = {
  title: "Əməkhaqqının hesablanması",
  variant: "h1",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const cellStyle = {
  textAlign: "center",
};

export default function SalaryCalculation() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [structures, setStructure] = useState([]);
  const [currStructure, setCurrStructure] = useState("");
  const [currStructureID, setCurrStructureID] = useState("");

  // const memoizedColumns = useMemo(() => columns, [columns]);

  useEffect(() => {
    paginationFetchingHandler();
    getStructures();
  }, [limit, offset, filters]);

  // kesr hisseni 2 reqeme salmaq ucun
  function formatToTwoDecimalPlaces(number) {
    return Math.floor(number * 100) / 100;
  }
  useEffect(() => {
    const columns_ = [
      new Column({
        path: "employee.lastname",
        headerName: "Soyad",
        groupHeader: null,
      }),
      new Column({
        path: "employee.firstname",
        headerName: "Ad",
        groupHeader: null,
      }),
      new Column({
        path: "employee.fathername",
        headerName: "Ata adı",
        groupHeader: null,
      }),
      new Column({
        path: "employee.pin",
        headerName: "Fin",
        groupHeader: null,
      }),

      // new Column({
      //   path: "employee.position",
      //   headerName: "Vəzifə",
      // }),
      new Column({
        path: "salary",
        headerName: "Əməkhaqqı",
        filterType: OPERATORS_CONTAINING.NUMBER,
        groupHeader: null,

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>{formatToTwoDecimalPlaces(row.salary)}</div>
          );
        },
      }),

      new Column({
        path: "workingHours",
        headerName: "Ayın iş saatları",
        groupHeader: "Hesablanıb",
        headerColor: "#6c8139",
      }),
      new Column({
        path: "actualWorkingHours",
        headerName: "Faktiki iş saatları",
        groupHeader: "Hesablanıb",
        headerColor: "#6c8139",
      }),
      new Column({
        path: "totalBeforeTax",
        headerName: "Hesablanmış əməkhaqqı",
        groupHeader: "Hesablanıb",
        headerColor: "#6c8139",

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.totalBeforeTax)}
            </div>
          );
        },
      }),
      // new Column({
      //   path: "",
      //   headerName: "Məzuniyyət",
      //  groupHeader: "Hesablanib",

      // }),
    ];
    // MonthlySupplementalWages columns create part(birdefelik elaveler)
    const uniqueMonthlySupplementalWagesNames = [
      ...new Set(
        data.flatMap((item, itemIndex) =>
          item.monthlySupplementalWages.map((wage, wageIndex) => ({
            name: wage.name,
            dataIndex: itemIndex,
            wageIndex: wageIndex,
          }))
        )
      ),
    ];
    const uniqueMonthlySupplementalWagesNameObjects = [
      ...uniqueMonthlySupplementalWagesNames
        .reduce((map, obj) => {
          if (!map.has(obj.name)) {
            map.set(obj.name, obj);
          }
          return map;
        }, new Map())
        .values(),
    ];
    const dynamicMonthlySupplementalWagesColumns =
      uniqueMonthlySupplementalWagesNameObjects.map((wage) => {
        return new Column({
          path: `monthlySupplementalWages.[${wage.name}].value`,
          headerName: wage.name,
          groupHeader: "Birdəfəlik əlavələr",
          headerColor: "#6c8139",
          colSpan: uniqueMonthlySupplementalWagesNameObjects.length,
          filterType: OPERATORS_CONTAINING.NUMBER,
          renderCell: (row, column) => {
            return (
              <div style={cellStyle}>
                {formatToTwoDecimalPlaces(
                  row.monthlySupplementalWages[wage.wageIndex].value
                )}
              </div>
            );
          },
        });
      });

    // SupplementalWages columns create part(daimi elaveler)
    const uniqueSupplementalWagesNames = [
      ...new Set(
        data.flatMap((item, itemIndex) =>
          item.supplementalWages.map((wage, wageIndex) => ({
            name: wage.name,
            dataIndex: itemIndex,
            wageIndex: wageIndex,
          }))
        )
      ),
    ];

    const uniqueSupplementalWagesNameObjects = [
      ...uniqueSupplementalWagesNames
        .reduce((map, obj) => {
          if (!map.has(obj.name)) {
            map.set(obj.name, obj);
          }
          return map;
        }, new Map())
        .values(),
    ];
    const dynamicSupplementalWagesColumns =
      uniqueSupplementalWagesNameObjects.map((wage) => {
        return new Column({
          path: `supplementalWages.[${wage.name}].value`,
          headerName: wage.name,
          groupHeader: "Daimi əlavələr",
          headerColor: "#6c8139",

          colSpan: uniqueSupplementalWagesNameObjects.length,

          filterType: OPERATORS_CONTAINING.NUMBER,
          renderCell: (row, column) => {
            return (
              <div style={cellStyle}>
                {formatToTwoDecimalPlaces(
                  row.supplementalWages[wage.wageIndex].value
                )}
              </div>
            );
          },
        });
      });

    // monthlyDeductionWages columns create part (birdefelik tutulmalar)
    const uniqueMonthlyDeductionWagesNames = [
      ...new Set(
        data.flatMap((item, itemIndex) =>
          item.monthlyDeductionWages.map((wage, wageIndex) => ({
            name: wage.name,
            dataIndex: itemIndex,
            wageIndex: wageIndex,
          }))
        )
      ),
    ];

    const uniqueMonthlyDeductionWagesNameObjects = [
      ...uniqueMonthlyDeductionWagesNames
        .reduce((map, obj) => {
          if (!map.has(obj.name)) {
            map.set(obj.name, obj);
          }
          return map;
        }, new Map())
        .values(),
    ];
    const dynamicMonthlyDeductionColumns =
      uniqueMonthlyDeductionWagesNameObjects.map((wage) => {
        return new Column({
          path: `monthlyDeductionWages.[${wage.name}].value`,
          headerName: wage.name,
          filterType: OPERATORS_CONTAINING.NUMBER,
          groupHeader: "Birdəfəlik tutulmalar",
          headerColor: "#6c8139",

          renderCell: (row, column) => {
            return (
              <div style={cellStyle}>
                {formatToTwoDecimalPlaces(
                  row.monthlyDeductionWages[wage.wageIndex].value
                )}
              </div>
            );
          },
        });
      });
    // deductionWages columns create part (daimi tutulma)
    const uniqueDeductionWagesNames = [
      ...new Set(
        data.flatMap((item, itemIndex) =>
          item.deductionWages.map((wage, wageIndex) => ({
            name: wage.name,
            dataIndex: itemIndex,
            wageIndex: wageIndex,
          }))
        )
      ),
    ];

    const uniqueDeductionWagesNameObjects = [
      ...uniqueDeductionWagesNames
        .reduce((map, obj) => {
          if (!map.has(obj.name)) {
            map.set(obj.name, obj);
          }
          return map;
        }, new Map())
        .values(),
    ];
    const dynamicDeductionWagesColumns = uniqueDeductionWagesNameObjects.map(
      (wage) => {
        return new Column({
          path: `deductionWages.[${wage.name}].value`,
          headerName: wage.name,
          filterType: OPERATORS_CONTAINING.NUMBER,
          groupHeader: "Daimi tutulmalar",
          headerColor: "#6c8139",

          renderCell: (row, column) => {
            return (
              <div style={cellStyle}>
                {formatToTwoDecimalPlaces(
                  row.deductionWages[wage.wageIndex].value
                )}
              </div>
            );
          },
        });
      }
    );

    columns_?.push(
      ...dynamicMonthlySupplementalWagesColumns,
      ...dynamicSupplementalWagesColumns,
      new Column({
        path: "totalWithSupplumental",
        headerName: "Cəmi",
        groupHeader: null,
        headerColor: "white",
        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>{formatToTwoDecimalPlaces(row.salary)}</div>
          );
        },
      }),
      ...dynamicMonthlyDeductionColumns,
      ...dynamicDeductionWagesColumns,
      new Column({
        path: "allDeduction",
        headerName: "Cəmi tutulmuşdur",

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.allDeduction)}
            </div>
          );
        },
      }),
      new Column({
        path: "nettSalary",
        headerName: "Ödənilməli məbləğ",

        renderCell: (row, col) => {
          return (
            <div style={cellStyle}>
              {formatToTwoDecimalPlaces(row.nettSalary)}
            </div>
          );
        },
      })
    );
    setColumns(columns_?.length > 11 ? [...columns_] : []);
  }, [data]);
  const paginationFetchingHandler = async () => {
    if (filters.length) {
      await getAllSalaryHistory([...filters]);
    } else {
      await getAllSalaryHistory();
    }
  };
  const getAllSalaryHistory = async (filters = undefined) => {
    try {
      setIsLoading(true);

      if (filters) {
        const resp = await getSalaryHistory({
          offset: offset || 0,
          limit: limit || 10,
          filters: encodeURIComponent(JSON.stringify([...filters])),
        });
        setData([...resp.data.staffWages]);
        setTotal(resp.data.total);
      } else {
        const resp = await getSalaryHistory({
          offset: offset || 0,
          limit: limit || 10,
        });

        setData([...resp.data.staffWages]);
        setTotal(resp.data.total);
      }
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setIsLoading(false);
    }
  };
  // salary calculation
  const calculateSalary = async () => {
    const resp = postSalaryCalculation(currStructureID);
  };
  const handleStructureChange = (value) => {
    setCurrStructure(value);
    const selectedStructure = structures.find(
      (structure) => structure.organization.name === value
    );
    setCurrStructureID(selectedStructure._id);
  };
  const getStructures = async () => {
    const structures = await getAllStructures();
    setStructure(structures.data.structures);
  };

  // swipe datas
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChangeTab = (e, value) => {
    setValue(value);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div className="d-flex" style={{ width: "300px", marginRight: "50px" }}>
          <SelectInput
            label={"Struktur: "}
            value={currStructure}
            onChange={handleStructureChange}
          >
            {structures.map((structure) => (
              <option
                key={structure?.organization?.name}
                value={structure?.organization?.name}
              >
                {structure?.organization?.name}
              </option>
            ))}
          </SelectInput>
        </div>
        <Button
          disabled={!currStructure}
          variant="contained"
          color="primary"
          onClick={() => {
            calculateSalary();
          }}
        >
          Hesabla
        </Button>
      </Grid>

      {/* <Box>
        <Box>
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab
              label="İşəgötürən tutulmalarına görə"
              value={0}
              {...a11yProps(0)}
            />
            <Tab label="Digər tutulmalara görə" value={1} {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box> */}

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <Grid className="mt-4">
            {columns.length ? (
              <SalaryTable
                columns={columns}
                data={data}
                totalRows={total}
                offset={offset}
                setOffset={(value) => setOffset(value)}
                limit={limit}
                setLimit={(value) => setLimit(value)}
                isLoading={isLoading}
                applyFiltersHandler={(filters) => setFilters(filters)}
                cancelAppliedFilters={() => setFilters([])}
                //   downloadHandler={(cols) => downlaodHandler(cols)}
              />
            ) : (
              <></>
            )}
          </Grid>
        </TabPanel>

        {/* <TabPanel value={value} index={1}>
         
        </TabPanel> */}
      </SwipeableViews>
    </>
  );
}
