import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const EmptyPosition = () => {
  const navigate = useNavigate();
  return (
    <form>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Boş vəzifənin həvalə edilməsi
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Əməkdaş Məlumatları
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Əməkdaş">Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  labelId="fieldOfActivity"
                  label="Fəaliyyət Sahəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Əməkdaş(A.S.A) 1</MenuItem>
                  <MenuItem value="employee2">Əməkdaş(A.S.A) 2</MenuItem>
                  <MenuItem value="employee3">Əməkdaş(A.S.A) 3</MenuItem>
                </Select>
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel id="Müqavilə nömrəsi">
                    Müqavilə nömrəsi
                  </InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    labelId="fieldOfActivity"
                    label="Fəaliyyət Sahəsi"
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="employee1">Müqavilə nömrəsi 1</MenuItem>
                    <MenuItem value="employee2">Müqavilə nömrəsi 2</MenuItem>
                    <MenuItem value="employee3">Müqavilə nömrəsi 3</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Müqavilənin tarixi"
                    minDate={new Date(new Date().getTime() - 86400000 * 30)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{ width: "100%", marginBottom: "16px" }}
                variant="outlined"
                my={2}
              />
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Təşkilat">Təşkilat</InputLabel>
                <Select
                  name="Təşkilat"
                  labelId="fieldOfActivity"
                  label="Fəaliyyət Sahəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Təşkilat 1</MenuItem>
                  <MenuItem value="employee2">Təşkilat 2</MenuItem>
                  <MenuItem value="employee3">Təşkilat 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Struktur">Struktur</InputLabel>
                <Select
                  name="Struktur"
                  labelId="fieldOfActivity"
                  label="Fəaliyyət Sahəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Struktur 1</MenuItem>
                  <MenuItem value="employee2">Struktur 2</MenuItem>
                  <MenuItem value="employee3">Struktur 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Substruktur">Substruktur</InputLabel>
                <Select
                  name="Substruktur"
                  labelId="fieldOfActivity"
                  label="Fəaliyyət Sahəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Substruktur 1</MenuItem>
                  <MenuItem value="employee2">Substruktur 2</MenuItem>
                  <MenuItem value="employee3">Substruktur 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Vəzifə">Vəzifə</InputLabel>
                <Select
                  name="Vəzifə"
                  labelId="fieldOfActivity"
                  label="Fəaliyyət Sahəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Vəzifə 1</MenuItem>
                  <MenuItem value="employee2">Vəzifə 2</MenuItem>
                  <MenuItem value="employee3">Vəzifə 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Boş vəzifə məlumatları
              </Typography>
              <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Başlama tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  label="Başlama tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Ödənişin növü">Ödənişin növü</InputLabel>
                <Select
                  name="Ödənişin növü"
                  labelId="fieldOfActivity"
                  label="Fəaliyyət Sahəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Ödənişin növü 1</MenuItem>
                  <MenuItem value="employee2">Ödənişin növü 2</MenuItem>
                  <MenuItem value="employee3">Ödənişin növü 3</MenuItem>
                </Select>
              </FormControl>
              <Grid
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  display: "flex",
                  gap: "25px",
                }}
              >
                <TextField
                  name="Əmək haqqına əlavə"
                  label="Əmək haqqına əlavə"
                  sx={{ width: "70%" }}
                  variant="outlined"
                  my={2}
                />
                <FormControl sx={{ width: "30%" }}>
                  <InputLabel id="Valyuta">Valyuta</InputLabel>
                  <Select
                    name="Valyuta"
                    labelId="fieldOfActivity"
                    label="Fəaliyyət Sahəsi"
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value="employee1">Valyuta 1</MenuItem>
                    <MenuItem value="employee2">Valyuta 2</MenuItem>
                    <MenuItem value="employee3">Valyuta 3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Təşkilat">Təşkilat</InputLabel>
                <Select
                  name="Təşkilat"
                  labelId="fieldOfActivity"
                  label="Təşkilat"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="Təşkilat1">Təşkilat 1</MenuItem>
                  <MenuItem value="Təşkilat2">Təşkilat 2</MenuItem>
                  <MenuItem value="Təşkilat3">Təşkilat 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Struktur">Struktur</InputLabel>
                <Select
                  name="Struktur"
                  labelId="fieldOfActivity"
                  label="Struktur"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="Struktur1">Struktur 1</MenuItem>
                  <MenuItem value="Struktur2">Struktur 2</MenuItem>
                  <MenuItem value="Struktur3">Struktur 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Substruktur">Substruktur</InputLabel>
                <Select
                  name="Substruktur"
                  labelId="fieldOfActivity"
                  label="Substruktur"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="Substruktur1">Substruktur 1</MenuItem>
                  <MenuItem value="Substruktur2">Substruktur 2</MenuItem>
                  <MenuItem value="Substruktur3">Substruktur 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Vəzifə">Vəzifə</InputLabel>
                <Select
                  name="Vəzifə"
                  labelId="fieldOfActivity"
                  label="Vəzifə"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="Vəzifə1">Vəzifə 1</MenuItem>
                  <MenuItem value="Vəzifə2">Vəzifə 2</MenuItem>
                  <MenuItem value="Vəzifə3">Vəzifə 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid mb={4}>
            <TextField
              name="Əmək məcəlləsinin maddəsi"
              label="Əmək məcəlləsinin maddəsi"
              fullWidth
              variant="outlined"
              my={2}
            />
          </Grid>
          <Grid mb={10}>
            <TextField
              name="Qeyd"
              label="Qeyd"
              fullWidth
              variant="outlined"
              my={2}
            />
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default EmptyPosition;
