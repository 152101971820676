/* eslint-disable prettier/prettier */
import { Delete as DeleteIcon } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  FormControlLabel,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useState } from "react";
import AddCommonDataDialog from "./AddCommonDataDialog";

import DeleteCommonDataDialog from "./DeleteCommonDataDialog";
import EditCommonDataDialog from "./EditCommonDataDialog";
import { useEffect } from "react";
import { getCompanyStructures } from "../../services/structure/StructureServices";

export default function CommonDataTable({ dispatch, state }) {
  const { selectedType, selectedField } = state;
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dense, setDense] = React.useState(true);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [structureDatas, setStructureDatas] = useState({
    total: 0,
    structures: [],
  });
  const [currStructure, setCurrStructure] = useState(structureDatas?.structures?.find((s) => s?._id === state?.selectedRow?.structureId)
  ?.organization?.name ?? "");

  useEffect(() => {
    setCurrStructure(structureDatas?.structures?.find((s) => s?._id === state?.selectedRow?.structureId)?.organization?.name)
  },[state?.selectedRow])
  function createData(
    name,
    value,
    reason,
    valueType,
    vacationDays,
    parent,
    lastname,
    gender,
    structureId,
    fathername,
    pin
  ) {
    return {
      name,
      value,
      reason,
      valueType,
      vacationDays,
      parent,
      lastname,
      gender,
      structureId,
      fathername,
      pin,
    };
  }

  let rows = [];

  const createRow = () => {
    if (
      selectedType[0] === "socialStatusTypes" ||
      selectedType[0] === "overtimeRules" ||
      selectedType[0] === "academicDegrees" ||
      selectedType[0] === "supplementalWageLabour"
    ) {
      selectedType[1].values.forEach((item, index) => {
        const { name, value, reason, valueType, operations } = item;
        return (rows = [...rows, createData(name, value, reason, valueType)]);
      });
    } else if (selectedType[0] === "supplementalWageLabourItems") {
      selectedType[1].values.forEach((item, index) => {
        const {
          name,
          value,
          reason,
          valueType,
          vacationDays,
          parent,
          operations,
        } = item;
        return (rows = [
          ...rows,
          createData(name, value, reason, valueType, vacationDays, parent),
        ]);
      });
    } else if (
      selectedType[0] === "inventoryItems" ||
      selectedType[0] === "carModels"
    ) {
      selectedType[1].values.forEach((item, index) => {
        const { name, parent } = item;
        return (rows = [...rows, createData(name, "", "", "", "", parent)]);
      });
    } else if (selectedType[0] === "recruiterList") {
      selectedType[1].values.forEach((item, index) => {
        const { name, lastname, fathername,gender, pin, structureId } = item;
        return (rows = [...rows, { name, lastname, fathername, gender, pin, structureId }]);
      });
    } else {
      selectedType[1].values.map((item, index) => {
        return (rows = [...rows, createData(item)]);
      });
    }
  };

  createRow();
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  useEffect(() => {
    (async function fetchCompanyStructures() {
      try {
        const data = await getCompanyStructures();
        if (data && data.structures && data.structures.length > 0) {
          setStructureDatas(data);
          setCurrStructure(data?.structures?.organization?._id);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    })();
  }, []);


  return (
    <>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography variant="h4" id="tableTitle" noWrap>
            {selectedType[1].name}
          </Typography>
        </div>
        <div>
          <Tooltip title="Əlavə et">
            <Button
              onClick={() => {
                setOpenAddDialog(true);
              }}
              variant="contained"
              endIcon={<AddCircleIcon />}
            >
              <Typography noWrap>Əlavə et</Typography>
            </Button>
          </Tooltip>
        </div>

        <AddCommonDataDialog
          setOpenAddDialog={setOpenAddDialog}
          openAddDialog={openAddDialog}
          state={state}
          dispatch={dispatch}
          selected_type={selectedType[1].name}
          structureDatas={structureDatas}
          currStructure={{set: setCurrStructure, state: currStructure}}
        />
      </Toolbar>

      {/* Table container */}
      <TableContainer component={Paper}>
        <Table
          size={dense ? "small" : "medium"}
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow sx={{ width: "100%" }}>
              <TableCell
                sx={{
                  width:
                    selectedType[0] === "socialStatusTypes" ||
                    selectedType[0] === "academicDegrees" ||
                    selectedType[0] === "overtimeRules" ||
                    selectedType[0] === "supplementalWageGeneral"
                      ? "45%"
                      : "20%",
                }}
              >
                Adı
              </TableCell>

              {selectedType[0] === "recruiterList" ? (
                <TableCell
                  sx={{
                    width:
                      selectedType[0] === "supplementalWageLabourItems"
                        ? "20%"
                        : "18%",
                  }}
                >
                  Soyad
                </TableCell>
              ) : null}
              {selectedType[0] === "recruiterList" ? (
                <TableCell
                  sx={{
                    width:
                      selectedType[0] === "supplementalWageLabourItems"
                        ? "20%"
                        : "18%",
                  }}
                >
                  Ata adı
                </TableCell>
              ) : null}
              {selectedType[0] === "recruiterList" ? (
                <TableCell
                  sx={{
                    width:
                      selectedType[0] === "supplementalWageLabourItems"
                        ? "20%"
                        : "12%",
                  }}
                >
                  Cinsi
                </TableCell>
              ) : null}

              {selectedType[0] === "recruiterList" ? (
                <TableCell
                  sx={{
                    width:
                      selectedType[0] === "supplementalWageLabourItems"
                        ? "20%"
                        : "10%",
                  }}
                >
                  FİN
                </TableCell>
              ) : null}
            {selectedType[0] === "recruiterList" ? (
                <TableCell
                  sx={{
                    width:
                      selectedType[0] === "supplementalWageLabourItems"
                        ? "20%"
                        : "16%",
                  }}
                >
                  Struktur
                </TableCell>
              ) : null}
              {selectedType[0] === "socialStatusTypes" ||
              selectedType[0] === "overtimeRules" ||
              selectedType[0] === "academicDegrees" ||
              selectedType[0] === "supplementalWageLabour" ||
              selectedType[0] === "supplementalWageLabourItems" ? (
                <TableCell
                  sx={{
                    width:
                      selectedType[0] === "supplementalWageLabourItems"
                        ? "20%"
                        : "30%",
                  }}
                >
                  Dəyəri
                </TableCell>
              ) : null}

              {(selectedType[0] === "inventoryItems" ||
                selectedType[0] === "carModels" ||
                selectedType[0] === "supplementalWageLabourItems") && (
                <TableCell sx={{ width: "20%" }}>Tipi</TableCell>
              )}

              {selectedType[0] === "supplementalWageLabour" ? (
                <TableCell sx={{ width: "30%" }}>Səbəbi</TableCell>
              ) : null}

              {selectedType[0] === "supplementalWageLabourItems" ? (
                <TableCell sx={{ width: "20%" }}>Məzuniyyət günü</TableCell>
              ) : null}

              <TableCell sx={{ width: "30%" }} align="right">
                Redaktə et
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <>
                  <TableRow
                    // style={{ height: (dense ? 33 : 53) * row }}
                    key={row.name}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {selectedType[0] === "recruiterList"
                        ? row.name
                        : row.name}
                    </TableCell>

                    {selectedType[0] === "recruiterList" ? (
                      <TableCell component="th" scope="row">
                        {row.lastname}
                      </TableCell>
                    ) : null}

                    {selectedType[0] === "recruiterList" ? (
                      <TableCell component="th" scope="row">
                        {row.fathername}
                      </TableCell>
                    ) : null}
                    {selectedType[0] === "recruiterList" ? (
                      <TableCell component="th" scope="row">
                        {row.gender === "male" ? "kişi" : "qadın"}
                      </TableCell>
                    ) : null}

                    {selectedType[0] === "recruiterList" ? (
                      <TableCell component="th" scope="row">
                        {row.pin}
                      </TableCell>
                    ) : null}
                    {selectedType[0] === "recruiterList" ? (
                      <TableCell component="th" scope="row">
                        {structureDatas?.structures?.find(
                            (s) => s?._id === row.structureId
                          )?.organization?.name}
                      </TableCell>
                    ) : null}
                    {selectedType[0] === "socialStatusTypes" ||
                    selectedType[0] === "overtimeRules" ||
                    selectedType[0] === "academicDegrees" ||
                    selectedType[0] === "supplementalWageLabour" ||
                    selectedType[0] === "supplementalWageLabourItems" ? (
                      <TableCell component="th" scope="row">
                        {row.value}
                        {(selectedType[0] === "supplementalWageLabour" ||
                          selectedType[0] === "supplementalWageLabourItems") &&
                        row.valueType === "percentage" ? (
                          <span>%</span>
                        ) : (
                          <span
                            style={{
                              // fontWeight: "bold",
                              fontSize: "19px",
                            }}
                          >
                            &#8380;
                          </span>
                        )}
                      </TableCell>
                    ) : null}

                    {(selectedType[0] === "inventoryItems" ||
                      selectedType[0] === "carModels" ||
                      selectedType[0] === "supplementalWageLabourItems") && (
                      <TableCell component="th" scope="row">
                        {row.parent}
                      </TableCell>
                    )}

                    {selectedType[0] === "supplementalWageLabour" ? (
                      <TableCell component="th" scope="row">
                        {row.reason}
                      </TableCell>
                    ) : null}

                    {selectedType[0] === "supplementalWageLabourItems" ? (
                      <TableCell component="th" scope="row">
                        {row.vacationDays}
                      </TableCell>
                    ) : null}

                    <TableCell
                      padding="9px"
                      component="th"
                      scope="row"
                      align="right"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                        }}
                      >
                        <EditIcon
                          sx={{
                            mr: 3,
                            cursor: "pointer",
                            "&:hover": { color: "green" },
                          }}
                          onClick={() => {
                            setOpenEditDialog(true);
                            dispatch({
                              type: "SET_SELECTED_ROW",
                              payload: row,
                            });
                          }}
                        />
                        <DeleteIcon
                          sx={{
                            cursor: "pointer",
                            "&:hover": { color: "red" },
                          }}
                          onClick={() => {
                            setOpenDeleteDialog(true);
                            dispatch({
                              type: "SET_SELECTED_ROW",
                              payload: row,
                            });
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
        <EditCommonDataDialog
          openEditDialog={openEditDialog}
          setOpenEditDialog={setOpenEditDialog}
          state={state}
          dispatch={dispatch}
          structureDatas={structureDatas}
          currStructure={{set: setCurrStructure, state: currStructure}}
        />
        <DeleteCommonDataDialog
          setOpenDeleteDialog={setOpenDeleteDialog}
          openDeleteDialog={openDeleteDialog}
          state={state}
          dispatch={dispatch}
        />
      </TableContainer>
      <FormControlLabel
        style={{ marginLeft: "0" }}
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Yığcam görünüm"
      />
    </>
  );
}
