import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";

// AQMI sorgulari------------------------

export const getBulletins = (payload) => {
  return api.get("employee/temporary-loss-of-work-capacity", {
    params: {
      offset: payload.offset,
      limit: payload.limit,
      ...(payload.filters?.length && { filter: payload.filters }),
    },
  });
};

export const createNewBulletinData = async (data) => {
  try {
    const response = await api.post(
      `/employee/temporary-loss-of-work-capacity`,
      data,
      { responseType: "blob" }
    );
    toast.success("Yeni bulleten uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err?.response?.data?.errors?.map((item) => {
      return toast.error("Xəta baş verdi", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getMedicalOrganizationsList = async () => {
  try {
    const response = await api.get(
      `employee/common?selectedField=medicalOrganizationList`
    );
    return response;
  } catch (err) {
    console.log(err);
    // err?.response?.data?.errors?.map((item) => {
    //   return toast.error("Xəta baş verdi", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // });
  }
};

export const addNewMedicalOrganization = async (payload) => {
  try {
    const response = await api.post(`employee/common`, {
      data: payload,
    });
    toast.success("Uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error("Xəta baş verdi", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteTLOWCData = async (id) => {
  try {
    const response = await api.delete(
      `employee/temporary-loss-of-work-capacity/${id}`
    );
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getTLOWCData = (id) => {
  return api.get(`employee/temporary-loss-of-work-capacity/${id}`);
};

export const updateTLOWCData = async (data) => {
  try {
    const response = await api.put(
      `employee/temporary-loss-of-work-capacity/${data.id}`,
      {
        ...data,
      }
    );
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export function fileToBase64(file) {
  console.log(file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export const downloadDatagridDatas = async ({
  columns,
  filters = undefined,
}) => {
  const url = `employee/temporary-loss-of-work-capacity/download?filter=${filters}&columns=${columns}&lang=az`;

  return await api.get(url, { responseType: "blob" });
};

// Namelum sebeb sorgulari------------------------

export const getUnknownReasons = (payload) => {
  return api.get("employee/unexplained-absence", {
    params: {
      offset: payload.offset,
      limit: payload.limit,
      ...(payload.filters?.length && { filter: payload.filters }),
    },
  });
};

export const createNewUnknownReason = async (data) => {
  try {
    const response = await api.post(`/employee/unexplained-absence`, data);
    toast.success("Yeni məlumat uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error("Xəta baş verdi", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const downloadUnknownReasonsDatagridDatas = async ({
  columns,
  filters = undefined,
}) => {
  const url = `employee/unexplained-absence/download?filter=${filters}&columns=${columns}&lang=az`;

  return await api.get(url, { responseType: "blob" });
};

export const deleteUnknownReason = async (id) => {
  try {
    const response = await api.delete(`employee/unexplained-absence/${id}`);
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getUnknownReason = (id) => {
  return api.get(`employee/unexplained-absence/${id}`);
};
export const getEmployeeList = () => {
  return api.get(`employee/business-trip/employee-list`);
};
export const createBusinessTrip = (data) => {
  return api.post(`employee/business-trip`, data);
};
export const getBusinessTrip = (filter, limit, offset) => {
  if (filter.length > 0) {
    const processedFilter = filter.map((item) => ({
      ...item,
      value:
        item.column === "tripDuration"
          ? Number(item.value)
          : item.operator === "equalsTo" &&
            (item.value === "true" || item.value === "false")
          ? item.value === "true"
          : item.value,
    }));

    const encodeFilter = encodeURIComponent(JSON.stringify(processedFilter));
    return api.get(
      `employee/business-trip?filter=${encodeFilter}&limit=${limit}&offset=${offset}`
    );
  } else {
    return api.get(`employee/business-trip?limit=${limit}&offset=${offset}`);
  }
};

export const checkBusinessTripDataList = (employeeId, substituteId) => {
  return api.get(
    `employee/business-trip/date-list?employeeIds=${employeeId}&employeeIds=${substituteId}`
  );
};
export const getBusinessTripDocument = async (id) => {
  return await api.get(`employee/business-trip/${id}`);
};
export const updateBusinessTrip = async (id, data) => {
  return await api.put(`employee/business-trip/${id}`, data);
};
export const getBusinessTripDownload = async ({
  filter,
  columns,
  isWithFilter,
}) => {
  const str = isWithFilter
    ? `employee/business-trip/export?filter=${filter}&columns=${columns}`
    : `employee/business-trip/export?columns=${columns}`;

  return await api.get(str, { responseType: "blob" });
};
export const updateStatusBusinessTrip = async (id) => {
  return await api.patch(`employee/business-trip/${id}/status`, {
    status: "cancelled",
  });
};

export const updateUnknownReason = async (data) => {
  try {
    const response = await api.put(`employee/unexplained-absence/${data.id}`, {
      ...data,
    });
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
