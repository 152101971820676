import React, { useState } from "react";
import styled from "@emotion/styled";

import {
  Grid,
  Paper as MuiPaper,
  Typography,
  Button,
  TextField,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { spacing } from "@mui/system";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { Field, FieldArray, Form, Formik } from "formik";
import { setOrganizationInfo } from "../../../api/api";
import { workDayModes, workHourModes } from "../../../constants/constants";
import { banksList } from "../../../constants/generally/BankInfos";
import { structureDetailsValidation } from "../../employees/Validations";
import { fieldOfActivity } from "./mockFieldOfActivity";

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function StructureDetailsTable({
  submitted,
  companyData,
  tableHeader,
  setSubmitted,
  selectedType,
  setDisableButton,
  setSelectedButton,
  districts,
}) {
  const [editMode, setEditMode] = useState(false);
  const action = React.useRef(null);
  const handleBankSelect = (item) => {
    const selectBank = banksList.find((bank) => {
      return bank.name === item;
    });
    return selectBank;
  };

  const handleIsSameAddress = (isSameAddress, setFieldValue) => {
    setFieldValue("isSameAddress", isSameAddress);
    if (isSameAddress) {
      ["actualAddress", "actualDistrict"].forEach((field) => {
        setFieldValue(field, undefined);
      });
    } else {
      ["actualAddress", "actualDistrict"].forEach((field) => {
        setFieldValue(field, null);
      });
    }
  };

  const options = Object.entries(fieldOfActivity).map(([id, name]) => ({
    id,
    name,
  }));

  return (
    <div>
      <Paper>
        {/* Header */}
        <div className="structureHead">
          <div className="structureHead-left">
            <Typography variant="h4" id="tableTitle" noWrap>
              {selectedType?.organization?.name}{" "}
              {tableHeader.structure + " " + tableHeader.description}
            </Typography>
          </div>
          {companyData?.logo ? (
            <figure>
              <img
                src={`https://chr.blink-bi.az/api/employee/s/logo/${companyData.logo}`}
                alt="Structure Pitcure"
              />
            </figure>
          ) : null}
        </div>

        <Formik
          initialValues={{
            insuranceAccountNumber: companyData.insuranceAccountNumber || "",
            VOEN: companyData.VOEN || "",
            oilIndustry: companyData.oilIndustry,
            fieldOfActivity: companyData.fieldOfActivity || "",
            legalAddress: companyData.legalAddress || "",
            isSameAddress: companyData.isSameAddress || false,
            legalDistrict: companyData.legalDistrict?.value || null,
            actualDistrict: companyData.actualDistrict?.value || null,
            actualAddress: companyData.actualAddress || "",
            contactInfo: companyData.contactInfo || "",
            bankDetails:
              companyData.bankInfos.length === 0
                ? [
                    {
                      bankName: "",
                      bankVoen: "",
                      swift: "",
                      bankCode: "",
                      accountNumber: "",
                      iban: "",
                    },
                  ]
                : companyData.bankInfos,
            workHourMode: companyData.workHourMode,
            workDayMode: companyData.workDayMode,
          }}
          enableReinitialize={true}
          validationSchema={structureDetailsValidation(companyData?._id)}
          onSubmit={async (values, { setSubmitting }) => {
            const modifeValues = {
              ...values,
              isSameAddress: values.isSameAddress.toString(),
            };

            const response = await setOrganizationInfo(
              companyData._id,
              modifeValues
            );
            if (response && response.data) {
              setSubmitting(false);
              setSubmitted(!submitted);
              setEditMode(false);
              setDisableButton(false);
            }
          }}
        >
          {({
            values,
            errors,
            setErrors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            isValid,
            dirty,
          }) => (
            <>
              {/* errors?.bankDetails?.[index]?.iban &&
            errors?.bankDetails?.[index]?.iban */}
              <Card
                mb={6}
                sx={{
                  pt: 2,
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={6} mb={3}>
                    <Grid item md={6}>
                      <TextField
                        type="text"
                        name="VOEN"
                        fullWidth
                        label="VOEN"
                        value={values.VOEN}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={!editMode}
                        error={errors?.VOEN && Boolean(errors?.VOEN)}
                        helperText={errors?.VOEN && errors?.VOEN}
                        my={2}
                        InputProps={{
                          readOnly: !editMode ? true : false,
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        name="insuranceAccountNumber"
                        label="Sığortaedənin Uçot Nömrəsi"
                        value={values.insuranceAccountNumber}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        disabled={!editMode}
                        error={
                          errors?.insuranceAccountNumber &&
                          Boolean(errors?.insuranceAccountNumber)
                        }
                        my={2}
                        InputProps={{
                          readOnly: !editMode ? true : false,
                        }}
                      />
                      {errors?.insuranceAccountNumber &&
                        errors?.insuranceAccountNumber && (
                          <FormHelperText error>
                            {errors?.insuranceAccountNumber}
                          </FormHelperText>
                        )}
                    </Grid>
                  </Grid>
                  <Grid sx={{ width: "100%", position: "relative" }}>
                    <FormControl fullWidth>
                      <Autocomplete
                        options={options}
                        getOptionLabel={(option) => option.name || ""}
                        value={
                          options.find(
                            (option) => option.name === values.fieldOfActivity
                          ) || null
                        }
                        onChange={(event, newValue) =>
                          handleChange({
                            target: {
                              name: "fieldOfActivity",
                              value: newValue?.name || "",
                            },
                          })
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.name
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Fəaliyyət Sahəsi" />
                        )}
                        sx={{ mb: 3 }}
                        disabled={!editMode}
                      />
                    </FormControl>
                    {values.fieldOfActivity && editMode && (
                      <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          right: 30,
                          top: 10,
                        }}
                        onClick={() => {
                          setFieldValue("fieldOfActivity", "");
                          action.current?.focusVisible();
                        }}
                      >
                        <CloseRounded />
                      </IconButton>
                    )}
                  </Grid>
                  <FormControl
                    error={errors?.oilIndustry && Boolean(errors?.oilIndustry)}
                    sx={{ width: "100%", marginBottom: "10px" }}
                  >
                    <InputLabel id="oilIndustry">Sektor</InputLabel>
                    <Select
                      name="oilIndustry"
                      labelId="oilIndustry"
                      label="Sektor"
                      fullWidth
                      value={values.oilIndustry}
                      variant="outlined"
                      onChange={handleChange}
                      disabled={!editMode}
                      error={
                        errors?.oilIndustry && Boolean(errors?.oilIndustry)
                      }
                    >
                      <MenuItem value={true}>Neft</MenuItem>
                      <MenuItem value={false}>Qeyri neft</MenuItem>
                    </Select>
                    {errors?.oilIndustry && errors?.oilIndustry && (
                      <FormHelperText error>
                        {errors?.oilIndustry}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Grid
                    sx={{
                      display: "flex",
                      gap: 3,
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <div className="w-100">
                      <InputLabel className="mb-2 text-center">
                        Hüquqi ünvan
                      </InputLabel>
                      <div className="d-flex">
                        <TextField
                          name="legalAddress"
                          placeholder="Məsələn: 28 may küçəsi bina 27"
                          fullWidth
                          value={values.legalAddress}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          disabled={!editMode}
                          sx={{ mb: 3 }}
                          InputProps={{
                            readOnly: !editMode ? true : false,
                          }}
                        />
                        <FormControl fullWidth className="ms-2">
                          <InputLabel id="demo-simple-select-label">
                            Region seçin
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="legalDistrict"
                            value={values?.legalDistrict}
                            disabled={!editMode}
                            inputProps={{
                              readOnly: !editMode ? true : false,
                            }}
                            label="Seçin"
                            onChange={handleChange}
                          >
                            {districts?.length &&
                              districts.map((item) => (
                                <MenuItem
                                  style={{ width: "80vw" }}
                                  value={item?.value}
                                  key={item?.value}
                                >
                                  {item?.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {values.legalDistrict && editMode && (
                          <IconButton
                            size="small"
                            sx={{
                              position: "absolute",
                              right: 30,
                              top: 35,
                            }}
                            onClick={() => {
                              setFieldValue("legalDistrict", -1);
                              action.current?.focusVisible();
                            }}
                          >
                            <CloseRounded />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    {!values?.isSameAddress && (
                      <div className="w-100 ">
                        <InputLabel className="mb-2 text-center">
                          Faktiki ünvan
                        </InputLabel>
                        <div className="d-flex">
                          <TextField
                            name="actualAddress"
                            placeholder="Məsələn: Ziya bunyadov 19"
                            fullWidth
                            value={values.actualAddress}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled={!editMode}
                            sx={{ mb: 3 }}
                            InputProps={{
                              readOnly: !editMode ? true : false,
                            }}
                          />
                          <FormControl fullWidth className="ms-2">
                            <InputLabel id="demo-simple-select-label">
                              Region seçin
                            </InputLabel>
                            <Select
                              action={action}
                              name="actualDistrict"
                              label="Region seçin"
                              value={values.actualDistrict}
                              fullWidth
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              disabled={!editMode}
                              sx={{ mb: 3 }}
                              inputProps={{
                                readOnly: !editMode ? true : false,
                              }}
                            >
                              {districts?.length &&
                                districts.map((item) => (
                                  <MenuItem
                                    style={{ width: "80vw" }}
                                    value={item?.value}
                                    key={item?.value}
                                  >
                                    {item?.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                          {values.actualDistrict && editMode && (
                            <IconButton
                              size="small"
                              sx={{
                                position: "absolute",
                                right: 30,
                                top: 35,
                              }}
                              onClick={() => {
                                setFieldValue("actualDistrict", -1);
                                action.current?.focusVisible();
                              }}
                            >
                              <CloseRounded />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Hüquqi və faktiki ünvan eynidir</InputLabel>
                    <Checkbox
                      checked={values?.isSameAddress}
                      name="isSameAddress"
                      disabled={!editMode}
                      onChange={(e) =>
                        handleIsSameAddress(e.target.checked, setFieldValue)
                      }
                    />
                  </Grid>
                  <FormControl
                    error={errors?.workDayMode && Boolean(errors?.workDayMode)}
                    fullWidth
                  >
                    <InputLabel>İş günü növü</InputLabel>
                    <Field
                      as={Select}
                      label="İş günü növü"
                      id="workdaymode-select"
                      onChange={(e) => {
                        setFieldValue(`workDayMode`, e.target.value);
                      }}
                      value={values.workDayMode}
                      error={
                        errors?.workDayMode && Boolean(errors?.workDayMode)
                      }
                      sx={{ mb: 3 }}
                      fullWidth
                      disabled={!editMode}
                    >
                      {workDayModes.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Field>
                    {errors?.workDayMode && errors?.workDayMode && (
                      <FormHelperText error>
                        {errors?.workDayMode}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    error={
                      errors?.workHourMode && Boolean(errors?.workHourMode)
                    }
                    fullWidth
                  >
                    <InputLabel>İş saatı növü</InputLabel>
                    <Field
                      as={Select}
                      label="İş saatı növü"
                      id="workhourmode-select"
                      onChange={(e) => {
                        setFieldValue(`workHourMode`, e.target.value);
                      }}
                      value={values.workHourMode}
                      error={
                        errors?.workHourMode && Boolean(errors?.workHourMode)
                      }
                      fullWidth
                      disabled={!editMode}
                    >
                      {workHourModes.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Field>
                    {errors?.workHourMode && errors?.workHourMode && (
                      <FormHelperText error>
                        {errors?.workHourMode}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FieldArray name="bankDetails">
                    {({ push, remove }) => (
                      <div>
                        {values.bankDetails.map((form, index) => (
                          <div key={index}>
                            <>
                              <Typography mt={3} mb={3} variant="h6">
                                {index + 1})Bank məlumatları
                              </Typography>

                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <FormControl
                                    error={
                                      errors?.bankDetails?.[index]?.bankName &&
                                      Boolean(
                                        errors?.bankDetails?.[index]?.bankName
                                      )
                                    }
                                    fullWidth
                                  >
                                    <InputLabel
                                      id={`bankDetails[${index}].bankName`}
                                    >
                                      Banklar
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`bankDetails[${index}].bankName`}
                                      labelId={`bankDetails[${index}].bankName`}
                                      label="Banklar"
                                      id="bankName-select"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `bankDetails[${index}].bankName`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `bankDetails[${index}].bankCode`,
                                          handleBankSelect(e.target.value).code
                                        );
                                        setFieldValue(
                                          `bankDetails[${index}].bankVoen`,
                                          handleBankSelect(e.target.value).voen
                                        );
                                      }}
                                      value={values.bankDetails[index].bankName}
                                      error={
                                        errors?.bankDetails?.[index]
                                          ?.bankName &&
                                        Boolean(
                                          errors?.bankDetails?.[index]?.bankName
                                        )
                                      }
                                      fullWidth
                                      disabled={!editMode}
                                    >
                                      {banksList.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                    {errors?.bankDetails?.[index]?.bankName &&
                                      errors?.bankDetails?.[index]
                                        ?.bankName && (
                                        <FormHelperText error>
                                          {
                                            errors?.bankDetails?.[index]
                                              ?.bankName
                                          }
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="number"
                                    name={`bankDetails[${index}].bankCode`}
                                    as={TextField}
                                    label="Bank kod "
                                    value={values.bankDetails[index].bankCode}
                                    defaultValue={
                                      values.bankDetails[index].bankCode
                                    }
                                    fullWidth
                                    disabled={true}
                                  />
                                </Grid>
                              </Grid>

                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="text"
                                    name={`bankDetails[${index}].bankVoen`}
                                    as={TextField}
                                    label="Bank VÖEN "
                                    value={values.bankDetails[index].bankVoen}
                                    defaultValue={
                                      values.bankDetails[index].bankVoen
                                    }
                                    fullWidth
                                    disabled={true}
                                  />
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="text"
                                    name={`bankDetails[${index}].accountNumber`}
                                    as={TextField}
                                    error={
                                      errors?.bankDetails?.[index]
                                        ?.accountNumber &&
                                      Boolean(
                                        errors?.bankDetails?.[index]
                                          ?.accountNumber
                                      )
                                    }
                                    helperText={
                                      errors?.bankDetails?.[index]
                                        ?.accountNumber &&
                                      errors?.bankDetails?.[index]
                                        ?.accountNumber
                                    }
                                    label="Müxbir Hesab nömrəsi "
                                    fullWidth
                                    disabled={!editMode}
                                  />
                                </Grid>
                              </Grid>

                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="text"
                                    name={`bankDetails[${index}].iban`}
                                    as={TextField}
                                    label="IBAN "
                                    error={
                                      errors?.bankDetails?.[index]?.iban &&
                                      Boolean(
                                        errors?.bankDetails?.[index]?.iban
                                      )
                                    }
                                    helperText={
                                      errors?.bankDetails?.[index]?.iban &&
                                      errors?.bankDetails?.[index]?.iban
                                    }
                                    fullWidth
                                    disabled={!editMode}
                                  />
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="text"
                                    name={`bankDetails[${index}].swift`}
                                    as={TextField}
                                    label="SWIFT "
                                    error={
                                      errors?.bankDetails?.[index]?.swift &&
                                      Boolean(
                                        errors?.bankDetails?.[index]?.swift
                                      )
                                    }
                                    helperText={
                                      errors?.bankDetails?.[index]?.swift &&
                                      errors?.bankDetails?.[index]?.swift
                                    }
                                    fullWidth
                                    disabled={!editMode}
                                  />
                                </Grid>
                              </Grid>
                            </>

                            {!editMode || index === 0 ? null : (
                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Bank məlumatını sil
                              </Button>
                            )}
                          </div>
                        ))}
                        {editMode && (
                          <Button
                            sx={{ mt: 3 }}
                            variant="contained"
                            type="button"
                            onClick={() => push({ bankCode: "", bankVoen: "" })}
                          >
                            Bank məlumatları əlavə et
                          </Button>
                        )}
                      </div>
                    )}
                  </FieldArray>

                  {editMode && (
                    <Grid
                      container
                      sx={{
                        mb: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="outlined"
                        color="error"
                        disabled={isSubmitting}
                        mt={3}
                        onClick={() => {
                          setEditMode(false);
                          setDisableButton(false);
                          setErrors({});
                        }}
                      >
                        Ləğv et
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !isValid || !dirty}
                        mt={3}
                      >
                        Yadda saxla
                      </Button>
                    </Grid>
                  )}
                </Form>
              </Card>
            </>
          )}
        </Formik>

        {!editMode && (
          <Grid container justifyContent="flex-end" mb={3}>
            <Button
              onClick={() => {
                setEditMode(true);
                setDisableButton(true);
              }}
              variant="contained"
              color="primary"
              mt={3}
            >
              Dəyişdir
            </Button>
          </Grid>
        )}
      </Paper>
    </div>
  );
}

export default StructureDetailsTable;
