import styled from "@emotion/styled";
import { Add as AddIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllEmployees } from "../../api/api";
import DeleteEmployeeDialog from "../../components/editEmployees/DeleteEmployeeDialog";
import EmployeePicture from "../../components/table/EmployeePicture";
import Table from "../../components/table/Table";
import { OPERATORS_CONTAINING } from "../../constants";
import Column from "../../models/table/Column";
import Operator from "../../models/table/Operator";
import { setNationality } from "../../redux/features/slices/nationality.slice";
import {
  getAllStructuresForFilter,
  getStaticFilterValues,
} from "../../utils/forFilterDropdown/getStructureUtils";
import { getDescendantProp, normalizeDate } from "../../utils/helperFunctions";

import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import StatusGenerator from "../../components/table/StatusGenerator";
import { downloadEmps } from "../../services/employees/EmployeeServices";
import { downloadFile } from "../../utils/downloadCSV";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

function EnhancedTable() {
  const navigate = useNavigate();
  const [employeesData, setEmployeesData] = useState([]);
  const [filters, setFilters] = useState([]);
  // const [employeeFilters, setEmployeeFilters] = useState([
  //   { column: "", operator: "", value: "" },
  // ]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [offset, setOffset] = useState();
  const [limit, setLimit] = useState(6);
  const [totalRow, setTotalRow] = useState();

  // const [exportStatus, setExportStatus] = useState("initial");

  // Filters
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleFilterClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleFilterClose = () => {
  //   setAnchorEl(null);
  // };

  // Table buttons
  const handleIconClick = (row, action) => {
    const rowId = row._id ? row._id : row.id;
    switch (action) {
      case "visibility":
        window.open(`/employees/${rowId}/view`, "_blank");
        break;
      case "edit":
        window.open(`/employees/${rowId}`, "_blank");
        break;
      case "delete":
        setDeleteDialog(true);
        setDeleteRowId(rowId);
        break;
      default:
        break;
    }
  };

  const getEmployees = async (filter = undefined) => {
    try {
      setLoading(true);
      const response = await getAllEmployees(offset, limit, filter);
      if (response && response.data) {
        // const employeesWithId = response.data.employees.map((employee) => ({
        //   ...employee,
        //   id: employee._id, // Rename the _id property to id
        //   position: employee.position?.name,
        //   email: employee.email[0] ? employee.email[0] : "",
        //   dob: new Date(employee.dob),
        //   idCardValidityPeriod: new Date(employee.idCardValidityPeriod),
        //   idCardIssuanceDate: new Date(employee.idCardIssuanceDate),
        // }));
        // dispatch(setEmployeesData(employeesWithId));
        setEmployeesData(response.data.employees);
        setTotalRow(response.data.total);
      }
      // response?.data && setEmployeesData(response.data.employees);
      // response?.data && setTotalRow(response.data.total);
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHandler(limit, offset);
    // setExportStatus("initial");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, offset, limit, filters]);

  const fetchHandler = async () => {
    if (!filters?.length > 0) {
      await getEmployees();
      return;
    } else {
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));
      await getEmployees(encodedFilter);
    }
  };

  // Handle Employee Filter
  // const handleEmployeeFilter = async (limit, filter) => {
  //   await getEmployees(limit, filter);
  //   setExportStatus("initial");
  // };

  // Handle Export Button
  // let forExcel = true;

  // useEffect(() => {
  //   if (exportStatus === "preparing") {
  //     const timeout = setTimeout(() => {
  //       setExportStatus("finished");
  //     }, 2000); // 2 seconds

  //     return () => clearTimeout(timeout); // Clear the timeout if component unmounts or status changes
  //   }
  // }, [exportStatus]);
  // Filter methods
  const capitalizeFirstWord = (str) => {
    const words = str?.split(" ");
    if (words?.length > 0) {
      words[0] =
        words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    }
    return words?.join(" ");
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const exportHandler = async (columnsForExport) => {
    try {
      const cols = [];

      columnsForExport?.forEach((item) => {
        if (item.isStaticColumn || !item.isShow || item.path === "#") return;
        cols.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodedCols = encodeURIComponent(JSON.stringify(cols));
      const encodedFils = encodeURIComponent(JSON.stringify(filters));

      const payload = {
        limit: limit,
        offset: offset,
        filter: filters.length > 0 && encodedFils,
        columns: encodedCols,
      };

      const resp = await downloadEmps(payload);

      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  // Table columns

  const operatorGeneratorHandler = (row, column) => {
    const operators = [
      new Operator({
        icon: <VisibilityIcon />,
        onClick: () => handleIconClick(row, "visibility"),
        type: 0,
      }),
      new Operator({
        icon: <EditIcon />,
        onClick: () => handleIconClick(row, "edit"),
        type: 0,
      }),
      new Operator({
        icon: <DeleteIcon />,
        onClick: () => handleIconClick(row, "delete"),
        hideCondition: row.isUsedInStaff,
        type: 0,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };

  let nationalities = [
    { key: "Albaniya", name: "Albaniya" },
    { key: "Almaniya", name: "Almaniya" },
    { key: "Anqola", name: "Anqola" },
    { key: "Antiqua və Barbuda", name: "Antiqua və Barbuda" },
    { key: "Argentina", name: "Argentina" },
    { key: "Avstraliya", name: "Avstraliya" },
    { key: "Avstriya", name: "Avstriya" },
    { key: "Azərbaycan", name: "Azərbaycan" },
    { key: "Bahamalar", name: "Bahamalar" },
    { key: "Bahrayn", name: "Bahrayn" },
    { key: "Banqladeş", name: "Banqladeş" },
    { key: "Barbados", name: "Barbados" },
    { key: "Belarus", name: "Belarus" },
    { key: "Belçika", name: "Belçika" },
    { key: "Boliviya", name: "Boliviya" },
    { key: "Bosniya və Herseqovina", name: "Bosniya və Herseqovina" },
    { key: "Botswana", name: "Botswana" },
    { key: "Braziliya", name: "Braziliya" },
    { key: "Böyük Britaniya", name: "Böyük Britaniya" },
    { key: "Büyükdəniz adası", name: "Büyükdəniz adası" },
    { key: "Bəhreyn", name: "Bəhreyn" },
    { key: "Bəruney", name: "Bəruney" },
    { key: "Cənubi Afrika", name: "Cənubi Afrika" },
    { key: "Cənubi Sudan", name: "Cənubi Sudan" },
    { key: "Cənubi Koreya", name: "Cənubi Koreya" },
    { key: "Çad", name: "Çad" },
    { key: "Çexiya", name: "Çexiya" },
    { key: "Çili", name: "Çili" },
    { key: "Çin", name: "Çin" },
    { key: "Çernoqoriya", name: "Çernoqoriya" },
    { key: "Çexoslavakiya", name: "Çexoslavakiya" },
    { key: "Danemarka", name: "Danemarka" },
    { key: "Dominika", name: "Dominika" },
    { key: "Dominikan Respublikası", name: "Dominikan Respublikası" },
    { key: "Ekvador", name: "Ekvador" },
    { key: "Ekvatorial Qvineya", name: "Ekvatorial Qvineya" },
    { key: "Eritreya", name: "Eritreya" },
    { key: "Estoniya", name: "Estoniya" },
    { key: "Əfqanıstan", name: "Əfqanıstan" },
    { key: "Fici", name: "Fici" },
    { key: "Filippin", name: "Filippin" },
    { key: "Finlandiya", name: "Finlandiya" },
    { key: "Fransa", name: "Fransa" },
    { key: "Fransız Qvineyası", name: "Fransız Qvineyası" },
    { key: "Fələstin", name: "Fələstin" },
    { key: "Gana", name: "Gana" },
    { key: "Qabon", name: "Qabon" },
    { key: "Qambiya", name: "Qambiya" },
    { key: "Qazaxıstan", name: "Qazaxıstan" },
    { key: "Qatar", name: "Qatar" },
    { key: "Qrenada", name: "Qrenada" },
    { key: "Qvineya", name: "Qvineya" },
    { key: "Qvineya-Bisau", name: "Qvineya-Bisau" },
    { key: "Gürcüstan", name: "Gürcüstan" },
    { key: "Haiti", name: "Haiti" },
    { key: "Hollandiya", name: "Hollandiya" },
    { key: "Honduras", name: "Honduras" },
    { key: "Honkonq", name: "Honkonq" },
    { key: "Hindistan", name: "Hindistan" },
    { key: "Horvatiya", name: "Horvatiya" },
    { key: "Indoneziya", name: "Indoneziya" },
    { key: "İngiltərə", name: "İngiltərə" },
    { key: "İordaniya", name: "İordaniya" },
    { key: "İraq", name: "İraq" },
    { key: "İran", name: "İran" },
    { key: "İrlandiya", name: "İrlandiya" },
    { key: "İslandiya", name: "İslandiya" },
    { key: "İspaniya", name: "İspaniya" },
    { key: "İsrail", name: "İsrail" },
    { key: "İsveç", name: "İsveç" },
    { key: "İsveçrə", name: "İsveçrə" },
    { key: "İtaliya", name: "İtaliya" },
    { key: "Kamboca", name: "Kamboca" },
    { key: "Kamerun", name: "Kamerun" },
    { key: "Kanada", name: "Kanada" },
    { key: "Keniya", name: "Keniya" },
    { key: "Kipr", name: "Kipr" },
    { key: "Kiribati", name: "Kiribati" },
    { key: "Kolumbiya", name: "Kolumbiya" },
    { key: "Komor Adaları", name: "Komor Adaları" },
    { key: "Kongo", name: "Kongo" },
    { key: "Kosta Rika", name: "Kosta Rika" },
    { key: "Kotd’İvor", name: "Kotd’İvor" },
    { key: "Kuba", name: "Kuba" },
    { key: "Kuveyt", name: "Kuveyt" },
    { key: "Kırqızıstan", name: "Kırqızıstan" },
    { key: "Latviya", name: "Latviya" },
    { key: "Lesoto", name: "Lesoto" },
    { key: "Livan", name: "Livan" },
    { key: "Liberiya", name: "Liberiya" },
    { key: "Litva", name: "Litva" },
    { key: "Lixtenşteyn", name: "Lixtenşteyn" },
    { key: "Liviya", name: "Liviya" },
    { key: "Lüksemburq", name: "Lüksemburq" },
    { key: "Makedoniya", name: "Makedoniya" },
    { key: "Malavi", name: "Malavi" },
    { key: "Malayziya", name: "Malayziya" },
    { key: "Maldiv adaları", name: "Maldiv adaları" },
    { key: "Malta", name: "Malta" },
    { key: "Maroq", name: "Maroq" },
    { key: "Marşal adaları", name: "Marşal adaları" },
    { key: "Mauritaniya", name: "Mauritaniya" },
    { key: "Mauritius", name: "Mauritius" },
    { key: "Meksika", name: "Meksika" },
    { key: "Moldova", name: "Moldova" },
    { key: "Monako", name: "Monako" },
    { key: "Monqolustan", name: "Monqolustan" },
    { key: "Monteneqro", name: "Monteneqro" },
    { key: "Morokko", name: "Morokko" },
    { key: "Mozambik", name: "Mozambik" },
    { key: "Myanma", name: "Myanma" },
    { key: "Mərakeş", name: "Mərakeş" },
    { key: "Nambiya", name: "Nambiya" },
    { key: "Nauru", name: "Nauru" },
    { key: "Nepal", name: "Nepal" },
    { key: "Niderland", name: "Niderland" },
    { key: "Niger", name: "Niger" },
    { key: "Nigeriya", name: "Nigeriya" },
    { key: "Nikaraqua", name: "Nikaraqua" },
    { key: "Norveç", name: "Norveç" },
    { key: "Pakistan", name: "Pakistan" },
    { key: "Palau", name: "Palau" },
    { key: "Panama", name: "Panama" },
    { key: "Paraqvay", name: "Paraqvay" },
    { key: "Portuqaliya", name: "Portuqaliya" },
    { key: "Polşa", name: "Polşa" },
    { key: "Rumıniya", name: "Rumıniya" },
    { key: "Rusiya", name: "Rusiya" },
    { key: "Ruanda", name: "Ruanda" },
    { key: "San-Marino", name: "San-Marino" },
    { key: "Svazilend", name: "Svazilend" },
    { key: "Salvador", name: "Salvador" },
    { key: "Samoa", name: "Samoa" },
    { key: "San-Tome və Prinsip", name: "San-Tome və Prinsip" },
    { key: "Seneqal", name: "Seneqal" },
    { key: "Seyşel adaları", name: "Seyşel adaları" },
    { key: "Sinhapur", name: "Sinhapur" },
    { key: "Slovakiya", name: "Slovakiya" },
    { key: "Sloveniya", name: "Sloveniya" },
    { key: "Solomon adaları", name: "Solomon adaları" },
    { key: "Somali", name: "Somali" },
    { key: "Sudan", name: "Sudan" },
    { key: "Surinam", name: "Surinam" },
    { key: "Suriya", name: "Suriya" },
    { key: "Svədistan", name: "Svədistan" },
    { key: "Səudiyyə Ərəbistanı", name: "Səudiyyə Ərəbistanı" },
    { key: "Sərbiya", name: "Sərbiya" },
    { key: "Tacikistan", name: "Tacikistan" },
    { key: "Tanzaniya", name: "Tanzaniya" },
    { key: "Tailand", name: "Tailand" },
    { key: "Tayvan", name: "Tayvan" },
    { key: "Toga", name: "Toga" },
    { key: "Tonqa", name: "Tonqa" },
    { key: "Trinidad və Tobaqo", name: "Trinidad və Tobaqo" },
    { key: "Tunis", name: "Tunis" },
    { key: "Turkmənistan", name: "Turkmənistan" },
    { key: "Tuvalu", name: "Tuvalu" },
    { key: "Türkiyə", name: "Türkiyə" },
    { key: "Ukrayna", name: "Ukrayna" },
    { key: "Urugvay", name: "Urugvay" },
    { key: "Vanuatu", name: "Vanuatu" },
    { key: "Vatikan", name: "Vatikan" },
    { key: "Venesuela", name: "Venesuela" },
    { key: "Vyetnam", name: "Vyetnam" },
    { key: "Yamayka", name: "Yamayka" },
    { key: "Yaponiya", name: "Yaponiya" },
    { key: "Yeni Zelandiya", name: "Yeni Zelandiya" },
    { key: "Yunanıstan", name: "Yunanıstan" },
    { key: "Yəmən", name: "Yəmən" },
    { key: "Zambiya", name: "Zambiya" },
    { key: "Zimbabve", name: "Zimbabve" },
  ];

  const columns = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      // isStaticColumn: true,
      disableColSearch: true,
      widthOfColumn: 200,
      renderCell: operatorGeneratorHandler,
    }),
    new Column({
      headerName: "Şəkil",
      disableColSearch: true,
      isWithFilter: false,
      renderCell: (row, column) => {
        return <EmployeePicture datas={row.profileImage} />;
      },
    }),
    new Column({
      path: "tableNumber",
      headerName: "Tabel nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, col) => {
        return capitalizeFirstWord(row?.firstname);
      },
    }),
    new Column({
      path: "lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, col) => {
        return capitalizeFirstWord(row?.lastname);
      },
    }),
    new Column({
      path: "fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, col) => {
        return capitalizeFirstWord(row?.fathername);
      },
    }),
    new Column({
      path: "staffs",
      headerName: "Təşkilat",
      filterType: OPERATORS_CONTAINING.IN,
      filterColumnKey: "baseStructure",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "employee",
      keyForResponseDataFromFilter: "baseStructures",

      getValue: (row, col) => {
        const staffs = row?.staffs;
        let organizations = [];
        if (!staffs) return "-";
        staffs?.forEach((item, index) => {
          organizations.push(item?.structure?.organization?.name);
        });

        return (
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}
          >
            {organizations.map((item, index) => (
              <div key={index}>{capitalizeFirstWord(item)}</div>
            ))}
          </div>
        );
      },
    }),
    new Column({
      path: "structure",
      headerName: "Struktur",
      filterType: OPERATORS_CONTAINING.IN,
      filterColumnKey: "topPartOfStructure",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "employee",
      dependencieKeysOfFilter: {
        structures: ["baseStructure"],
      },
      keyForResponseDataFromFilter: "topPartOfStructures",
      getValue: (row, col) => {
        let structures = [];
        if (!row.staffs) return "-";
        row.staffs?.forEach((item, index) => {
          structures.push(item?.topPartOfStructure?.source?.name);
        });
        return (
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
          >
            {structures.map((item, index) => (
              <div key={index}>{capitalizeFirstWord(item)}</div>
            ))}
          </div>
        );
      },
    }),
    new Column({
      path: "position.name",
      headerName: "Vəzifə",
      filterType: OPERATORS_CONTAINING.IN,
      filterColumnKey: "position",
      fetchFilterValuesV2: getStaticFilterValues,
      dataGridName: "employee",
      dependencieKeysOfFilter: {
        structures: ["baseStructure"],
        topPartOfStructures: ["topPartOfStructures", "subStructures"],
      },
      keyForResponseDataFromFilter: "positions",
      getValue: (row, column) => {
        let positions = [];
        if (!row.staffs) return "-";
        row.staffs?.forEach((item, index) => {
          positions.push(item?.position?.name);
        });
        return (
          <div
            style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
          >
            {positions.map((item, index) => (
              <div key={index}>{capitalizeFirstWord(item)}</div>
            ))}
          </div>
        );
      },
    }),
    new Column({
      path: "dob",
      headerName: "Doğum tarixi",
      filterType: OPERATORS_CONTAINING.DATE,

      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path + ".value")),
    }),
    new Column({
      path: "mobile",
      headerName: "Əlaqə nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "email",
      headerName: "E-mail",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "gender",
      headerName: "Cins",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: [
        {
          key: "male",
          name: "kişi",
        },
        {
          key: "female",
          name: "qadın",
        },
      ],
      getValue: (row, col) => {
        return row.gender === "male" ? "Kişi" : "Qadın";
      },
    }),
    new Column({
      path: "nationality",
      headerName: "Milliyət",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: nationalities,
    }),
    new Column({
      path: "placeOfBirth",
      headerName: "Doğum yeri",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "ssn",
      headerName: "SSN",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "pin",
      headerName: "Fin",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "idCardNumber",
      headerName: "Ş.V. Seriya №",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "idCardValidityPeriod",
      headerName: "Ş.V. bitmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "idCardIssuanceDate",
      headerName: "Ş.V. verilmə tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "status",
      headerName: "Müqavilə qüvvəliliyi",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: [
        {
          key: "active",
          name: "Aktiv",
        },
        {
          key: "inactive",
          name: "Deaktiv",
        },
      ],
      getValue: (row, column) => {
        return row[column.path] === "active" ? "Aktiv" : "Deaktiv";
      },
    }),
    new Column({
      path: "militaryService.hasMilitaryService",
      headerName: "Hərbi mükəlləfiyyət",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: [
        {
          key: false,
          name: "Yoxdur",
        },
        {
          key: true,
          name: "Var",
        },
      ],
      getValue: (row, c) => {
        const value = getDescendantProp(row, c.path);

        return value === false ? "Yoxdur" : "Var";
      },
    }),
    // new Column({
    //   path: "staffs",
    //   headerName: "Təşkilat",
    //   filterType: OPERATORS_CONTAINING.OPTION,
    //   filterColumnKey: "staffs.structure._id",
    //   fetchFilterValues: getAllStructuresForFilter,
    //   getValue: (row, col) => {
    //     const staffs = getDescendantProp(row, col.path);
    //     let organizations = "-";
    //     if (!staffs) return "-";
    //     staffs?.forEach((item, index) => {
    //       organizations =
    //         index === 0
    //           ? item?.structure?.organization?.name
    //           : `${organizations}, ${item?.structure?.organization?.name}`;
    //     });
    //     return organizations;
    //   },
    // }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: [
        {
          key: "active",
          name: "Aktiv",
        },
        {
          key: "inactive",
          name: "Deaktiv",
        },
      ],
      renderCell: (row, column) => {
        return (
          <StatusGenerator
            docType={"employeeStatus"}
            value={row[column.path] ?? "inactive"}
          />
        );
      },
    }),
    new Column({
      path: "driverLicense",
      headerName: "Sürücülük vəsiqəsi",
      filterColumnKey: "driverLicense.hasDriverLicense",
      filterType: OPERATORS_CONTAINING.OPTION,
      getValue: (row, column) => {
        return row[column.path]?.licenseCategory?.length ? "Var" : "Yoxdur";
      },
      filterValues: [
        {
          key: false,
          name: "Yoxdur",
        },
        {
          key: true,
          name: "Var",
        },
      ],
    }),
  ];
  return (
    <>
      <Box
        style={
          {
            // width: "80%",
            // height: "55vh",
          }
        }
        b
      >
        <Table
          columns={columns}
          data={employeesData}
          totalRows={totalRow}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={loading}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
          downloadHandler={(columnsForExport) =>
            exportHandler(columnsForExport)
          }
        />
        {/* {loading ? <Loading /> : null} */}
      </Box>
      {/* Handle Delete */}
      <DeleteEmployeeDialog
        id={deleteRowId}
        setDeleteDialog={setDeleteDialog}
        deleteDialog={deleteDialog}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
    </>
  );
}

function Employees() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nationalityMenu, setNationalityMenu] = useState(null);

  //  Handle add employee menu
  const handleNationality = (e) => {
    setNationalityMenu(e.currentTarget);
  };

  const handleClose = () => {
    setNationalityMenu(null);
  };

  const handleNavigate = (value) => {
    dispatch(setNationality(value));
    window.open("/employees/add-employee", "_blank");
  };

  return (
    <React.Fragment>
      <Helmet title="Employees" />
      <ToastContainer />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Elektron Arxiv - Əməkdaş Məlumatları
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              aria-owns={nationalityMenu ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleNationality}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              Əməkdaş əlavə et
            </Button>

            <Menu
              id="nationality-menu"
              anchorEl={nationalityMenu}
              open={Boolean(nationalityMenu)}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={() => {
                  handleNavigate("azerbaijani");
                }}
              >
                Azərbaycan Vətəndaşı
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleNavigate("foreign");
                }}
              >
                Xarici Vətəndaşı
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Employees;
