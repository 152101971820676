import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { formatDateTime } from "../../../../utils/helperFunctions";
import {
  GROUP_NAME,
  LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS,
} from "../dialogConstants";
import FieldItem from "./parts/FieldItem";
import { isArray } from "lodash";
import { translationMap } from "./../helpers";

export default function DialogLeftCont({
  contractType,
  fieldsData,
  isGroup = false,
  mode = "noneditable",
  inputProps,
}) {
  const renderFields = React.useCallback(
    (shouldShowUpdateData = false) => {
      if (!fieldsData) return;

      return isGroup ? handlerOnGroup(shouldShowUpdateData) : handlerOnSingle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldsData, isGroup]
  );

  const specialFieldTypeObject = (field) => {
    const { value } = field;
    switch (field.key) {
      case "workingCondition":
        return `${value.parent} - ${value.name}`;
      default:
        const type = translationMap[value.type] || value.type;
        return value.value + " " + type;
    }
  };

  const defineValueType = (value) => {
    if (Array.isArray(value)) return "array";
    return typeof value;
  };

  const updateRenderField = (field, index) => {
    if (!field || field.isHide) return;

    const { label = "__", type = defineValueType(field.value) } = field;
    let fieldValue;
    if (type === "array") {
      const isStringArray =
        Array.isArray(field.value) &&
        field.value.every((val) => typeof val === "string");

      if (isStringArray) {
        fieldValue = field.value.join(", ");
      } else {
        fieldValue = field?.value[index]?.staff?.employee
          .map((emp) => `${emp.lastname} ${emp.firstname} ${emp.fathername}`)
          .filter(Boolean)
          .join(" -> ");
      }
    } else if (type === "date") {
      fieldValue = field.value ? formatDateTime(field.value) : "";
    } else if (type === "number" || type === "string") {
      let actualValue = field.value;
      if (typeof actualValue === "object") {
        const type = translationMap[actualValue.type] || actualValue.type;
        actualValue = actualValue.value + " " + type;
      }
      actualValue = translationMap[actualValue] || actualValue;
      return (
        <FieldItem key={index} label={label} value={actualValue} largerText />
      );
    } else if (type === "object") {
      fieldValue = specialFieldTypeObject(field);
    }

    return <FieldItem key={index} label={label} value={fieldValue} />;
  };
  const handlerOnGroup = (shouldShowUpdateData) => {
    const uiParts = [];
    const data = { ...fieldsData };
    let updateKeys;
    if (data.updates) {
      updateKeys = Object.keys(data.updates);
      for (let element of updateKeys) {
        data[element] = data.updates[element];
      }
    }
    for (const key in data) {
      const group = data[key];
      const utils =
        LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS[contractType]?.[key];
      uiParts.push(
        <Accordion sx={{ backgroundColor: "rgba(0,0,0,0.1)" }} key={key}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            sx={{ paddingInline: "16px" }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                color: "rgba(200,200,200,1)",
              }}
            >
              {GROUP_NAME[key]}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ marginX: "10px" }}>
            {isArray(group) &&
              group.map((item, index) => {
                return updateRenderField(
                  {
                    ...item,
                    renderValue: utils && utils[item.key],
                    group,
                    key: item.key,
                  },
                  index
                );
              })}
          </AccordionDetails>
        </Accordion>
      );
    }

    if (shouldShowUpdateData) {
      const renderArray = uiParts.filter((element) =>
        updateKeys?.includes(element.key)
      );
      return [...renderArray];
    } else {
      const renderArray = uiParts
        .filter((element) => !updateKeys?.includes(element.key))
        .filter((element) => !["updates"].includes(element.key));
      return [...renderArray];
    }
  };
  const handlerOnSingle = () => {
    return fieldsData?.map((item, index) => {
      if (Array.isArray(item)) {
        return item.map((itm) => {
          return renderField(itm, `${itm.label}/${index}`);
        });
      }
      return renderField(item, `${item[index].label}/${index}`);
    });
  };

  const renderField = (field, index) => {
    if (field.isHide) return;

    const { label = "__" } = field;
    const value = renderValue(field);

    return (
      <FieldItem
        key={index}
        label={label}
        value={value}
        mode={mode}
        group={{ [field.key]: field.group }}
        inputProps={inputProps}
      />
    );
  };

  const renderValue = (field) => {
    if (!field.value) return "-";
    if (field.renderValue) {
      const render = field.renderValue;
      return render(field.value);
    }
    switch (field.type) {
      case "string":
        return field.value;
      case "date":
        if (!field.value) return "__";
        return formatDateTime(field.value);
      case "array":
        return "array";
      case "object":
        return "object";
      default:
        return field.value;
    }
  };
  return (
    <Grid sx={styles.container}>
      <Grid>{renderFields()}</Grid>
      {fieldsData?.updates && (
        <>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <p style={{ textAlign: "center", width: "100%", fontSize: 18 }}>
              Dəyişikliklər
            </p>
          </div>
          <Grid>{renderFields(true)}</Grid>
        </>
      )}
    </Grid>
  );
}

const styles = {
  container: {
    width: "100%",
    height: "100%",
    paddingX: "10px",
    overflow: "auto",
  },
};
