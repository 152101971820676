import { Dialog, Grid, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import RequestDialog from "../../../layouts/signing/dialog/RequestDialog";
import LoadingTable from "../../table/parts/LoadingTable";
import DialogFooter from "./dialogParts/DialogFooter";
import DialogHeader from "./dialogParts/DialogHeader";
import DialogLeftCont from "./dialogParts/DialogLeftCont";
import DialogRightCont from "./dialogParts/DialogRightCont";
import CommentDialog from "./dialogParts/parts/CommentDialog";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       // aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {/* {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography
//             sx={{ writingMode: "vertical-rl", textOrientation: "mixed" }}
//           >
//             {children}
//           </Typography>
//         </Box>
//       )} */}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  fixedIm: {
    overflow: "visible !important",
  },
});

export default function DocumentDialog({
  refreshPage,
  open,
  onClose,
  components = {
    header: undefined,
    body: undefined,
    leftComponent: undefined,
    rightComponent: undefined,
    footer: undefined,
    navbar: undefined,
  },
  hideParts = {
    header: false,
    body: false,
    leftContainer: false,
    rightContainer: false,
    footer: false,
    navbar: false,
  },
  options = {
    bgColor: "",
    title: "",
  },
  fieldsData,
  commentsData,
  signingProps = {
    hide: false, // hide component
    acceptBtnTitle: "", // accept button title
    rejectBtnTitle: "", // reject button title
    handlerAccept: undefined, // accept button onClick
    handlerReject: undefined, // reject button onClick
    withoutVerifie: false,
    withoutCommentDialog: false,
  },
  downloadBtnProps = {
    generateHref: undefined, // exmaple: (id) => return `{baseUrl}/.../${id}/download`
    value: undefined,
    hide: false,
    // onClick: undefined,
    disable: undefined,
  },
  commentsProps = {
    hide: false,
    submitHandler: undefined,
  },
  navbarData,
  navbarProps = {
    currTabValue: undefined,
    isLikeButton: false,
    index: undefined,
  },
  id,
  contractNo,
  loading,
  isGroup,
  typeDoc,
  dialogType,
  versionData,
  versionsProps = {
    versionHandler: (value) => {},
    currVersionState: undefined,
  },
}) {
  /* fieldsData -------> render field object props | array
  [
    {
        label:
        value:
        renderValue?: (value) => return after some operations value 
        isHide?:
        type: string | date | 
    }, ...
  ]
  */
  /* commentsData ---->
  commentsData 
  [
    {
        label: 
        value:
    }
  ]

    navbarData ----->
   
 */

  // header - header, close
  // body - right and left
  //      left: textfields
  //      right: doc, comment signing
  // footer ?

  // navbarData = [
  //   {
  //     label: "Xidməti yazı",
  //     value: "id0",
  //   },
  //   {
  //     label: "Əmr",
  //     value: "id1",
  //   },
  //   {
  //     label: "Məzuniyyət ərizəsi - sorğu",
  //     value: "id2",
  //   },
  // ];
  const [showCommDialog, setShowCommDialog] = useState({
    type: undefined,
    isShow: false,
  });
  const [showSignRequestDialog, setShowSignRequestDialog] = useState(false);
  const [value, setValue] = useState(
    navbarProps.index !== undefined ? navbarProps.index : 0
  );
  useEffect(() => {
    navbarProps.isLikeButton ? setValue(0) : setValue(navbarProps.index);
  }, [navbarProps.index, navbarProps.isLikeButton]);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    !navbarProps.isLikeButton && setValue(newValue);
    if (!navbarProps?.currTabValue) return;
    navbarProps?.currTabValue(navbarData[newValue].value);
  };

  const handleOnSubmit = () => {
    setShowCommDialog({ type: "approved", isShow: true });

    if (signingProps.handlerAccept) {
      signingProps.handlerAccept();
      return;
    } else if (signingProps.withoutVerifie) {
      setShowCommDialog({ type: "approved", isShow: true });
      return;
    }
  };

  const handleOnCancel = () => {
    if (signingProps.handlerReject) {
      signingProps.handlerReject();
      return;
    }
    setShowCommDialog({ type: "reject", isShow: true });
  };

  const [simaBase64, setSimaBase64] = useState();
  // #region
  //   UI component parts:
  const header = !components.header ? (
    <Grid sx={styles.headerCont}>
      <DialogHeader
        title={options.title}
        onClose={onClose}
        contractNo={contractNo}
        versionHandler={versionsProps.versionHandler}
        versionData={versionData}
        currVersionState={versionsProps.currVersionState}
      />
    </Grid>
  ) : (
    components.header
  );
  //left component
  const leftCont = !components.leftComponent ? (
    <Grid sx={styles.leftCont}>
      {!hideParts.leftContainer && (
        <DialogLeftCont
          contractType={typeDoc}
          fieldsData={fieldsData}
          isGroup={isGroup}
        />
      )}
    </Grid>
  ) : (
    components.leftComponent
  );
  //right component
  const rightCont = !components.rightComponent ? (
    <Grid sx={styles.rightCont}>
      {!hideParts.rightContainer && (
        <DialogRightCont
          comments={commentsData}
          commentsProps={commentsProps}
          downloadBtnProps={downloadBtnProps}
        />
      )}
    </Grid>
  ) : (
    components.rightComponent
  );
  //body component
  const body = !components.body ? (
    <Grid sx={styles.bodyCont}>
      {leftCont}
      {rightCont}
    </Grid>
  ) : (
    components.body
  );
  //footer component
  const footer = !components.footer ? (
    <Grid sx={styles.footerCont}>
      <DialogFooter
        titleReject={signingProps.rejectBtnTitle}
        titleSubmit={signingProps.acceptBtnTitle}
        hide={signingProps.hide}
        onReject={handleOnCancel}
        onSubmit={handleOnSubmit}
      />
    </Grid>
  ) : (
    components.footer
  );
  const navigation = !components.navbar ? (
    <Grid sx={[styles.navCont]}>
      {
        <Tabs
          orientation="vertical"
          sx={{
            height: "auto",
            width: "30px",
            overflow: "visible",
            borderRadius: "0px 10px 10px 0px",
          }}
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          textColor="#fff"
          indicatorColor={"#fff"}
          classes={{ fixed: classes.fixedIm }}
          variant="standard"
        >
          {navbarData?.length > 1 &&
            navbarData?.map((item, index) => (
              <Tab
                key={"tab" + index}
                label={item.label}
                {...a11yProps(index)}
                sx={[
                  styles.tab,
                  {
                    backgroundColor:
                      value === index
                        ? !loading
                          ? options.bgColor
                          : "grey"
                        : "rgba(255,255,255,0.9)",
                    color: value !== index ? options.bgColor : "white",
                    transition:
                      "background-color 250ms linear, color 250ms linear, border-radius 350ms ease, min-width 250ms ease",
                    borderRadius:
                      value === index
                        ? "0px 10px 10px 0px"
                        : index === 0
                        ? "0px 10px 0px 0px"
                        : index === navbarData.length - 1 && "0px 0px 10px 0px",
                    minWidth: value === index ? "50px" : "30px",
                    cursor:
                      ((index === 0 && navbarProps.isLikeButton) ||
                        value === index) &&
                      "default !important",
                  },
                ]}
              />
            ))}
        </Tabs>
      }
    </Grid>
  ) : (
    components.navbar
  );
  //   UI component parts:
  // #endregion

  return (
    <>
      <Dialog
        open={open}
        sx={{
          overflow: "auto",
        }}
        onClose={onClose}
        PaperProps={{
          sx: [
            styles.paper,
            {
              backgroundColor: !loading ? options.bgColor : "grey",
              opacity: loading && 0.9,
              display:
                (showCommDialog.isShow || showSignRequestDialog) && "none",
            },
          ],
        }}
      >
        {!hideParts.navbar && navigation}
        <Grid sx={[styles.container]}>
          {loading ? (
            <LoadingTable />
          ) : (
            <>
              {!hideParts.header && header}
              {!hideParts.body && body}
              {!hideParts.footer && footer}
            </>
          )}
        </Grid>
      </Dialog>
      {!signingProps.withoutCommentDialog ? (
        <Dialog
          open={showCommDialog.isShow}
          onClose={() => {
            setShowCommDialog({ isShow: false, type: undefined });
            setShowSignRequestDialog(false);
          }}
          PaperProps={{ style: styles.commDialogPaperStyle }}
          maxWidth={"1000px"}
        >
          <CommentDialog
            setShowSignRequestDialog={() => setShowSignRequestDialog(true)}
            type={showCommDialog.type}
            id={id}
            onClose={() => {
              setShowCommDialog({ isShow: false, type: undefined });
            }}
            closeSigningDialog={onClose}
            onSubmitHandler={commentsProps.submitHandler}
            simaDataHandler={(data) => {
              if (!data) return;
              setSimaBase64(data.data);
            }}
          />
        </Dialog>
      ) : null}
      {simaBase64 && (
        <Dialog
          maxWidth={"800px"}
          open={showSignRequestDialog}
          onClose={() => setShowSignRequestDialog(false)}
          PaperProps={{
            style: styles.requestPaper,
          }}
        >
          <RequestDialog
            refreshPage={refreshPage}
            simaData={simaBase64}
            onClose={() => {
              onClose();
              setShowCommDialog({ isShow: false, type: undefined });
              setShowSignRequestDialog(false);
            }}
            // openCommentDialog={(type) => {
            //   setShowCommDialog({ type: type, isShow: true });
            // }}
          />
        </Dialog>
      )}
    </>
  );
}

const styles = {
  paper: {
    width: "100%",
    maxWidth: "1000px",
    height: "800px",
    // position: "absolute",
    overflow: "visible",
    borderRadius: "10px",
    transition: "background-color 250ms linear",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: "5px",
    width: "100%",
    maxWidth: "1000px",
    overflow: "auto",
    zIndex: 999,
  },
  headerCont: { height: "10%" },
  bodyCont: {
    height: "80%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  rightCont: { width: "50%" },
  leftCont: { width: "50%" },
  footerCont: { height: "10%", width: "100%" },
  commDialogPaperStyle: {
    width: 1000,
    height: "auto",
    opacity: 0.95,
    borderRadius: "10px",
  },
  requestPaper: {
    borderRadius: "27px",
    width: "1000px",
    height: "600px",
  },
  tab: {
    writingMode: "vertical-rl",
    textOrientation: "mixed",
    textAlign: "center",
    // paddingRight: "50px",
  },
  navCont: {
    width: "30px",
    height: "auto",
    position: "absolute",
    top: "50px",
    right: "-30px",
    borderRadius: "0px 10px 10px 0px",
    overflow: "visible",
  },
};
