import {
  downloadLabourContractChangesCSV_API,
  getEmployeesForLabourContractByID_API,
  getLabourContractChangeStatistics_API,
  getLabourContractDoc_API,
  getLabourContractEMAS_API,
  getLabourContractUpdate_API,
  getLabourContractUpdates_API,
  getStructureEmployeesForLabourContractByID_API,
  getStructureForLabourContractChanges_API,
  updateLabourContractAfterEMAS_API,
} from "../api/documentRequests";
import { handleApiCall } from "./apiHandler";

// ⇩ ⇩ ⇩  Labour Contract Serices; ⇩ ⇩ ⇩
export const getLabourContractDoc_SERVICE = async (id) => {
  return handleApiCall(getLabourContractDoc_API, id);
  // try {
  //   const response = await getLabourContractDoc_API(id);
  //   return response;
  // } catch (error) {
  //   console.error("Service Error:", error);
  //   throw error;
  // }
};

export const getLabourContractEMAS_SERVICE = async (id) => {
  try {
    const response = await getLabourContractEMAS_API(id);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const downloadLabourContractChangesCSV_SERVICE = async ({
  filter,
  columns,
  isWithFilter,
}) => {
  try {
    const response = await downloadLabourContractChangesCSV_API(
      filter,
      columns,
      isWithFilter
    );
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const getContractChangeStatistics_SERVICE = async (
  startDate,
  endDate
) => {
  try {
    const response = await getLabourContractChangeStatistics_API(
      startDate,
      endDate
    );
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const getLabourContractUpdates_SERVICE = async ({
  limit,
  offset,
  filter,
}) => {
  try {
    const response = await getLabourContractUpdates_API(limit, offset, filter);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const getLabourContractUpdate_SERVICE = async ({
  id,
  isGroup = false,
}) => {
  try {
    const response = await getLabourContractUpdate_API(id, isGroup);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const getEmployeesForLabourContractByID_SERVICE = async () => {
  try {
    const response = await getEmployeesForLabourContractByID_API();
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const getStructureEmployeesForLabourContractByID_SERVICE = async (
  structureId,
  partId = undefined
) => {
  try {
    const response = await getStructureEmployeesForLabourContractByID_API(
      structureId,
      partId
    );
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const getStructureForLabourContractChanges_SERVICE = async (
  structureId,
  partId = undefined
) => {
  try {
    const response = await getStructureForLabourContractChanges_API(
      structureId,
      partId
    );
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const updateLabourContractAfterEMAS_SERVICE = async (payload) => {
  return handleApiCall(updateLabourContractAfterEMAS_API, payload);
  // try {
  //   const response = await updateLabourContractAfterEMAS_API(payload);
  //   return response;
  // } catch (error) {
  //   console.error("Service Error:", error);
  //   throw error;
  // }
};
// ⇧ ⇧ ⇧ Labour Contract Serices; ⇧ ⇧ ⇧
