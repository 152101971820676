import React, { useEffect, useState } from "react";

import useGraphicForm from "../../../../hooks/useGraphicForm";
import GraphicFormLeft from "./components/GraphicFormLeft";
import GraphicFormRight from "./components/GraphicFormRight";

import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { postGraphicSetting } from "../../../../api/graphicSettings";
import styles from "../GraphicForm/GraphicForm.module.css";
import { removeEmptyStrings } from "../../../../utils/removeEmptyString";

const GraphicForm = ({ setData, getData, handleClose }) => {
  const {
    label,
    formData,
    isHoliday,
    numGroups,
    inputSets,
    showInputs,
    scheduleType,
    handleChange,
    handleAddClick,
    handleChangeType,
    handleChangeLabe,
    handleReduceInputs,
    handleChangeCheckBox,
  } = useGraphicForm();
  let schedules = [];
  const handleSubmit = async (event) => {
    event.preventDefault();

    let newData = {};
    const formatTime = (time) => {
      return time
        ? new Date(time).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
          })
        : "";
    };

    if (scheduleType === "standard") {
      schedules.push({
        jobStartTime: formatTime(formData.jobStartTime),
        jobEndTime: formatTime(formData.jobEndTime),
        restStartTime: formatTime(formData.restStartTime),
        restEndTime: formatTime(formData.restEndTime),
      });
      newData = {
        scheduleType: scheduleType,
        schedules: schedules,
        workType: parseInt(formData.workType),
      };
    } else if (scheduleType === "shift") {
      for (let i = 0; i < numGroups; i++) {
        schedules.push({
          jobStartTime: formatTime(formData[`jobStartTime${i}`]),
          jobEndTime: formatTime(formData[`jobEndTime${i}`]),
        });
      }
      newData = {
        scheduleType: scheduleType,
        workType: parseInt(formData.workType),
        schedules: schedules,
      };
    } else if (scheduleType === "flexible") {
      for (let i = 0; i < inputSets; i++) {
        if (isHoliday[i] === true) {
          schedules.push({
            isHoliday: true,
          });
        } else {
          schedules.push({
            jobStartTime: formatTime(formData[`jobStartTime${i}`]),
            jobEndTime: formatTime(formData[`jobEndTime${i}`]),
          });
        }
      }
      newData = {
        scheduleType: scheduleType,
        schedules: schedules,
      };
    }
    const result = removeEmptyStrings(newData);

    try {
      const res = await postGraphicSetting(result);
      setData(result);
      getData();
      handleClose();
      return res;
    } catch (error) {
      console.error("Error while posting graphic setting:", error);
    }
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  // Function to check if all required fields are filled
  const checkFormCompletion = () => {
    let requiredFieldsFilled = false;
    if (scheduleType === "standard") {
      requiredFieldsFilled =
        formData.workType !== "" &&
        formData.jobEndTime &&
        formData.jobStartTime &&
        scheduleType !== "";
    } else if (scheduleType === "shift") {
      let workTypeFilled = formData.workType !== "";
      let allGroupsFilled = true;
      for (let i = 0; i < numGroups; i++) {
        if (!formData[`jobStartTime${i}`] || !formData[`jobEndTime${i}`]) {
          allGroupsFilled = false;
          break;
        }
      }
      requiredFieldsFilled =
        workTypeFilled && allGroupsFilled && scheduleType !== "";
    } else if (scheduleType === "flexible") {
      for (let i = 0; i < inputSets; i++) {
        requiredFieldsFilled =
          isHoliday[i] === true ||
          (formData[`jobStartTime${i}`] && formData[`jobEndTime${i}`]);
      }
    }
    setIsSubmitDisabled(!requiredFieldsFilled);
  };

  useEffect(() => {
    checkFormCompletion();
  }, [formData, scheduleType, isHoliday, numGroups, inputSets]);
  return (
    <form className={styles.graphicForm}>
      <div className={styles.graphicFormTop}>
        <GraphicFormLeft
          formData={formData}
          numGroups={numGroups}
          showInputs={showInputs}
          scheduleType={scheduleType}
          handleChange={handleChange}
          handleAddClick={handleAddClick}
          handleChangeLabe={handleChangeLabe}
          handleChangeType={handleChangeType}
        />
        <GraphicFormRight
          label={label}
          formData={formData}
          numGroups={numGroups}
          isHoliday={isHoliday}
          inputSets={inputSets}
          showInputs={showInputs}
          scheduleType={scheduleType}
          handleChange={handleChange}
          isSubmitDisabled={isSubmitDisabled}
          handleReduceInputs={handleReduceInputs}
          handleChangeCheckBox={handleChangeCheckBox}
        />
      </div>
      <div className={styles.graphicFormBottom}>
        <Button className={styles.hiddenBtn}></Button>
        {scheduleType === "flexible" ? (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
            className={styles.addBtn}
          >
            Əlavə et
          </Button>
        ) : null}

        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isSubmitDisabled}
        >
          Yadda saxla
        </Button>
      </div>
    </form>
  );
};

export default GraphicForm;
