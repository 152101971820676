import {
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Paper,
} from "@mui/material";
import styled from "@emotion/styled";
import React from "react";
import moment from "moment";

const CompensationTable = ({ data }) => {
  const Thead = styled.thead`
    border-radius: 3px;
    background-color: rgba(21, 27, 76, 0.7);
    border-bottom: 1px solid rgba(41, 47, 96, 1);
  `;

  return (
    <TableContainer sx={{ border: "1px solid #8f8686" }} component={Paper}>
      <Table
        sx={{
          borderRadius: "5px",
          borderCollapse: "collapse",
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <Thead>
          <TableRow>
            <TableCell sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}>
              Əməkdaşın adı
            </TableCell>
            <TableCell
              sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
              align="center"
            >
              Əməkdaşın soyadı
            </TableCell>
            <TableCell
              sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
              align="center"
            >
              Əməkdaşın ata adı
            </TableCell>
            <TableCell
              sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
              align="center"
            >
              İş ilinin başlanması
            </TableCell>
            <TableCell
              sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
              align="center"
            >
              İş ilinin bitməsi
            </TableCell>
            <TableCell
              sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
              align="center"
            >
              Yaranmış məzuniyyət hüququ
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "5px",
                }}
              >
                <p>Əsas</p>
                <p>Əlavə</p>
                <p>Cəmi</p>
              </div>
            </TableCell>
            <TableCell
              sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
              align="center"
            >
              İstifadə edilmiş məzuniyyət
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "5px",
                }}
              >
                <p>Əsas</p>
                <p>Əlavə</p>
                <p>Cəmi</p>
              </div>
            </TableCell>
            <TableCell
              sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
              align="center"
            >
              Qalıq məzuniyyət günləri
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "5px",
                }}
              >
                <p>Əsas</p>
                <p>Əlavə</p>
                <p>Cəmi</p>
              </div>
            </TableCell>
          </TableRow>
        </Thead>
        <TableBody>
          {data?.map((employeeData, empIndex) => (
            <TableRow key={empIndex}>
              <TableCell
                sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
                align="center"
              >
                {employeeData?.employee?.firstname}
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
                align="center"
              >
                {employeeData?.employee?.lastname}
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
                align="center"
              >
                {employeeData?.employee?.fathername}
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
                align="center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "35px",
                  }}
                >
                  {employeeData?.vacationTrackings?.flatMap((vacation) => {
                    if (vacation?.vacationReports.length > 1) {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <div key={reportIndex}>
                            <p>
                              {moment(report?.startWorkYear).format(
                                "DD/MM/YYYY"
                              )}
                            </p>
                          </div>
                        )
                      );
                    } else {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <p key={`${empIndex}-${reportIndex}`}>
                            {moment(report?.startWorkYear).format("DD/MM/YYYY")}
                          </p>
                        )
                      );
                    }
                  })}
                </div>
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
                align="center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "35px",
                  }}
                >
                  {employeeData?.vacationTrackings?.flatMap((vacation) => {
                    if (vacation?.vacationReports.length > 1) {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <div key={reportIndex}>
                            <p>
                              {moment(report?.endWorkYear).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        )
                      );
                    } else {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <p key={`${empIndex}-${reportIndex}`}>
                            {moment(report?.endWorkYear).format("DD/MM/YYYY")}
                          </p>
                        )
                      );
                    }
                  })}
                </div>
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
                align="center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "35px",
                  }}
                >
                  {employeeData?.vacationTrackings?.flatMap((vacation) => {
                    if (vacation?.vacationReports.length > 1) {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <p>{report?.reserved?.main}</p>
                            <p>{report?.reserved?.additional}</p>
                            <p>{report?.reserved?.total}</p>
                          </div>
                        )
                      );
                    } else {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                            key={`${empIndex}-${reportIndex}`}
                          >
                            <p>{report?.reserved?.main}</p>
                            <p>{report?.reserved?.additional}</p>
                            <p>{report?.reserved?.total}</p>
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
                align="center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "35px",
                  }}
                >
                  {employeeData?.vacationTrackings?.flatMap((vacation) => {
                    if (vacation?.vacationReports.length > 1) {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <div
                            key={reportIndex}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <p>{report?.used?.main}</p>
                            <p>{report?.used?.additional}</p>
                            <p>{report?.used?.total}</p>
                          </div>
                        )
                      );
                    } else {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                            key={`${empIndex}-${reportIndex}`}
                          >
                            <p>{report?.used?.main}</p>
                            <p>{report?.used?.additional}</p>
                            <p>{report?.used?.total}</p>
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              </TableCell>
              <TableCell
                sx={{ borderRight: "1px solid rgba(81, 81, 81, 1)" }}
                align="center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "35px",
                  }}
                >
                  {employeeData?.vacationTrackings?.flatMap((vacation) => {
                    if (vacation?.vacationReports.length > 1) {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <div
                            key={reportIndex}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <p>{report?.unUsed?.main}</p>
                            <p>{report?.unUsed?.additional}</p>
                            <p>{report?.unUsed?.total}</p>
                          </div>
                        )
                      );
                    } else {
                      return vacation?.vacationReports?.map(
                        (report, reportIndex) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                            key={`${empIndex}-${reportIndex}`}
                          >
                            <p>{report?.unUsed?.main}</p>
                            <p>{report?.unUsed?.additional}</p>
                            <p>{report?.unUsed?.total}</p>
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompensationTable;
