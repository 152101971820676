import { Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import CommentDialog from "./dialogParts/parts/CommentDialog";
import RequestDialog from "../../../layouts/signing/dialog/RequestDialog";

export default function CommentDialogWithButton({ documentId, submit }) {
  const [commmenDialog, renderCommmenDialog] = useState({
    type: null,
    isShow: false,
  });
  const [simaBase64, setSimaBase64] = useState();
  const [showSignRequestDialog, setShowSignRequestDialog] = useState(false);
  return (
    <>
      <div className="mt-2 d-flex">
        <Button
          onClick={() =>
            renderCommmenDialog({ isShow: true, type: "approved" })
          }
          className="me-1"
          fullWidth
          variant="contained"
          color="success"
        >
          İmzala
        </Button>
        <Button
          onClick={() =>
            renderCommmenDialog({ isShow: true, type: "rejected" })
          }
          fullWidth
          variant="contained"
          color="error"
        >
          İmtina et
        </Button>
      </div>
      <Dialog
        open={commmenDialog.isShow}
        onClose={() => {
          renderCommmenDialog({ isShow: false, type: undefined });
          // setShowSignRequestDialog(false);
        }}
        PaperProps={{ style: commDialogPaperStyle }}
        maxWidth={"1000px"}
      >
        <CommentDialog
          // setShowSignRequestDialog={() => setShowSignRequestDialog(true)}
          type={commmenDialog.type}
          id={documentId}
          onClose={() => {
            renderCommmenDialog({ isShow: false, type: undefined });
          }}
          onSubmitHandler={submit}
          simaDataHandler={(data) => {
            if (!data) return;
            setSimaBase64(data.data);
            setShowSignRequestDialog(true);
          }}
        />
      </Dialog>

      <Dialog
        maxWidth={"800px"}
        open={showSignRequestDialog}
        onClose={() => setShowSignRequestDialog(false)}
        PaperProps={{
          style: {
            borderRadius: "27px",
            width: "1000px",
            height: "600px",
          },
        }}
      >
        <RequestDialog
          // refreshPage={refreshPage}
          simaData={simaBase64}
          onClose={() => {
            // onClose();
            renderCommmenDialog({ isShow: false, type: undefined });
            setShowSignRequestDialog(false);
          }}
          // openCommentDialog={(type) => {
          //   setShowCommDialog({ type: type, isShow: true });
          // }}
        />
      </Dialog>
    </>
  );
}

let commDialogPaperStyle = {
  width: 1000,
  height: "auto",
  opacity: 0.95,
  borderRadius: "10px",
};
