import React, { useCallback, useEffect, useMemo, useState } from "react";

import Table from "../../components/table/Table";
import Column from "../../models/table/Column";
import {
  getDayOfWeek,
  getCurrentMonthRange,
} from "../../utils/helperFunctions";

import { Helmet } from "react-helmet-async";

import { spacing } from "@mui/system";
import { Typography, Divider as MuiDivider, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { getAccountingTable_SERVICE } from "../../services/accountingTableServices";
import {
  getPartsForFilter,
  getStaticFilterValues,
} from "../../utils/forFilterDropdown/getStructureUtils";
import {
  bgColorHandler,
  COLORS_STATUS,
  isLocked,
  OPERATORS_CONTAINING,
  titleHandler,
} from "../../constants";
import { GENERAL_BLOCK } from "../../styled";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import { CompareArrows, RestartAlt } from "@mui/icons-material";
import Operator from "../../models/table/Operator";
import SelectInput from "../../components/ui/Select/SelectInput";
import { getCompanyStructures } from "../../services/structure/StructureServices";

const Divider = styled(MuiDivider)(spacing);

const TimesheetTable = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [data, setData] = useState([]);
  const [columns, set_columns] = useState([]);
  // const currentYear = new Date().getFullYear();
  // const currentMonth = new Date().getMonth() + 1;
  const currentMonthDays = new Date(currentYear, currentMonth, 0).getDate();
  const currentMonthRange = getCurrentMonthRange();
  const [structureDatas, setStructureDatas] = useState({
    total: 0,
    structures: [],
  });
  const [currentStructure, setCurrentStructure] = useState();
  let enums = {
    labourVacation: "Ə/M",
    unpaidVacation: "Ö/M",
    educationalAndCreativeVacation: "T/M",
    socialVacation: "S/M",
    temporaryLoss: "X/V",
    unknownReason: "N/S",
    training: "T/K",
    businessTrip: "E",
    restDay: "İ",
    holiday: "B",
    mourningDay: "H",
    nonWorkingDay: "Q",
    workDay: "",
  };

  useEffect(() => {
    let arr = [];
    const staticColumns = [
      new Column({
        path: "staff.employee.tableNumber",
        headerName: "Tabel No.",
        widthOfColumn: 100,
        // renderCell: (row, col) => {
        //   // console.log(row);
        //   return row.staff?.employee[0]?.tableNumber;
        // },
        filterType: OPERATORS_CONTAINING.TEXT,
      }),
      new Column({
        path: "isLocked",
        headerName: "Status",
        widthOfColumn: 130,
        filterType: OPERATORS_CONTAINING.OPTION,
        filterValues: isLocked,
        dataGridName: "time-table",
        renderCell: (row) => {
          // console.log(row);
          return (
            <div className="text-center">
              {row?.isLocked ? "Tabel bağlıdır" : "Tabel aktivdir"}
            </div>
          );
        },
      }),
      new Column({
        path: "staff.employee.fullname",
        headerName: "Əməkdaşın A.S.A.",
        widthOfColumn: 200,
        filterType: OPERATORS_CONTAINING.TEXT,
        dataGridName: "time-table",
        renderCell: (row, col) => {
          // console.log(row);
          return (
            <div className="text-center">{`${row.staff?.employee?.firstname} ${row.staff?.employee?.lastname} ${row.staff?.employee?.fathername}`}</div>
          );
        },
      }),
      new Column({
        path: "structure.organization.name",
        headerName: "Struktur",
        widthOfColumn: 200,
        filterColumnKey: "baseStructure",
        dataGridName: "time-table",
        fetchFilterValuesV2: getStaticFilterValues,
        keyForResponseDataFromFilter: "baseStructures",
        filterType: OPERATORS_CONTAINING.IN,
      }),
      new Column({
        path: "parentPart.source.name",
        headerName: "Sub-struktur",
        widthOfColumn: 200,
        filterColumnKey: "parentPart._id",
        dependencieKeysOfFilter: {
          structures: ["baseStructure._id"],
          topPartOfStructures: ["topPartOfStructure._id"],
        },
        dataGridName: "time-table",
        fetchFilterValuesV2: getStaticFilterValues,
        keyForResponseDataFromFilter: "topPartOfStructures",
        filterType: OPERATORS_CONTAINING.IN,
        // renderCell: (row, col) => {
        //   // console.log(row);
        //   return row.parentPart?.organization.name;
        // },
      }),
      new Column({
        path: "staff.position.name",
        headerName: "Vəzifə",
        widthOfColumn: 150,
        filterColumnKey: "staff.part._id",
        dependencieKeysOfFilter: {
          structures: ["baseStructure._id"],
          topPartOfStructures: ["topPartOfStructure._id"],
        },
        dataGridName: "time-table",
        fetchFilterValuesV2: getStaticFilterValues,
        keyForResponseDataFromFilter: "positions",
        filterType: OPERATORS_CONTAINING.IN,
      }),
      new Column({
        path: "structure.workDayMode",
        headerName: "İşlədiyi gün",
        widthOfColumn: 200,
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "structure.workHourMode",
        headerName: "İşlədiyi saat",
        widthOfColumn: 200,
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "totalWorkDays",
        headerName: "Norma günü",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "totalWorkHours",
        headerName: "Norma saatı",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "labourVacation",
        headerName: "Əmək məzuniyyəti",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "unpaidVacation",
        headerName: "Ödənişsiz Məzuniyyət",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "educationalAndCreativeVacation",
        headerName: "Təhsil və yaradıcılıq məzuniyyəti",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "socialVacation",
        headerName: "Sosial məzuniyyəti",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "training",
        headerName: "Təlim-tədris",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "temporaryLost",
        headerName: "Xəstəlik",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "unknownReason",
        headerName: "Naməlum səbəb",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "businessTrip",
        headerName: "Ezamiyyət",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "totalOffDays",
        headerName: "Qeyri iş günləri (istirahət/bayram/hüzn günləri)",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "calendarDays",
        headerName: "Təqvim günləri",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
    ];
    if (data[0] && data?.[0].tables?.length) {
      for (let i = 0; i < data?.[0]?.tables?.length; i++) {
        arr[i] = new Column({
          headerName: `${i + 1}`,
          fetchFilterValues: getPartsForFilter,
          filterType: OPERATORS_CONTAINING.OPTION,
          renderCell: (row) => {
            const day = row?.tables?.[i];

            return (
              <div style={{ border: "1px solid transparent" }}>
                {day?.dayType === "workDay" ? (
                  <div className="position-relative text-center">
                    <GENERAL_BLOCK
                      className="position-relative"
                      style={{
                        borderRadius: "5px",
                        marginTop: "5px",
                        backgroundColor: COLORS_STATUS.GREEN,
                        width: "100%",
                        textAlign: "center",
                        border: "1px solid white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "5px",
                        height: "25px",
                      }}
                    >
                      {day?.dayHours} saat
                    </GENERAL_BLOCK>
                    {/* <GENERAL_BLOCK
                      bg_color={COLORS_STATUS.RED}
                      brad="5px"
                      className="mb-2"
                    >
                      {day?.lunchStartHour}-{day?.lunchEndHour}
                      sd
                    </GENERAL_BLOCK> */}
                  </div>
                ) : (
                  <div
                    className="position-relative"
                    style={{
                      borderRadius: "5px",
                      marginTop: "5px",
                      backgroundColor: bgColorHandler(day?.dayType),
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      height: "25px",
                    }}
                  >
                    <span>{enums[day?.dayType]}</span>
                  </div>
                )}
              </div>
            );
          },
        });
      }
    }
    staticColumns?.push(...arr);
    set_columns(staticColumns?.length > 19 ? [...staticColumns] : []);
  }, [data]);

  useEffect(() => {
    async function getTabel() {
      const filterParam =
        filters.length > 0
          ? `${encodeURIComponent(JSON.stringify(filters))}`
          : "";

      let structure = structureDatas?.structures?.find(
        (s) => s?.organization?.name === currentStructure
      )?._id;
      try {
        const response = await getAccountingTable_SERVICE({
          year: currentYear,
          month: currentMonth,
          structure,
          filter: filterParam,
          limit,
          skip: offset,
        });
        setData(response.data?.data?.items);
        setTotal(response.data?.data?.total);
      } catch (error) {
        console.error(error);
        return false;
      }
    }
    getTabel();
  }, [filters, currentYear, currentMonth, currentStructure]);

  useEffect(() => {
    const currMonth = new Date().getMonth();
    const currYear = new Date().getFullYear();
    setCurrentMonth(currMonth);
    setCurrentYear(currYear);
    fetchCompanyStructures();
  }, []);

  async function fetchCompanyStructures() {
    try {
      const data = await getCompanyStructures({
        limit: 100,
        skip: 0,
      });
      if (data && data.structures && data.structures.length > 0) {
        setStructureDatas(data);
        setCurrentStructure(data?.structures[0]?.organization?.name);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  const handleYearChange = (value) => {
    setCurrentYear(value);
  };

  const handleMonthChange = (value) => {
    const selectedMonth = months.find((month) => month.name === value);
    if (selectedMonth) {
      setCurrentMonth(selectedMonth.key);
    }
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  return (
    <React.Fragment>
      <Helmet title="Employees timesheet" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        sx={{ marginRight: 6 }}
      >
        Tabel cədvəli
      </Typography>
      <Typography variant="h4" gutterBottom display="inline">
        {currentMonthRange}
      </Typography>
      <Divider my={6} />
      <SelectDateContainer>
        <GENERAL_BLOCK className="text-center" f_size="23px" wid="80%">
          {months[currentMonth].name} {currentYear}
        </GENERAL_BLOCK>
        <div className="d-flex" style={{ width: "40%" }}>
          <SelectInput
            label={"Struktur: "}
            value={currentStructure || null}
            onChange={(value) => setCurrentStructure(value)}
          >
            {structureDatas?.structures.map((structure) => (
              <option
                key={structure?.organization?.name}
                value={structure?.organization?.name}
              >
                {structure?.organization?.name}
              </option>
            ))}
          </SelectInput>
          <SelectInput
            styleOuterContainer={{ width: "130px" }}
            label={"İl: "}
            value={currentYear}
            onChange={handleYearChange}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </SelectInput>

          <SelectInput
            styleOuterContainer={{ width: "130px" }}
            label={`Ay: `}
            value={months[currentMonth].name}
            data={months}
            itemName={"name"}
            itemValue={"name"}
            onChange={handleMonthChange}
          >
            <option value={months[currentMonth].key}>
              {months[currentMonth].name}
            </option>
          </SelectInput>
        </div>
      </SelectDateContainer>
      {columns?.length > 0 || filters?.length > 0 ? (
        <Table
          columns={columns}
          data={data}
          totalRows={total}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={loading}
        />
      ) : (
        <div style={{ textAlign: "center", padding: "30px" }}>
          Məlumat yoxdur
        </div>
      )}
    </React.Fragment>
  );
};

export default TimesheetTable;

const SelectDateContainer = styled.div`
  display: flex;
  justify-content: end;
  position: sticky;
  top: 64px;
  background-color: #1b2635;
  padding: 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  // width: max-content;
  z-index: 999;
`;

const months = [
  {
    name: "Yanvar",
    key: 0,
  },
  {
    name: "Fevral",
    key: 1,
  },
  {
    name: "Mart",
    key: 2,
  },
  {
    name: "Aprel",
    key: 3,
  },
  {
    name: "May",
    key: 4,
  },
  {
    name: "İyun",
    key: 5,
  },
  {
    name: "İyul",
    key: 6,
  },
  {
    name: "Avqust",
    key: 7,
  },
  {
    name: "Sentyabr",
    key: 8,
  },
  {
    name: "Oktyabr",
    key: 9,
  },
  {
    name: "Noyabr",
    key: 10,
  },
  {
    name: "Dekabr",
    key: 11,
  },
];
const years = Array.from({ length: 27 }, (_, index) => 2024 + index);
