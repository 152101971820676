import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Divider, Grid } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import PersonIcon from "@mui/icons-material/Person";

import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";

// profile components parts
import MyProfileInfo from "./Personal_informations/MyProfileInfo";
import ShowBankInfo from "./Personal_informations/ShowBankInfo";
import ShowLaborActivity from "./Personal_informations/LaborActivity";
import ShowRelationshipInfo from "./Personal_informations/Relationshipİnfo";
import ShowContactInfo from "./Personal_informations/ContactInfo";
import ShowEducationInfo from "./Personal_informations/EductaionInfo";
import ShowAcademicDegreeInfo from "./Personal_informations/AcademicDegreeInfo";
import ShowSosialStatusInfo from "./Personal_informations/SocialStatus";
import ShowSupplementalInfo from "./Personal_informations/SupplementalWagesInfo";
import ShowRewardsAndReprimandsInfo from "./Personal_informations/RewardsAndReprimands";
import ShowDocumentsInfo from "./Personal_informations/DocumentsInfo";
// css
import style from "./myProfileStyle.module.css";

// api
import Navbar from "./Navbar";
import { useSelector } from "react-redux";

export default function MyProfile() {
  const [navbarStatus, setNavbarStatus] = useState(false);
  const { currentEmployee } = useSelector((state) => state.commonThings);

  const headerData = {
    title: "Şəxsi Kabinetim",
    variant: "h3",
  };

  // const showHandler = () => setNavbarStatus(!navbarStatus);

  // const navbarCloseHandler = (e) => {
  //   e.stopPropagation();
  //   setNavbarStatus(false);
  // };

  const navbarStatusHandler = (currentValue) => {
    setNavbarStatus(currentValue);
  };

  return (
    <>
      <Grid
      // className={navbarStatus ? style.hover : style.navbarPlace}
      // className={style.navbarPlace}
      // onMouseEnter={(e) => navbarOpenHandler(e)}
      // // onClick={(e) => navbarOpenHandler(e)}
      // onMouseLeave={(e) => navbarCloseHandler(e)}
      >
        {/* {navbarStatus === false ? (
          <Grid className={style.navbarProfile}>
            <PersonIcon sx={{ width: "100%", height: "100%" }} />
          </Grid>
        ) : (
          // <MyCabinetNavbar />
          <Navbar />
        )} */}
        <Navbar navbarStatus={navbarStatusHandler} />
      </Grid>
      <React.Fragment>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
          <ToastContainer />
          <Divider sx={{ marginTop: 5 }} />
          <Grid
            className={
              style.infoSection +
              " " +
              (navbarStatus ? style.animation2 : style.animation1)
            }
          >
            <MyProfileInfo employeeById={currentEmployee?._id} />

            <ShowBankInfo employeeById={currentEmployee?._id} />

            <ShowLaborActivity employeeById={currentEmployee?._id} />

            <ShowRelationshipInfo employeeById={currentEmployee?._id} />

            <ShowContactInfo employeeById={currentEmployee?._id} />

            <ShowEducationInfo employeeById={currentEmployee?._id} />

            <ShowAcademicDegreeInfo employeeById={currentEmployee?._id} />

            <ShowSosialStatusInfo employeeById={currentEmployee?._id} />

            <ShowSupplementalInfo employeeById={currentEmployee?._id} />

            <ShowRewardsAndReprimandsInfo employeeById={currentEmployee?._id} />

            <ShowDocumentsInfo employeeById={currentEmployee?._id} />
          </Grid>
        </Grid>
      </React.Fragment>
    </>
  );
}
