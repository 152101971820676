// React
import { useEffect, useState } from "react";

// Material UI
import { Button, Grid, Popover, Typography } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";

import DataTable from "../../../components/layouts/dataTable/DataTable";

import { getOrdersContracts } from "../../../services/employeeApplication(employee)/EmployeeApplicationService";
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";
import { normalizeDate } from "../../../utils/helperFunctions";

export default function ContractsTable() {
  const [totalRow, setTotalRow] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowID, setRowID] = useState();
  const [filters, setFilters] = useState([]);

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function buttonColorPicker(status) {
    switch (status) {
      case "pending":
        return "blue";
      case "approved":
        return "green";
      case "rejected":
        return "red";
      default:
        return "yellow";
    }
  }

  function statusTranslater(status) {
    switch (status) {
      case "pending":
        return "imza gözlənilir";
      case "approved":
        return "qüvvədədir";
      case "rejected":
        return "imtina edilmişdir";
      default:
        return "";
    }
  }

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  // const handlePaginationChange = (pageSize, table) => {
  //   if (!pageSize) return;
  //   const value = pageSize.page * pageSize.pageSize;
  //   setOffset(value);
  //   setLimit(parseInt(pageSize.pageSize));
  // };

  useEffect(() => {
    const getResponse = async () => {
      try {
        setIsLoading(true);
        const { data } = await getOrdersContracts({
          limit: limit || 5,
          offset: offset || 0,
          contractType: "agreement",
        });
        setTotalRow(data.total);
        setRowData(
          data.contracts.map((item, index) => {
            return {
              id: item._id,
              orderno: item.data.docNumber?.value,
              date: item.status === "approved" ? item.verifiers[0].date : "",
              content: item.contractType.name,
              name: item.verifiers[0]?.employee.firstname || "",
              surname: item.verifiers[0]?.employee.lastname || "",
              fathername: item.verifiers[0]?.employee.fathername || "",
              status: item.status,
            };
          })
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };

    getResponse();
  }, [limit, offset]);

  const columns = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
    }),
    new Column({
      path: "orderno",
      headerName: "Əmr no",
    }),
    new Column({
      path: "date",
      headerName: "Tarix",
      renderCell: (row, col) => {
        return normalizeDate(row.date);
      },
    }),
    new Column({
      path: "content",
      headerName: "Məzmun",
    }),
    new Column({
      path: "name",
      headerName: "Ad",
    }),
    new Column({
      path: "surname",
      headerName: "Soyad",
    }),
    new Column({
      path: "fathername",
      headerName: "Ata adı",
    }),
    new Column({
      path: "status",
      headerName: "Status",
    }),
  ];

  return (
    <>
      <Grid sx={{ width: "100%" }}>
        <Table
          columns={columns}
          data={rowData}
          totalRows={totalRow}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
        />
      </Grid>
    </>
  );
}
