import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import ButtonCustom from "../../ui/Button/ButtonCustom";
import Input from "../../ui/Input/Input";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
`;

const AnchorSubMenu = styled.div`
  position: absolute;
  min-width: 300px;
  top: 100%;
  left: 0;
  overflow: scroll;
  height: 400px;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 5px;
  z-index: 99;
  visibility: ${({ $show }) => ($show ? "visible" : "hidden")};
  background-color: #1b2635;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transform: scale(${({ $show }) => ($show ? "1" : "0")});
  transform-origin: top left;
  animation: ${({ $show }) => ($show ? fadeIn : fadeOut)} 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px;
`;

const AnchorMenuContainer = styled.div`
  position: relative;
  width: ${({ $width }) => ($width ? $width : "fit-content")};
  height: 100%;
`;

const Ul = styled.ul`
  list-style: none;
  padding: 5px 5px;
  margin: 5px;
`;

const Li = styled.li`
  width: max-content;
  min-width: "100%";
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  transition: all 0.15s;
`;

export default function ColumnsVisibility({ data, setData, width }) {
  const [showMenu, setShowMenu] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [cursorStyle, setCursorStyle] = useState("pointer");
  const [isAllShown, setIsAllShown] = useState(true);

  const [searchList, setSearchList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const columnsRender = searchList.length > 0 ? searchList : data;
  const submenuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const arr = data.filter((item) =>
      item.headerName.toLowerCase().includes(searchInput)
    );

    if (searchInput.length > 0 && arr.length < 1) {
      setSearchList(["empty"]);
    } else setSearchList([...arr]);
  }, [searchInput, data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showMenu &&
        submenuRef.current &&
        !submenuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showMenu]);

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  const onChangeHandler = (index, value, column) => {
    if (
      data.filter((item) => item.isShow === true && !item.isStaticColumn)
        .length === 1 &&
      value === false
    ) {
      // Only one item has isShow as false, disable changing to true
      return;
    }

    const updatedData = data.map((item) => {
      if (item.id === column.id) return { ...item, isShow: !item.isShow };
      return item;
    });

    // data.map((item) => {
    //   if (item.id === column.id) return { ...item, isShow: !item.isShow };
    //   return item;
    // });

    // updatedData[index] = {
    //   ...updatedData[index],
    //   isShow: !updatedData[index].isShow,
    // };

    setData(updatedData);
  };

  const handleDragStart = (e, index) => {
    setDraggedItem({ index, content: data[index] });
    setCursorStyle("move");
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.dropEffect = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (!draggedItem || index === draggedItem.index) return;

    const rect = e.target.getBoundingClientRect();
    const offset = e.clientY - rect.top;
    const newIndex = offset > rect.height / 2 ? index + 1 : index;

    const newItems = Array.from(data);
    const removed = newItems.splice(draggedItem.index, 1)[0];
    newItems.splice(newIndex, 0, removed);

    setData(newItems);
    setDraggedItem({ ...draggedItem, index: newIndex });
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    setCursorStyle("pointer");
  };

  const toggleShowHandler = () => {
    const updatedData = data.map((item, index) => {
      if (item.isStaticColumn) return { ...item };

      return {
        ...item,
        isShow: isAllShown ? (index === 0 ? true : false) : true,
      };
    });

    setData(updatedData);
    setIsAllShown(!isAllShown);
  };

  return (
    <AnchorMenuContainer $width={width}>
      <ButtonCustom width={"100%"} buttonRef={buttonRef} onClick={toggleMenu}>
        Sütunlar
      </ButtonCustom>
      {
        <AnchorSubMenu $show={showMenu} ref={submenuRef}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              position: "sticky",
              padding: "10px 0",
              top: "-11px",
              zIndex: "9999",
              background: "#1b2635",
            }}
          >
            <Input
              styleContainer={{ height: "30px" }}
              placeholder={"Sütunu tapın"}
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ButtonCustom
                width={"90px"}
                fontSize={"10px"}
                onClick={toggleShowHandler}
              >
                {isAllShown ? "Hamısını gizlət" : "Hamısını göstər"}
              </ButtonCustom>
            </div>
          </div>
          <Ul style={{ height: "100vh" }}>
            {columnsRender[0] !== "empty" &&
              columnsRender?.map(
                (item, index) =>
                  !item.isStaticColumn && (
                    <Li
                      key={index}
                      draggable
                      onDragStart={(event) => handleDragStart(event, index)}
                      onDragOver={(event) => handleDragOver(event, index)}
                      onDragEnd={handleDragEnd}
                      style={{
                        width: "max-content",
                        minWidth: "100%",
                        cursor: cursorStyle,
                        backgroundColor:
                          draggedItem?.index === index ? "#eee" : "transparent",
                      }}
                      onClick={() => onChangeHandler(index, !item.isShow, item)}
                    >
                      <div
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <input
                          type="checkbox"
                          style={{ marginRight: "3px", width: "max-content" }}
                          // value={columnsRender[index]?.isShow}
                          checked={item?.isShow}
                          onChange={() =>
                            onChangeHandler(index, !item.isShow, item)
                          }
                        />
                        <span>{item?.headerName}</span>
                      </div>
                    </Li>
                  )
              )}
          </Ul>
        </AnchorSubMenu>
      }
    </AnchorMenuContainer>
  );
}
