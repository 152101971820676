import {
  Grid,
  Typography,
  styled,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  LinearProgress,
  Pagination,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getEmployeesAtWorkDep } from "../../../api/vacationRequests/vacationRequests";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const DepartmentsEmployeeAtWork = ({ id }) => {
  const [totalDepartments, setTotalDepartments] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [departmentEmployees, setDepartmentEmployees] = useState({ parts: [] });

  useEffect(() => {
    const getDepartmentEmployees = async () => {
      try {
        if (id) {
          const response = await getEmployeesAtWorkDep(id, limit, offset);
          if (response && response.data) {
            const modifiedResponse = response.data.parts.map((item, index) => ({
              ...item,
              id: index,
              name: item.part.source.name || "",
              atWorkPerc: Math.round(
                (item.atWork / (item.atWork + item.onVacation)) * 100
              ),
              progressBar: Math.round(
                (item.atWork / (item.atWork + item.onVacation)) * 100
              ),
            }));
            setTotalDepartments(response.data.total);
            setDepartmentEmployees(modifiedResponse);
          }
        }
      } catch (error) {
        console.log("err", error);
      }
    };
    getDepartmentEmployees();
  }, [offset, id]);

  const columns = [
    { field: "name", headerName: "Departament Adı", flex: 1 },
    {
      field: "atWorkPerc",
      headerName: "%",
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        if (isNaN(value)) {
          return <span>0</span>;
        }
        return <span>{value}</span>;
      },
    },
    {
      field: "progressBar",
      headerName: "Əməkdaş %",
      flex: 1,
      renderCell: (params) => (
        <Grid>
          <LinearProgress
            sx={{ width: params.colDef.computedWidth, height: "15px" }}
            variant="determinate"
            value={params.value}
          />
        </Grid>
      ),
    },
  ];

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid>
          <Grid>
            <Typography variant="h4" mb={3}>
              Departamanetlər üzrə iş başında əməkdaş faizi
            </Typography>
          </Grid>
          <Grid>
            <DataGrid
              sx={{ border: 0 }}
              disableColumnFilter
              disableColumnSelector
              disableRowSelectionOnClick
              hideFooter
              columns={
                departmentEmployees.length > 0
                  ? columns
                  : [
                      { field: "col1", headerName: "Departament Adı", flex: 1 },
                      { field: "col2", headerName: "%", flex: 1 },
                      { field: "col3", headerName: "Əməkdaş %", flex: 1 },
                    ]
              }
              rows={
                departmentEmployees.length > 0
                  ? departmentEmployees
                  : [{ id: 1, col1: "", col2: "", col3: "" }]
              }
            />
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          {page > 1 && (
            <Pagination
              mb={2}
              count={Math.ceil(totalDepartments / limit)}
              variant="outlined"
              shape="rounded"
              page={page}
              color="secondary"
              onChange={(event, value) => {
                setPage(value);
                if (value !== page) {
                  setOffset((value - 1) * 5);
                }
              }}
            />
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DepartmentsEmployeeAtWork;
