import { Person, SettingsOutlined } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import ButtonDropdown from "../../components/ui/ButtonDropdown/ButtonDropdown";
import { BASE_URL } from "../../constants";
import FilterDropdown from "../../layouts/employment-contracts/dropdown/FilterDropdown";
import Column from "../../models/table/Column";
import {
  downloadExpiringCont,
  getExpiringContractsFilter,
  postNotificationSettings,
} from "../../services/documentations/EmploymentContractsService";
import { downloadFile } from "../../utils/downloadCSV";
import { getDescendantProp } from "../../utils/helperFunctions";

export default function ExpiringLabourContractsTable() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    getEndingContractsHandler();
  }, [offset, limit, filters]);

  const filterHandler = async ({ expireUnit, expireValue }) => {
    try {
      const { data } = await postNotificationSettings({
        expireUnit,
        expireValue,
      });
      if (data.unit && data.value) await getEndingContractsHandler();
    } catch (error) {}
  };

  const getEndingContractsHandler = async () => {
    try {
      // setIsLoadingET(true);
      setLoading(true);
      const { data } = await getExpiringContractsFilter(
        offset ? offset : 0,
        limit ? limit : 5
      );
      const filteredItem = data && filterDataForTable(data.contracts);
      // setEndingContracts((prev) => ({
      //   forRender: filteredItem,
      //   data: data.contracts,
      // }));
      setData(data.contracts);
      setTotal(data.total);
    } catch (error) {
      console.log("endingContract", error);
    } finally {
      setLoading(false);
    }
  };
  const handlePaginationChange = (pageSize, table) => {
    if (!pageSize) return;
    const value = pageSize.page * pageSize.pageSize;
    setOffset(value);
    setLimit(parseInt(pageSize.pageSize));
  };

  const getValueOfRow = (params, key) => {
    const obj = params.row[`data.${key}`];
    if (obj && "value" in obj) {
      return params.row[`data.${key}`]["value"];
    } else {
      return "no value";
    }
  };

  const endContractExportHandler = async (columns) => {
    const forExport = [];
    try {
      columns.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        if (
          item.field === "subjects.subject.firstname" ||
          item.field === "subjects.subject.lastname" ||
          item.field === "subjects.subject.fathername"
        ) {
          forExport.push({
            fieldName: item.headerName,
            path: item.path.slice(9),
            // isArr: false,
            // combineFields:
            // item.combineFields?.length > 0 ? item.combineFields : undefined,
          });
          return;
        } else {
          forExport.push({
            fieldName: item.headerName,
            path: item.path,
            isArr: item.isArr,
            combineFields:
              item.combineFields?.length > 0 ? item.combineFields : undefined,
          });
        }
      });

      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));

      const resp = await downloadExpiringCont(encodedColumns);
      downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const refreshContractHandler = (item) => {};

  const normalizeDate = (dateString) => {
    const date = moment(dateString);
    return date.format("DD/MM/YYYY");
  };

  const filterDataForTable = (data) => {
    return data.map((item) => {
      return { ...item, id: item._id };
    });
  };

  const columns = [
    // new Column({
    //   headerName: "№",
    // }),
    // {
    //    field: "#", maxWidth: 50, type: "number", hide: false },
    new Column({
      field: "#", // Add a custom column for the button
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      renderCell: (row, column) => (
        <Button onClick={() => refreshContractHandler(row.id)}>
          Muqavileni yenile
        </Button>
      ),
    }),
    new Column({
      headerName: "Ad",
      path: "subjects.subject.firstname",
      isArr: true,
      hasCellButton: true,
      cellButtonProps: {
        icon: <Person />,
        onClick: (value) => window.open(`${BASE_URL}/employees/${value}/view`),
        value: (row) => {
          return getDescendantProp(row, "subjects.subject.id", true);
        },
      },
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      headerName: "Soyad",
      path: "subjects.subject.lastname",
      isArr: true,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      headerName: "Ata adi",
      path: "subjects.subject.fathername",
      isArr: true,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      headerName: "Fin",
      path: "data.FIN",
      getValue: (row, column) => {
        return getDescendantProp(row, column.path + ".value");
      },
    }),
    new Column({
      headerName: "Təşkilat",
      path: "data.organizationName",
      getValue: (row, column) => {
        return getDescendantProp(row, column.path + ".value");
      },
    }),
    new Column({
      headerName: "Struktur",
      path: "topPartOfStructures.source.name",
      isArr: true,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      headerName: "Sub-Struktur",
      path: "subStructures.source.name",
      isArr: true,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      headerName: "Vəzifə",
      path: "staffs.position.name",
      isArr: true,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      headerName: "Bitmə Tarixi",
      path: "data.contractEndDate",
      getValue: (row, column) => {
        return normalizeDate(getDescendantProp(row, column.path + ".value"));
      },
    }),
  ];

  const Btn = (
    <ButtonDropdown
      right={0}
      styleContainer={{
        marginLeft: "3px",
      }}
      dropdownComponent={
        <FilterDropdown
          onSubmit={(dataType, dataValue) =>
            filterHandler({
              expireUnit: dataType,
              expireValue: dataValue,
            })
          }
        />
      }
    >
      <SettingsOutlined />
    </ButtonDropdown>
  );

  return (
    <Grid
      sx={{
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: 2,
      }}
    >
      <Grid paddingTop={2.5}>
        <Typography
          variant="h4"
          width={"100%"}
          textAlign={"center"}
          // marginTop={2.5}
          marginBottom={2.5}
        >
          Müqavilə müddəti bitmək üzrə olan əmək müqavilələri
        </Typography>
      </Grid>
      <Grid height={"99%"}>
        <Table
          columns={columns}
          data={data}
          totalRows={total}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={loading}
          hideFilter={true}
          addRightContButtons={[Btn]}
          applyFiltersHandler={(filters) => setFilters(filters)}
          cancelAppliedFilters={() => setFilters([])}
          downloadHandler={(cols) => endContractExportHandler(cols)}
        />
      </Grid>
    </Grid>
  );
}

const styles = {
  statisticContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  endContractsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "400px",
    marginTop: "10px",
  },
  contractsSettingsCont: {
    width: "20%",
    borderRadius: 2,
    padding: 2.5,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    marginRight: 2,
  },
  settingsHeader: { fontSize: 16 },
  settingsLabelCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 2,
    marginLeft: 2,
  },
  allContractsContainer: {
    marginTop: 5,
    width: "100%",
  },
  loadingContainer: {
    // width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
