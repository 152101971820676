import React, { useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { validateDate } from "@mui/x-date-pickers/internals";
import { useNavigate } from "react-router-dom";
import {
  validateNewContractNumber,
  validateReasonforRecall,
  validateSelect,
} from "./recall-work-validation";

const Divider = styled(MuiDivider)(spacing);

const RecallWork = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    employee: "",
    contract_number: "",
    new_contract_number: "",
    new_contract_date: null,
    organization: "",
    structure: "",
    position: "",
    reason_for_recall: "",
  });
  const [errors, setErrors] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    newErrors.employee = validateSelect(formData.employee);
    newErrors.contract_number = validateSelect(formData.contract_number);
    newErrors.new_contract_number = validateNewContractNumber(
      formData.new_contract_number
    );
    newErrors.organization = validateSelect(formData.organization);
    newErrors.structure = validateSelect(formData.structure);
    newErrors.position = validateSelect(formData.position);
    newErrors.reason_for_recall = validateReasonforRecall(
      formData.reason_for_recall
    );
    setErrors(newErrors);

    return Object.values(newErrors).every((error) => !error);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSuccess(true);
      setFormData({
        employee: "",
        contract_number: "",
        new_contract_number: "",
        new_contract_date: null,
        organization: "",
        structure: "",
        position: "",
        reason_for_recall: "",
      });
    } else {
      setIsSuccess(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Əmək məzuniyyətindən geriçağırma barədə əmr
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl error={!!errors.employee} sx={{ width: "50%" }}>
              <InputLabel id="employee">Əməkdaş(A.S.A)</InputLabel>
              <Select
                name="employee"
                labelId="employee"
                label="employee"
                value={formData.employee}
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value="employee1">Əməkdaş(A.S.A) 1</MenuItem>
                <MenuItem value="employee2">Əməkdaş(A.S.A) 2</MenuItem>
              </Select>
              {errors.employee && (
                <FormHelperText>{errors.employee}</FormHelperText>
              )}
            </FormControl>
            <FormControl error={!!errors.organization} sx={{ width: "50%" }}>
              <InputLabel id="organization">İşə geri çağırma növü</InputLabel>
              <Select
                name="organization"
                labelId="İşə geri çağırma növü"
                label="İşə geri çağırma növü"
                fullWidth
                variant="outlined"
                value={formData.organization}
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value="organization1">
                  İşə geri çağırma növü 1
                </MenuItem>
                <MenuItem value="organization2">
                  İşə geri çağırma növü 2
                </MenuItem>
                <MenuItem value="organization3">
                  İşə geri çağırma növü 3
                </MenuItem>
              </Select>
              {errors.organization && (
                <FormHelperText>{errors.organization}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ display: "flex", gap: "25px", width: "50%" }}>
              <FormControl
                error={!!errors.contract_number}
                sx={{ width: "100%" }}
              >
                <InputLabel id="contract_number">Müqavilə nömrəsi</InputLabel>
                <Select
                  value={formData.contract_number}
                  onChange={(e) =>
                    handleChange("contract_number", e.target.value)
                  }
                  name="contract_number"
                  labelId="Müqavilə nömrəsi"
                  label="Müqavilə nömrəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="contract_number1">
                    Müqavilə nömrəsi 1
                  </MenuItem>
                  <MenuItem value="contract_number2">
                    Müqavilə nömrəsi 2
                  </MenuItem>
                  <MenuItem value="contract_number3">
                    Müqavilə nömrəsi 3
                  </MenuItem>
                </Select>
                {errors.contract_number && (
                  <FormHelperText>{errors.contract_number}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={!!errors.contract_number}
                sx={{ width: "100%" }}
              >
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  name="new_contract_date"
                  label="Müqavilənin tarixi"
                  value={formData.new_contract_date}
                  onChange={(e) => handleChange(e)}
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </FormControl>
            </Grid>
            <FormControl
              error={!!errors.reason_for_recall}
              sx={{ width: "50%" }}
            >
              <TextField
                error={!!errors.reason_for_recall}
                name="İşə geri çağırma səbəbi"
                label="İşə geri çağırma səbəbi"
                variant="outlined"
                my={2}
              />
              {errors.reason_for_recall && (
                <FormHelperText>{errors.reason_for_recall}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <TextField
              name="Tabel nömrəsi"
              label="Tabel nömrəsi"
              sx={{ width: "50%" }}
              variant="outlined"
              my={2}
            />
            <Grid sx={{ display: "flex", width: "50%", gap: "25px" }}>
              <TextField
                name="Əmrin nömrəsi"
                label="Əmrin nömrəsi"
                sx={{ width: "50%" }}
                variant="outlined"
                my={2}
              />
              <TextField
                name="Əmrin imzalanma tarixi"
                label="Əmrin imzalanma tarixi"
                sx={{ width: "50%" }}
                variant="outlined"
                my={2}
              />
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl my={2} sx={{ width: "50%" }}>
              <InputLabel id="Təşkilat">Təşkilat</InputLabel>
              <Select
                name="Təşkilat"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Təşkilat 1</MenuItem>
                <MenuItem value="employee2">Təşkilat 2</MenuItem>
                <MenuItem value="employee3">Təşkilat 3</MenuItem>
              </Select>
            </FormControl>
            <Grid sx={{ display: "flex", width: "50%", gap: "25px" }}>
              <DatePicker
                my={2}
                sx={{ width: "50%" }}
                inputFormat="dd/MM/yyyy"
                label="Məzuniyyətin /Ezamiyyənin başlama tarixi"
                minDate={new Date(new Date().getTime() - 86400000 * 30)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DatePicker
                my={2}
                sx={{ width: "50%" }}
                inputFormat="dd/MM/yyyy"
                label="Məzuniyyətin /Ezamiyyənin bitmə tarixi"
                minDate={new Date(new Date().getTime() - 86400000 * 30)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl sx={{ width: "50%" }} my={2}>
              <InputLabel id="Struktur">Struktur</InputLabel>
              <Select
                name="Struktur"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Struktur 1</MenuItem>
                <MenuItem value="employee2">Struktur 2</MenuItem>
                <MenuItem value="employee3">Struktur 3</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <DatePicker
                my={2}
                inputFormat="dd/MM/yyyy"
                label="İşə çıxma tarixi"
                minDate={new Date(new Date().getTime() - 86400000 * 30)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </FormControl>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl my={2} sx={{ width: "50%" }}>
              <InputLabel id="Substruktur">Substruktur</InputLabel>
              <Select
                name="Substruktur"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Substruktur 1</MenuItem>
                <MenuItem value="employee2">Substruktur 2</MenuItem>
                <MenuItem value="employee3">Substruktur 3</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="Gün"
              label="Gün"
              sx={{ width: "50%" }}
              variant="outlined"
              my={2}
            />
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl my={2} sx={{ width: "50%" }}>
              <InputLabel id="Vəzifə">Vəzifə</InputLabel>
              <Select
                name="Vəzifə"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Vəzifə 1</MenuItem>
                <MenuItem value="employee2">Vəzifə 2</MenuItem>
                <MenuItem value="employee3">Vəzifə 3</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <DatePicker
                my={2}
                inputFormat="dd/MM/yyyy"
                label="Yeni işə çıxma tarixi"
                minDate={new Date(new Date().getTime() - 86400000 * 30)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </FormControl>
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              type="submit"
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Yadda saxla
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RecallWork;
