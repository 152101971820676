import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";

import { Grid, Divider, Typography } from "@mui/material";
import Breadcrumb from "../../../components/layouts/breadcrumb/Breadcrumb";
import Column from "../../../models/table/Column";
import Table from "../../../components/table/Table";
import StatusGenerator from "../../../components/table/StatusGenerator";
import {
  getDescendantProp,
  normalizeDate,
} from "../../../utils/helperFunctions";
import {
  OPERATORS_CONTAINING,
  STRUCTURE_CHGANGE_REQUEST_FOR_FILTER,
} from "../../../constants";
import { downloadFile } from "../../../utils/downloadCSV";
import {
  getAllStructureRequestsNames,
  patchStructureRequestById,
} from "../../../utils/forFilterDropdown/getStructureUtils";
import Operator from "../../../models/table/Operator";
import { Close, RemoveRedEye } from "@mui/icons-material";
import OperatorGenerator from "../../../components/table/OperatorGenerator";
import DocumentDialog from "../../../components/dialogs/documentDialog/DocumentDialog";
import {
  getDownloadStructureRequest,
  getStructureChangeRequests,
} from "../../../services/structure/StructureRequestService";
import { getLabourContractDoc_SERVICE } from "../../../services/documentServices";
import { getEmployeeRequestManager } from "../../../services/vacation/VacationRequest";

export default function StructureChangeRequests() {
  const [totalRow, setTotalRow] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [rowData, setRowData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [showReqDialog, setShowReqDialog] = useState(false);
  const [reqId, setReqId] = useState([]);
  const [cancelled, setCancelled] = useState();

  useEffect(() => {
    // getStructures();
    paginationFetchingHandler();
  }, [filters, limit, offset, cancelled]);

  const paginationFetchingHandler = async () => {
    if (filters.length) {
      const trimmedFilters = filters.map((item) => {
        return {
          ...item,
          value: item.value.trim(),
        };
      });
      const encodedFilter = encodeURIComponent(JSON.stringify(trimmedFilters));
      await getResponse(encodedFilter);
    } else {
      await getResponse();
    }
  };

  const downlaodHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(JSON.stringify([...filters]));
      const resp = await getDownloadStructureRequest({
        columns: encodeColumns,
        filters: encodeFilters,
        isWithFilter: filters.length > 0,
      });
      if (resp) downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const getResponse = async (filters = undefined) => {
    try {
      setIsLoading(true);

      const { data } = await getStructureChangeRequests({
        limit: limit || 5,
        offset: offset || 0,
        filters,
      });
      setTotalRow(data.total);

      setRowData(data.requests);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelledButton = async (row) => {
    await patchStructureRequestById({ id: row._id });
    setCancelled(!cancelled);
  };

  function idHandler(contracts) {
    let data = [];
    contracts.map((item) => {
      data.push({
        value: item.contractId,
        label:
          item.contractType === "service"
            ? "Xidməti yazı"
            : item.contractType === "order"
            ? "Əmr"
            : "",
      });
    });
    setReqId([...data]);
  }

  const columns = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      renderCell: (row, col) => {
        const operators = [
          // new Operator({
          //   icon: <RemoveRedEye />,
          //   hideCondition: false,
          //   onClick: () => {
          //     setShowReqDialog(true);
          //     idHandler(row.originalRequest.contracts);
          //   },
          // }),

          new Operator({
            icon: <Close />,
            hideCondition: !row.isCancellable,
            onClick: () => {
              cancelledButton(row);
            },
          }),
        ];
        return (
          <>
            <DocumentDialog
              documentId={row._id}
              disableSigning={true}
              fetchHandler={getEmployeeRequestManager}
              refreshPage={paginationFetchingHandler}
            />
            <OperatorGenerator operators={operators} />
          </>
        );
      },
    }),
    new Column({
      path: "requestNO",
      headerName: "Sorğu nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),

    new Column({
      path: "originalRequest.structureId.organization.name",
      headerName: "Dəyişiklik edilən struktur vahidi",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterColumnKey: "structureId._id",
      fetchFilterValues: getAllStructureRequestsNames,
    }),
    new Column({
      path: "createdAt",
      headerName: "Sorğu tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) =>
        normalizeDate(getDescendantProp(row, column.path)),
    }),
    new Column({
      path: "originalRequest.creatorId.firstname",
      headerName: "Sorğunu yaradanın adı",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => getDescendantProp(row, column.path),
    }),
    new Column({
      path: "originalRequest.creatorId.lastname",
      headerName: "Sorğunu yaradanın soyadı",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => getDescendantProp(row, column.path),
    }),
    new Column({
      path: "originalRequest.structureId.recruiterStaff.employee.firstname",
      headerName: "İşə götürənin adı",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => getDescendantProp(row, column.path, true),
    }),
    new Column({
      path: "originalRequest.structureId.recruiterStaff.employee.lastname",
      headerName: "İşə götürənin soyadı",
      filterType: OPERATORS_CONTAINING.TEXT,
      getValue: (row, column) => {
        return getDescendantProp(row, column.path, true);
      },
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: STRUCTURE_CHGANGE_REQUEST_FOR_FILTER,
      renderCell: (row, column) => {
        const approvers = [];
        if (
          row.status === "pendingHrSign" ||
          row.status === "pendingHrChanges"
        ) {
          approvers.push({
            index: 1,
            employee:
              row.originalRequest.structureId?.recruiterStaff?.employee[0] &&
              `${row?.originalRequest?.creatorId?.firstname} ${row?.originalRequest?.creatorId?.lastname}`,
            status: "pending",
          });
        } else if (row.status === "cancelledByHR") {
          approvers.push({
            index: 1,
            employee:
              row.originalRequest.structureId?.recruiterStaff?.employee[0] &&
              `${row?.originalRequest?.creatorId?.firstname} ${row?.originalRequest?.creatorId?.lastname}`,
            status: "rejected",
          });
        } else if (row.status === "rejectedByRecruiter") {
          approvers.push({
            index: 1,
            employee:
              row.originalRequest.structureId?.recruiterStaff?.employee[0] &&
              `${row?.originalRequest?.creatorId?.firstname} ${row?.originalRequest?.creatorId?.lastname}`,
            status: "rejected",
          });
        } else if (
          row.status === "pendingRecruiterApprove" ||
          row.status === "pendingRecruiterSign" ||
          row.status === "pendingChainApprove"
        ) {
          approvers.push({
            index: 1,
            employee:
              row.originalRequest.structureId?.recruiterStaff?.employee[0] &&
              `${row?.originalRequest?.creatorId?.firstname} ${row?.originalRequest?.creatorId?.lastname}`,
            status: "pending",
          });
        } else if (row.status === "completed" || row.status === "approved") {
          approvers.push({
            index: 1,
            employee:
              row.originalRequest.structureId?.recruiterStaff?.employee[0] &&
              `${row?.originalRequest?.creatorId?.firstname} ${row?.originalRequest?.creatorId?.lastname}`,
            status: "approved",
          });
        }
        return (
          <StatusGenerator
            approvement={approvers}
            value={getDescendantProp(row, column.path)}
            docType={"structureChangeRequest"}
          />
        );
      },
    }),
  ];

  return (
    <>
      <Helmet title="Əməkdaş əlavə et" />
      <Typography variant="h3" gutterBottom display="inline">
        Struktur dəyişikliyi sorğuları
      </Typography>
      <ToastContainer />
      <Divider my={6} sx={{ marginTop: "20px" }} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid sx={{ width: "100%", marginTop: "50px" }}>
            <Table
              columns={columns}
              data={rowData}
              totalRows={totalRow}
              offset={offset}
              setOffset={(value) => setOffset(value)}
              limit={limit}
              setLimit={(value) => setLimit(value)}
              isLoading={isLoading}
              applyFiltersHandler={(filters) => setFilters(filters)}
              cancelAppliedFilters={() => setFilters([])}
              downloadHandler={(cols) => downlaodHandler(cols)}
            />
            {/* {reqId.length > 0 && (
              <DocumentDialog
                open={showReqDialog}
                documentId={reqId}
                onClose={() => {
                  setReqId("");
                  setShowReqDialog(false);
                }}
                navList={[...reqId]}
              />
            )} */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
