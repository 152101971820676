import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Bar } from "react-chartjs-2";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { getPeriodicVacationRequests } from "../../../api/vacationRequests/vacationRequests";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 200px;
  width: 100%;
`;

const MonthlyRequestsBar = ({ theme, id }) => {
  const todaysDate = new Date();
  const periodType = "month";
  const startYear = todaysDate.getFullYear();
  const requestType = "VacationRequest";

  const getPeriodic = async () => {
    try {
      if (id) {
        const response = await getPeriodicVacationRequests(
          id,
          startYear,
          periodType,
          requestType
        );
        setPeriodicData(response?.data.periodicEmployeeRequestCount);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const [periodicData, setPeriodicData] = useState([]);
  useEffect(() => {
    getPeriodic();
  }, [id]);
  const data = {
    labels: [
      "Yan",
      "Fev",
      "Mart",
      "Apr",
      "May",
      "Iyun",
      "Iyul",
      "Avq",
      "Sen",
      "Okt",
      "Noy",
      "Dek",
    ],
    datasets: [
      {
        label: "Aylar üzrə məzuniyyət sorğuları",
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        hoverBackgroundColor: theme.palette.secondary.main,
        hoverBorderColor: theme.palette.secondary.main,
        data: periodicData,
        barPercentage: 0.75,
        categoryPercentage: 0.5,
      },
      // {
      //   label: "This year",
      //   backgroundColor: theme.palette.grey[300],
      //   borderColor: theme.palette.grey[300],
      //   hoverBackgroundColor: theme.palette.grey[300],
      //   hoverBorderColor: theme.palette.grey[300],
      //   data: periodicData,
      //   barPercentage: 0.75,
      //   categoryPercentage: 0.5,
      // },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: false,
        ticks: {
          stepSize: 20,
        },
      },

      x: {
        stacked: false,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Aylar üzrə məzuniyyət sorğuları və ƏQMİ
        </Typography>

        <Spacer mb={6} />

        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(MonthlyRequestsBar);
