// React
import { useEffect, useState } from "react";

// Material UI
import { Grid } from "@mui/material";

import { getOrdersContracts } from "../../../services/employeeApplication(employee)/EmployeeApplicationService";
import DocumentDialog from "../../../components/dialogs/documentDialog/DocumentDialog";

import Column from "../../../models/table/Column";
import Table from "../../../components/table/Table";
import {
  getDescendantProp,
  normalizeDate,
} from "../../../utils/helperFunctions";

export default function OrdersTable() {
  const [totalRow, setTotalRow] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowID, setRowID] = useState();
  const [filters, setFilters] = useState([]);

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const handlePaginationChange = (pageSize, table) => {
    if (!pageSize) return;
    const value = pageSize.page * pageSize.pageSize;
    setOffset(value);
    setLimit(parseInt(pageSize.pageSize));
  };
  useEffect(() => {
    const getResponse = async () => {
      try {
        setIsLoading(true);
        const { data } = await getOrdersContracts({
          limit: limit || 5,
          offset: offset || 0,
          contractType: "order",
        });
        setTotalRow(data.total);
        setRowData(
          data.contracts.map((item, index) => {
            return {
              ...item,
              id: item._id,
              orderno: item.data.docNumber?.value,
              date:
                item.status === "approved"
                  ? normalizeDate(item.verifiers[0].date)
                  : "",
              content: item.contractType.name,
              name: item.verifiers[0]?.employee.firstname || "",
              surname: item.verifiers[0]?.employee.lastname || "",
              fathername: item.verifiers[0]?.employee.fathername || "",
              status: item.status,
            };
          })
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };

    getResponse();
  }, [limit, offset]);

  const columns = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
    }),
    new Column({
      path: "orderno",
      headerName: "Əmr no",
    }),
    new Column({
      path: "date",
      headerName: "Tarix",
      renderCell: (row, col) => {
        return normalizeDate(row.date);
      },
    }),
    new Column({
      path: "content",
      headerName: "Məzmun",
    }),
    new Column({
      path: "name",
      headerName: "Ad",
    }),
    new Column({
      path: "surname",
      headerName: "Soyad",
    }),
    new Column({
      path: "fathername",
      headerName: "Ata adı",
    }),
    new Column({
      path: "status",
      headerName: "Status",
    }),
  ];

  return (
    <>
      <Grid sx={{ width: "100%" }}>
        <Table
          columns={columns}
          data={rowData}
          totalRows={totalRow}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
        />
      </Grid>
      {rowID && (
        <DocumentDialog
          onClose={handleCloseDialog}
          open={openDialog}
          id={rowID}
          disableSigning={true}
        />
      )}
    </>
  );
}
