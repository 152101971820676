import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Column from "../../models/table/Column";
import {
  BUSINESS_TRIP_STATUS_FOR_FILTER,
  isForeignCountry,
  OPERATORS_CONTAINING,
} from "../../constants";
import BusinessTripForm from "./BusinessTripForm";
import {
  getBusinessTrip,
  getBusinessTripDocument,
  getBusinessTripDownload,
} from "./services";
import moment from "moment";
import { getStaticFilterValues } from "../../utils/forFilterDropdown/getStructureUtils";
import Operator from "../../models/table/Operator";
import { Close, Edit, RemoveRedEye } from "@mui/icons-material";
import OperatorGenerator from "../../components/table/OperatorGenerator";
import {
  CancelledBusinessTripDialog,
  EditBusinessTripDialog,
  ViewBusinessTripDialog,
} from "./BusinessTripDialog";
import StatusGenerator from "../../components/table/StatusGenerator";
import { getDescendantProp } from "../../utils/helperFunctions";
import { downloadFile } from "../../utils/downloadCSV";

const BusinessTrip = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const [tripLoading, setTripLoading] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [cancelRowId, setCancelRowId] = useState();
  const [data, setData] = useState([]);
  const [editdata, setEditData] = useState([]);
  const [tripData, setTripData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [total, setTotal] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);

  const getBusinessTripData = async (filters) => {
    if (!filters) {
      filters = [];
    }
    setLoading(true);
    try {
      const res = await getBusinessTrip(filters, limit, offset);
      setData(res?.data?.data);
      setTotal(res?.data?.total);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  const getDocument = async () => {
    setTripLoading(true);
    try {
      if (selectedId) {
        const res = await getBusinessTripDocument(selectedId);
        setTripData(res.data);
      }
    } catch (err) {
      console.log(err);
    }
    setTripLoading(false);
  };
  useEffect(() => {
    getBusinessTripData(filters);
  }, [filters, offset, limit]);
  useEffect(() => {
    getDocument();
  }, [selectedId]);
  const operatorColumnGenerator = (row, column) => {
    const operators = [
      new Operator({
        icon: <RemoveRedEye />,
        onClick: () => handleOpenDialog(row),
        type: 0,
      }),
      row?.status === "awaiting-signature" &&
        new Operator({
          icon: <Edit />,
          onClick: () => handleEdit(row),
          type: 0,
        }),
      row?.status === "signed-and-planned" &&
        new Operator({
          icon: <Close />,
          onClick: () => handleCancelled(row?._id),
          type: 0,
        }),
    ];

    return <OperatorGenerator operators={operators} />;
  };
  const handleOpenDialog = (row) => {
    setSelectedId(row._id);
    setShowModal(true);
  };
  async function handleCancelled(row) {
    setCancelDialog(true);
    setCancelRowId(row);
  }
  async function handleEdit(row) {
    setEditShowModal(true);
    setEditData(row);
  }
  const handleCloseDialog = () => {
    setSelectedId("");
    setShowModal(false);
  };
  const downloadHandler = async (columnsForExport) => {
    try {
      const forExport = [];
      const isWithFilter =
        filters.length > 0 && filters[0]?.column?.length > 0 ? true : false;
      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;

        forExport.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));

      const resp = await getBusinessTripDownload({
        isWithFilter: isWithFilter,
        columns: encodedColumns,
        filter: encodedFilter,
      });

      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };
  const column = [
    new Column({
      headerName: "Operator",
      disableColSearch: true,
      path: "#",
      renderCell: operatorColumnGenerator,
    }),
    new Column({
      path: "employee.tableNumber",
      headerName: "Tabel Nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.firstname",
      headerName: "Ad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.lastname",
      headerName: "Soyad",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "employee.fathername",
      headerName: "Ata adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "contract.data.docNumber",
      headerName: "Ə.M. Müqaviləsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "baseStructure.organization.name",
      headerName: "Struktur",
      filterType: OPERATORS_CONTAINING.IN,
      filterColumnKey: "baseStructure",
      dataGridName: "business-trip",
      fetchFilterValuesV2: getStaticFilterValues,
      keyForResponseDataFromFilter: "baseStructures",
    }),
    new Column({
      path: "subStructure.source.name",
      headerName: "Sub-struktur",
      dataGridName: "business-trip",
      filterColumnKey: "subStructure",
      fetchFilterValuesV2: getStaticFilterValues,
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: "subStructures",
      filterType: OPERATORS_CONTAINING.IN,
    }),
    new Column({
      path: "position.source.name",
      headerName: "Vəzifə",
      dataGridName: "business-trip",
      filterColumnKey: "position",
      fetchFilterValuesV2: getStaticFilterValues,
      dependencieKeysOfFilter: {
        structures: ["baseStructure._id"],
        topPartOfStructures: ["topPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: "positions",
      filterType: OPERATORS_CONTAINING.IN,
    }),
    new Column({
      path: "substituteEmployee.tableNumber",
      headerName: "Əvəz Edənin Tabel Nömrəsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteEmployee.firstname",
      headerName: "Əvəz Edənin Adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteEmployee.lastname",
      headerName: "Əvəz Edənin Soyadı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteEmployee.fathername",
      headerName: "Əvəz Edənin Atasının Adı",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteContract.data.docNumber",
      headerName: "Əvəz Edənin Ə.M. Müqaviləsi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "substituteBaseStructure.organization.name",
      headerName: "Əvəz Edənin işlədiyi Struktur",
      filterType: OPERATORS_CONTAINING.IN,
      filterColumnKey: "substituteBaseStructure",
      dataGridName: "business-trip",
      fetchFilterValuesV2: getStaticFilterValues,
      keyForResponseDataFromFilter: "baseStructures",
    }),
    new Column({
      path: "substituteSubStructure.source.name",
      headerName: "Əvəz Edənin işlədiyi Sub-struktur",
      dataGridName: "business-trip",
      filterColumnKey: "substituteSubStructure",
      fetchFilterValuesV2: getStaticFilterValues,
      dependencieKeysOfFilter: {
        structures: ["substituteBaseStructure._id"],
        topPartOfStructures: ["substituteTopPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: "subStructures",
      filterType: OPERATORS_CONTAINING.IN,
    }),
    new Column({
      path: "substitutePosition.source.name",
      headerName: "Əvəz Edənin Vəzifəsi",
      dataGridName: "business-trip",
      filterColumnKey: "substitutePosition",
      fetchFilterValuesV2: getStaticFilterValues,
      dependencieKeysOfFilter: {
        structures: ["substituteBaseStructure._id"],
        topPartOfStructures: ["substituteTopPartOfStructure._id"],
      },
      keyForResponseDataFromFilter: "positions",
      filterType: OPERATORS_CONTAINING.IN,
    }),
    new Column({
      path: "tripReason",
      headerName: "Ezamiyyət Səbəbi",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "isForeignCountry",
      headerName: "Ölkədaxili / Xarici",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: isForeignCountry,
      renderCell: (row, column) =>
        row?.isForeignCountry ? (
          <div className="forCenter">Xarici</div>
        ) : (
          <div className="forCenter">Ölkədaxili</div>
        ),
    }),
    new Column({
      path: "businessTripLocation",
      headerName: "Ezam Olduğu Yer",
      filterType: OPERATORS_CONTAINING.TEXT,
      renderCell: (row, column) => {
        if (row?.foreignTripDetails.length > 0) {
          return (
            <>
              {row.foreignTripDetails.map((item, index) => {
                const city =
                  item?.cities?.length > 0 ? item?.cities?.[0]?.name : null;
                return (
                  <div
                    className="forCenter"
                    style={{ flexDirection: "column" }}
                    key={index}
                  >
                    <p style={{ textAlign: "center" }}>{item.name}</p>
                    {city && <p style={{ textAlign: "center" }}>{city}</p>}
                  </div>
                );
              })}
            </>
          );
        }
        if (row?.domesticTripDetails.length > 0) {
          const city = row?.domesticTripDetails?.[0]?.location;
          return <div className="forCenter">{city}</div>;
        }
        return null;
      },
    }),
    new Column({
      path: "tripStartDate",
      headerName: "Ezamiyyə Başalama Tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row, column) =>
        row?.tripStartDate ? (
          <div className="forCenter">
            {moment(row?.tripStartDate).format("DD/MM/YYYY")}
          </div>
        ) : null,
    }),
    new Column({
      path: "tripEndDate",
      headerName: "Ezamiyyə Bitmə Tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row, column) =>
        row?.tripEndDate ? (
          <div className="forCenter">
            {moment(row?.tripEndDate).format("DD/MM/YYYY")}
          </div>
        ) : null,
    }),
    new Column({
      path: "returnToWorkDate",
      headerName: "İşə çıxma Tarixi",
      filterType: OPERATORS_CONTAINING.DATE,
      renderCell: (row, column) =>
        row?.returnToWorkDate ? (
          <div className="forCenter">
            {moment(row?.returnToWorkDate).format("DD/MM/YYYY")}
          </div>
        ) : null,
    }),
    new Column({
      path: "tripDuration",
      headerName: "Ezamiyyə müddəti (gün)",
      filterType: OPERATORS_CONTAINING.SINGLE_NUMBER,
    }),
    new Column({
      path: "status",
      headerName: "Status",
      filterValues: BUSINESS_TRIP_STATUS_FOR_FILTER,
      filterType: OPERATORS_CONTAINING.OPTION,
      renderCell: (row, column) => {
        const approvers = [];
        let status = "";
        if (row?.status === "awaiting-signature") {
          status = "pending";
        } else if (row?.status === "signed-and-planned") {
          status = "approved";
        } else if (row?.status === "cancelled") {
          status = "rejected";
        } else if (row?.status === "not-signed") {
          status = "notSigned";
        } else {
          status = row.status;
        }

        const fullName = `${row?.employee?.firstname} ${row?.employee?.lastname}`;
        approvers.push({
          index: 1,
          employee: fullName,
          status: status,
        });
        return (
          <StatusGenerator
            // isVerified={isVerified}
            data={row}
            approvement={approvers}
            value={getDescendantProp(row, column.path)}
            docType={"businessTrip"}
          />
        );
      },
    }),
  ];
  return (
    <div className="business_trip_main">
      <Typography variant="h3" gutterBottom display="inline">
        Ezamiyyətlər
      </Typography>
      <BusinessTripForm getBusinessTripData={getBusinessTripData} />
      <Table
        data={data}
        limit={limit}
        offset={offset}
        totalRows={total}
        columns={column}
        isLoading={loading}
        setLimit={(value) => setLimit(value)}
        setOffset={(value) => setOffset(value)}
        cancelAppliedFilters={() => setFilters([])}
        applyFiltersHandler={(filters) => setFilters(filters)}
        downloadHandler={(column) => downloadHandler(column)}
      />
      {showModal && (
        <ViewBusinessTripDialog
          data={tripData}
          open={showModal}
          loading={tripLoading}
          close={handleCloseDialog}
        />
      )}
      {editShowModal && (
        <EditBusinessTripDialog
          data={editdata}
          open={editShowModal}
          close={setEditShowModal}
          getData={getBusinessTripData}
        />
      )}
      <CancelledBusinessTripDialog
        id={cancelRowId}
        open={cancelDialog}
        close={setCancelDialog}
        getData={getBusinessTripData}
      />
    </div>
  );
};

export default BusinessTrip;
