import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader,
  Paper,
  Typography,
  Grid,
  Autocomplete,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useMemo } from "react";
import BusinessTripSkeleton from "./BusinessTripSkeleton";
import { updateStatusBusinessTrip } from "./services";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers";
import {
  useBusinessTrip,
  useEditBusinessTrip,
} from "../../hooks/useBusinessTrip";

const DetailField = ({ label, value, width = "25%" }) => (
  <TextField
    sx={{ marginBottom: 2, marginTop: 2, width }}
    label={label}
    value={value || ""}
    InputProps={{ readOnly: true }}
  />
);

export const ViewBusinessTripDialog = ({ data, open, close, loading }) => {
  const businessTripData = useMemo(() => {
    const entry = data?.businessTripEntry || {};
    const employee = entry.employee || {};
    const substitute = entry.substituteEmployee || {};
    const contract = entry.contract?.data || {};
    const substituteContract = entry.substituteContract?.data || {};
    const foreignDetails = entry.foreignTripDetails?.[0] || {};
    const domesticDetails = entry.domesticTripDetails?.[0] || {};

    return {
      fullName: `${employee.firstname} ${employee.lastname} ${employee.fathername}`,
      substituteFullName: `${substitute.firstname} ${substitute.lastname} ${substitute.fathername}`,
      tabelNo: employee.tableNumber,
      substituteTabelNo: substitute.tableNumber,
      docNo: contract.docNumber,
      substituteContractNo: substituteContract.docNumber,
      position: entry.position?.source?.name,
      substitutePosition: entry.substitutePosition?.source?.name,
      tripReason: entry.tripReason,
      tripDetails: entry.isForeignCountry
        ? `${foreignDetails.name} ${foreignDetails.cities?.[0]?.name || ""}`
        : domesticDetails.location,
      tripStartDate: moment(entry.tripStartDate).format("DD/MM/YYYY"),
      tripEndDate: moment(entry.tripEndDate).format("DD/MM/YYYY"),
      returnToWorkDate: moment(entry.returnToWorkDate).format("DD/MM/YYYY"),
      tripDuration: entry.tripDuration,
    };
  }, [data]);

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxWidth: "unset",
          height: "400px",
        },
      }}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {loading ? (
        <BusinessTripSkeleton />
      ) : (
        <>
          <DialogTitle
            sx={{ fontSize: "24px", textAlign: "center" }}
            id="alert-dialog-title"
          >
            Ezamiyyət haqqında ətraflı məlumat
          </DialogTitle>
          <DialogContent>
            <form style={{ pointerEvents: "none" }}>
              <div style={{ display: "flex", gap: "8px" }}>
                <DetailField
                  label="Əməkdaş (A.S.A)"
                  value={businessTripData.fullName}
                />
                <DetailField
                  label="Tabel No"
                  value={businessTripData.tabelNo}
                />
                <DetailField
                  label="Müqavilə nömrəsi"
                  value={businessTripData.docNo}
                />
                <DetailField label="Vəzifə" value={businessTripData.position} />
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <DetailField
                  label="Əvəz Edən Əməkdaş (A.S.A)"
                  value={businessTripData.substituteFullName}
                />
                <DetailField
                  label="Əvəz Edənin Tabel No"
                  value={businessTripData.substituteTabelNo}
                />
                <DetailField
                  label="Əvəz Edənin Müqavilə nömrəsi"
                  value={businessTripData.substituteContractNo}
                />
                <DetailField
                  label="Əvəz Edənin Vəzifə"
                  value={businessTripData.substitutePosition}
                />
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <DetailField
                  label="Ezamiyyət Səbəbi"
                  value={businessTripData.tripReason}
                  width="50%"
                />
                <DetailField
                  label={
                    data?.businessTripEntry?.isForeignCountry
                      ? "Ezam Olduğu Ölkə"
                      : "Ezam Olduğu Yer (Öklədaxili)"
                  }
                  value={businessTripData.tripDetails}
                  width="50%"
                />
              </div>
              <div style={{ display: "flex", gap: "8px" }}>
                <DetailField
                  label="Ezamiyyə Başlama Tarixi"
                  value={businessTripData.tripStartDate}
                />
                <DetailField
                  label="Ezamiyyə Bitmə Tarixi"
                  value={businessTripData.tripEndDate}
                />
                <DetailField
                  label="İşə çıxma Tarixi"
                  value={businessTripData.returnToWorkDate}
                />
                <DetailField
                  label="Ezamiyyə müddəti (gün)"
                  value={businessTripData.tripDuration}
                />
              </div>
            </form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
export const EditBusinessTripDialog = ({ data, getData, open, close }) => {
  const { empData, country, leave_state } = useBusinessTrip();
  const {
    loading,
    edit_state,
    handleSubmit,
    calcTripDuration,
    getDateList,
    shouldDisableDate,
    disabledReasonMessage,
  } = useEditBusinessTrip(data, getData, close);

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxWidth: "unset",
          height: "500px",
        },
      }}
      onClose={() => close(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {loading ? (
        <BusinessTripSkeleton />
      ) : (
        <>
          {" "}
          <DialogTitle
            sx={{ fontSize: "24px", textAlign: "center" }}
            id="alert-dialog-title"
          >
            Ezamiyyət dəyişiklik
          </DialogTitle>
          <DialogContent>
            <Formik initialValues={edit_state} onSubmit={handleSubmit}>
              {({ values, isSubmitting, setFieldValue }) => (
                <>
                  <Form>
                    <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 3 }}>
                      <Grid className="w-25">
                        <FormControl fullWidth>
                          <Field name="employeeId">
                            {({ field, form }) => (
                              <Autocomplete
                                options={empData || []}
                                getOptionLabel={(option) =>
                                  `${option?.firstname || ""} ${
                                    option?.lastname || ""
                                  } ${option?.fathername || ""}`
                                }
                                value={
                                  empData?.find(
                                    (emp) => emp.id === form.values.employeeId
                                  ) || null
                                }
                                onChange={(event, selectedEmp) => {
                                  form.setFieldValue(
                                    "employeeId",
                                    selectedEmp?.id || ""
                                  );
                                  form.setFieldValue("staffId", "");
                                  form.setFieldValue("positionName", "");
                                  form.setFieldValue(
                                    "empTabelNo",
                                    selectedEmp?.tableNumber || ""
                                  );
                                  getDateList({
                                    ...form.values,
                                    employeeId: selectedEmp?.id || "",
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Əməkdaş (A.S.A)"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid className="w-25 pe-none">
                        <Field
                          as={TextField}
                          defaultValue=""
                          fullWidth
                          labelId="empTabelNo"
                          id="empTabelNo"
                          name="empTabelNo"
                          label="Tabel No"
                        />
                      </Grid>
                      <Grid className="w-25">
                        <FormControl fullWidth>
                          <InputLabel id="staffId">Müqavilə nömrəsi</InputLabel>
                          <Field
                            as={Select}
                            defaultValue=""
                            labelId="staffId"
                            id="staffId"
                            name="staffId"
                            label="Müqavilə nömrəsi"
                            onChange={(e) => {
                              const { value } = e.target;
                              const selectedEmp = empData?.find(
                                (emp) => emp?._id === values?.employeeId
                              );
                              const selectedStaff = selectedEmp?.staffs?.find(
                                (staff) => staff?._id === value
                              );

                              setFieldValue("staffId", value);
                              setFieldValue(
                                "positionName",
                                selectedStaff?.position?.name || ""
                              );
                            }}
                          >
                            {empData?.length > 0 &&
                              empData
                                .filter((e) => e?._id === values?.employeeId)
                                .flatMap((e) =>
                                  e.staffs.map((item) => (
                                    <MenuItem key={item?._id} value={item?._id}>
                                      {item?.contract?.data?.docNumber}
                                    </MenuItem>
                                  ))
                                )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid className="w-25 pe-none">
                        <Field
                          as={TextField}
                          fullWidth
                          defaultValue=""
                          labelId="positionName"
                          id="positionName"
                          name="positionName"
                          label="Vəzifə"
                        />
                      </Grid>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 3 }}>
                      <Grid className="w-25">
                        <FormControl fullWidth>
                          <Field name="substituteId">
                            {({ field, form }) => (
                              <Autocomplete
                                options={
                                  empData?.filter(
                                    (e) => e?._id !== form.values.employeeId
                                  ) || []
                                }
                                getOptionLabel={(option) =>
                                  `${option?.firstname || ""} ${
                                    option?.lastname || ""
                                  } ${option?.fathername || ""}`
                                }
                                value={
                                  empData?.find(
                                    (emp) =>
                                      emp._id === form.values.substituteId
                                  ) || null
                                }
                                onChange={(event, selectedEmp) => {
                                  form.setFieldValue(
                                    "substituteId",
                                    selectedEmp?._id || ""
                                  );
                                  form.setFieldValue("substituteStaffId", "");
                                  form.setFieldValue(
                                    "substitutePositionName",
                                    ""
                                  );
                                  form.setFieldValue(
                                    "substituteTabelNo",
                                    selectedEmp?.tableNumber || ""
                                  );
                                  getDateList({
                                    ...form.values,
                                    substituteId: selectedEmp?._id || "",
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Əvəz Edən Əməkdaş (A.S.A)"
                                    placeholder="Select a substitute"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid className="w-25 pe-none">
                        <Field
                          as={TextField}
                          fullWidth
                          defaultValue=""
                          labelId="substituteTabelNo"
                          id="substituteTabelNo"
                          name="substituteTabelNo"
                          label="Əvəz Edənin Tabel No"
                          onChange={(e) => {}}
                        />
                      </Grid>
                      <Grid className="w-25">
                        <FormControl fullWidth>
                          <InputLabel id="substituteStaffId">
                            Əvəz Edənin Müqavilə nömrəsi
                          </InputLabel>
                          <Field
                            as={Select}
                            defaultValue=""
                            labelId="substituteStaffId"
                            id="substituteStaffId"
                            name="substituteStaffId"
                            label="Əvəz Edənin Müqavilə nömrəsi"
                            onChange={(e) => {
                              const { value } = e.target;
                              const selectedEmp = empData?.find(
                                (emp) => emp?._id === values?.substituteId
                              );
                              const selectedStaff = selectedEmp?.staffs?.find(
                                (staff) => staff?._id === value
                              );
                              setFieldValue("substituteStaffId", value);
                              setFieldValue(
                                "substitutePositionName",
                                selectedStaff?.position?.name || ""
                              );
                            }}
                          >
                            {empData?.length > 0 &&
                              empData
                                .filter((e) => e?._id === values?.substituteId)
                                .flatMap((e) =>
                                  e.staffs.map((item) => (
                                    <MenuItem key={item?._id} value={item?._id}>
                                      {item?.contract?.data?.docNumber}
                                    </MenuItem>
                                  ))
                                )}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid className="w-25 pe-none">
                        <Field
                          as={TextField}
                          fullWidth
                          defaultValue=""
                          labelId="substitutePositionName"
                          id="substitutePositionName"
                          name="substitutePositionName"
                          label="Əvəz Edənin Vəzifə"
                        />
                      </Grid>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 3 }}>
                      <Grid className="w-25">
                        <Field
                          as={TextField}
                          fullWidth
                          defaultValue=""
                          labelId="tripReason"
                          id="tripReason"
                          name="tripReason"
                          label="Ezamiyyət Səbəbi"
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("tripReason", value);
                          }}
                        />
                      </Grid>
                      {values?.isChecked ? (
                        <Grid className="w-25">
                          <FormControl fullWidth>
                            <InputLabel id="foreignTripDetails">
                              Ezam Olduğu Ölkə
                            </InputLabel>
                            <Field
                              as={Select}
                              labelId="foreignTripDetails"
                              id="foreignTripDetails"
                              name="foreignTripDetails"
                              label="Ezam Olduğu Ölkə"
                              value={
                                values?.foreignTripDetails?.cities?.length > 0
                                  ? values.foreignTripDetails.cities[0].name
                                  : values.foreignTripDetails?.name
                              }
                              onChange={(e) => {
                                const { value } = e.target;
                                const selectedCountry = country.find(
                                  (c) => c.name === value
                                );
                                const selectedCity = country
                                  .flatMap((c) => c.cities)
                                  .find((city) => city.name === value);

                                if (selectedCountry) {
                                  setFieldValue("foreignTripDetails", {
                                    name: selectedCountry.name,
                                    cities: [],
                                  });
                                } else if (selectedCity) {
                                  const parentCountry = country.find((c) =>
                                    c.cities.some(
                                      (city) => city.name === selectedCity.name
                                    )
                                  );

                                  setFieldValue("foreignTripDetails", {
                                    name: parentCountry.name,
                                    cities: [{ name: selectedCity.name }],
                                  });
                                }
                              }}
                            >
                              {country?.length > 0 &&
                                country.map((e, index) => [
                                  <ListSubheader key={`header-${index}`}>
                                    {index + 1}. {e?.name}
                                  </ListSubheader>,
                                  <MenuItem
                                    key={`item-${index}`}
                                    value={e?.name}
                                  >
                                    {e?.name}
                                  </MenuItem>,
                                  ...(e?.cities || []).map(
                                    (city, cityIndex) => (
                                      <MenuItem
                                        key={`city-${index}-${cityIndex}`}
                                        value={city?.name}
                                      >
                                        {city?.name}
                                      </MenuItem>
                                    )
                                  ),
                                ])}
                            </Field>
                          </FormControl>
                        </Grid>
                      ) : (
                        <Grid className="w-25">
                          <FormControl fullWidth>
                            <InputLabel id="withinLocation">
                              Ezam Olduğu Yer (Öklədaxili)
                            </InputLabel>
                            <Field
                              as={Select}
                              defaultValue=""
                              labelId="withinLocation"
                              id="withinLocation"
                              name="withinLocation"
                              label="Ezam Olduğu Yer (Öklədaxili)"
                              onChange={(e) => {
                                const { value } = e.target;
                                setFieldValue("withinLocation", value);
                              }}
                            >
                              {leave_state?.withinCounty &&
                                leave_state?.withinCounty?.map((e) => (
                                  <MenuItem value={e?.label}>
                                    {e?.label}
                                  </MenuItem>
                                ))}
                            </Field>
                          </FormControl>
                        </Grid>
                      )}

                      <Grid className="w-25">
                        <Field
                          as={Checkbox}
                          fullWidth
                          defaultValue=""
                          labelId="isChecked"
                          id="isChecked"
                          name="isChecked"
                          checked={values.isChecked}
                          onChange={(e) => {
                            const { checked } = e.target;
                            setFieldValue("isChecked", checked);
                          }}
                        />
                      </Grid>
                      <Grid className="w-25 hidden"></Grid>
                    </Grid>
                    <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 3 }}>
                      <Grid className="w-25">
                        <Field name={`tripStartDate`}>
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="Ezamiyyə Başalama Tarixi"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              minDate={new Date()}
                              shouldDisableDate={shouldDisableDate}
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue(
                                    "tripStartDate",
                                    date,
                                    false
                                  );
                                  calcTripDuration(
                                    {
                                      ...form.values,
                                      tripStartDate: date,
                                    },
                                    form.setFieldValue
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid className="w-25">
                        <Field name={`tripEndDate`}>
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="Ezamiyyə Bitmə Tarixi"
                              inputFormat="dd/MM/yyyy"
                              minDate={values?.tripStartDate}
                              value={field.value || null}
                              shouldDisableDate={shouldDisableDate}
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue(
                                    "tripEndDate",
                                    date,
                                    false
                                  );
                                  calcTripDuration(
                                    {
                                      ...form.values,
                                      tripEndDate: date,
                                    },
                                    form.setFieldValue
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid className="w-25">
                        <Field name={`returnToWorkDate`}>
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="İşə çıxma Tarixi"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              minDate={values?.tripEndDate}
                              shouldDisableDate={shouldDisableDate}
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue(
                                    "returnToWorkDate",
                                    date,
                                    false
                                  );
                                  calcTripDuration(
                                    {
                                      ...form.values,
                                      returnToWorkDate: date,
                                    },
                                    form.setFieldValue
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid className="w-25 pe-none">
                        <Field
                          as={TextField}
                          defaultValue=""
                          fullWidth
                          labelId="tripDuration"
                          id="tripDuration"
                          name="tripDuration"
                          label="Ezamiyyə müddəti (gün)"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 6,
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        Dəyişiklik et
                      </Button>
                    </Grid>
                    {disabledReasonMessage?.length > 0 && (
                      <div className="d-flex flex-column w-100 gap-3">
                        {disabledReasonMessage &&
                          disabledReasonMessage.map((item, index) => {
                            return (
                              <Paper elevation={3} sx={{ p: 2 }}>
                                <Typography
                                  sx={{ color: "#ff9800" }}
                                  key={`${item?.az}-${index}`}
                                >
                                  {item?.az}
                                </Typography>
                              </Paper>
                            );
                          })}
                      </div>
                    )}
                  </Form>
                </>
              )}
            </Formik>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
export const CancelledBusinessTripDialog = ({ id, getData, open, close }) => {
  const handleCancelled = async (id) => {
    try {
      await updateStatusBusinessTrip(id);
      getData();
      toast.success("Uğurla yeniləndi", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (err) {
      err.response.data.errors.map((item) => {
        return toast.error(item.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => close(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle sx={{ textAlign: "center" }} id="form-dialog-title">
        Ləğv etmək istədiyinizdən əminsiniz ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ textAlign: "center" }}
          id="alert-dialog-description"
        >
          Bu əməliyyata davam etsəniz ezamiyyət sorğusu ləğv ediləcək!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => close(false)} color="primary">
          Ləğv et
        </Button>
        <Button
          onClick={() => {
            handleCancelled(id);
            close(false);
          }}
          color="primary"
        >
          Bəli
        </Button>
      </DialogActions>
    </Dialog>
  );
};
