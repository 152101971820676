import { api } from "../../api/axiosInterceptor";

export const getExpiringContractsFilter = async (offset = 0, limit = 10) => {
  return await api.get(
    `/employee/labour-contract/hr/expiring?offset=${offset}&limit=${limit}`
  );
};

export const getContractsWithFilter = async (
  filter,
  offset = 0,
  limit = 10
) => {
  return await api.get(
    `/employee/labour-contract/hr/filter?offset=${offset}&limit=${limit}&contractName=labour-contract`,
    { params: { filter: filter } }
  );
};

export const getContractsStatistics = async (params) => {
  return await api.get("/employee/labour-contract/hr/statistics", {
    params: params,
  });
};

export const getAllContracts = async (
  filter = undefined,
  offset = 0,
  limit = 50
) => {
  return await api.get(`/employee/labour-contract/hr`, {
    params: { filter: filter, limit: limit, offset: offset },
  });
};

export const postNotificationSettings = async (data) => {
  return await api.post(`/employee/labour-contract/hr/notification`, data);
};

export const getNotificationSettings = async () => {
  return await api.get(`/employee/labour-contract/hr/notification`);
};

export const getEmployeeNotificationList = async () => {
  return await api.get(`/employee/labour-contract/hr/notificationSubjects`);
};

export const addEmployeeNotificationList = async (data) => {
  return await api.post(
    `/employee/labour-contract/hr/notificationSubjects`,
    data
  );
};

export const putEmployeeNotificationList = async (data) => {
  return await api.put(
    `/employee/labour-contract/hr/notificationSubjects`,
    data
  );
};

export const removeEmployeeNotificationList = async (data) => {
  return await api.delete(`/employee/labour-contract/hr/notificationSubjects`, {
    data: data,
  });
};

export const downloadEmpNotificationList = async (type, columns) => {
  return await api.get(
    `/employee/labour-contract/hr/notificationSubjects/download?notificationType=${type}&columns=${columns}`
  );
};

export const downloadContFilter = async (columns, filter, isAll = false) => {
  const endpoint = !isAll
    ? `/employee/labour-contract/hr/filter/download?columns=${columns}&filter=${filter}`
    : `/employee/labour-contract/hr/filter/download?columns=${columns}&filter=%5B%5D`;

  return await api.get(endpoint, { responseType: "blob" });
};

export const downloadExpiringCont = async (columns) => {
  return await api.get(
    `/employee/labour-contract/hr/expiring/download?columns=${columns}`,
    { responseType: "blob" }
  );
};

export const getContractWithId = async (id) => {
  return await api.get(`/employee/labour-contract/hr/${id}`);
};

export const createOrderWithId = async (id) => {
  return await api.post(`/employee/contract/static/labour-order?staffId=${id}`);
};

export const previewOrderWithId = async (id) => {
  return await api.get(`/employee/contract/static/labour-order?staffId=${id}`);
};

export const sendTerminateExplanation = async (body) => {
  return await api.post(
    `/employee/employee-application/manager/LabourTerminateExplanation`,
    { ...body }
  );
};

export const sendTerminateApplication = async (body) => {
  return await api.post(
    `/employee/employee-application/manager/LabourTerminateApplication`,
    { ...body }
  );
};

export const sendTerminateDirectly = async (body, id) => {
  delete body?.labourContractId;
  return await api.post(
    `/employee/contract/static/labour-termination-order?labourContractId=${id}`,
    { ...body }
  );
};
