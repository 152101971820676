import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";

export const getSalaryHistory = async (payload) => {
  let params = {
    offset: payload?.offset,
    limit: payload?.limit,
    filters: payload?.filters,
  };

  try {
    const response = await api.get("employee/wages-calc/history", {
      params: params,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const postSalaryCalculation = async (id) => {
  try {
    return await api.post(`/employee/wages-calc/`, { structure: id });
  } catch (err) {
    console.log("postsalarycalculation", err);
  }
};
