import React, { useEffect, useState } from "react";
import TransferAnotherJob from "./transfer-another-job/TransferAnotherJob";
import EmptyPosition from "./empty-position/EmptyPosition";
import Compensation from "./compensation/compensation";
import AwardRecognition from "./award-recognition/AwardRecognition";
import { useSearchParams } from "react-router-dom";
import RecallWork from "./recall-work/RecallWork";

const StaticOrder = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const label = searchParams.get("label");
    setActiveComponent(label);
  }, [searchParams]);

  const renderComponent = (label) => {
    switch (label) {
      case "Başqa işə keçirilmə əmri":
        return <TransferAnotherJob />;
      case "Boş vəzifənin həvalə edilməsi əmri":
        return <EmptyPosition />;
      case "Əmək məzuniyyətindən geriçağırma barədə əmr":
        return <RecallWork />;
      case "Kompensasiya əmri":
        return <Compensation />;
      case "Mükafat və təltif əmri":
        return <AwardRecognition />;
      default:
        return null;
    }
  };
  return activeComponent ? renderComponent(activeComponent) : null;
};

export default StaticOrder;
