import { Skeleton } from "@mui/material";
import React from "react";

const BusinessTripSkeleton = () => {
  return (
    <div style={{ marginTop: "20px", padding: "0 20px" }}>
      {/* Header Skeleton */}
      <Skeleton
        variant="text"
        width="40%"
        height={30}
        sx={{ margin: "0 auto", marginBottom: 2 }}
      />
      {/* Form Skeleton */}
      <div style={{ display: "flex", gap: "8px" }}>
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <Skeleton
          variant="rectangular"
          width="50%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="50%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
        <Skeleton
          variant="rectangular"
          width="25%"
          height={56}
          sx={{ marginBottom: 2, borderRadius: "4px" }}
        />
      </div>
    </div>
  );
};

export default BusinessTripSkeleton;
