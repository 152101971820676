import { Autocomplete, Grid, InputLabel, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import Header from "../../components/layouts/header/Header";
import Table from "../../components/table/Table";
import { useState } from "react";
import Column from "../../models/table/Column";

const header = {
  title: "Əməkhaqqı çıxarışı",
  variant: "h1",
};

const column = [
  new Column({
    path: "#",
    headerName: "Operatorlar",
  }),
  new Column({
    path: "",
    headerName: "Ad",
  }),
  new Column({
    path: "",
    headerName: "Soyad",
  }),
  new Column({
    path: "",
    headerName: "Ata adı",
  }),
  new Column({
    path: "",
    headerName: "Təşkilat",
  }),
  new Column({
    path: "",
    headerName: "Struktur",
  }),
  new Column({
    path: "",
    headerName: "Substruktur",
  }),
  new Column({
    path: "",
    headerName: "Ümumi dinamik əlavə",
  }),
  new Column({
    path: "",
    headerName: "Ümumi statik əlavə",
  }),
  new Column({
    path: "",
    headerName: "CH",
  }),
  new Column({
    path: "",
    headerName: "Güzəşt",
  }),
  new Column({
    path: "",
    headerName: "Gəlir Vergisi",
  }),
  new Column({
    path: "",
    headerName: "Ümumi Vergi",
  }),
  new Column({
    path: "",
    headerName: "Ümumi dinamik tutulma",
  }),
  new Column({
    path: "",
    headerName: "Ümumi statik tutulma",
  }),
  new Column({
    path: "",
    headerName: "NET",
  }),
  new Column({
    path: "",
    headerName: "Status",
  }),
];

export default function SalaryStatement() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [formState, setFormState] = useState({
    organizations: "",
  });

  const [organizations, setOrganizations] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");

  return (
    <>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid>
      {/* form part */}
      <div style={{ display: "flex", columnGap: "10px" }}>
        {/* teskilat */}
        {/* <div className="w-50 ">
          <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
            Təşkilat
          </InputLabel>

          <Autocomplete
            sx={{ width: "100%" }}
            // options={employee}
            renderInput={(params) => (
              <TextField {...params} label="Təşkilatı seçin" />
            )}
            // getOptionLabel={(option) =>
            //   `${option.firstname} ${option?.lastname} ${option?.fathername}`
            // }
            inputValue={searchValue}
            // isOptionEqualToValue={(option, value) => option.id === value.id}
            // onInputChange={(event, newInputValue) => {
            //   setSearchValue(newInputValue);
            // }}
            // onChange={(e, newInputValue) => {
            //   set_leave_state({
            //     ...leave_state,
            //     employee: newInputValue?.id ? newInputValue.id : "",
            //   });
            // }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {`${option.firstname} ${option.lastname} ${option.fathername}`}
                </li>
              );
            }}
          />
        </div> */}
        {/* emekdas*/}
        {/* <div className="w-50 ">
          <InputLabel sx={{ marginBottom: "8px", color: "white" }}>
            Əməkdaş
          </InputLabel>

          <Autocomplete
            sx={{ width: "100%" }}
            // options={employee}
            renderInput={(params) => (
              <TextField {...params} label="Əməkdaş seçin" />
            )}
            // getOptionLabel={(option) =>
            //   `${option.firstname} ${option?.lastname} ${option?.fathername}`
            // }
            inputValue={searchValue}
            // isOptionEqualToValue={(option, value) => option.id === value.id}
            // onInputChange={(event, newInputValue) => {
            //   setSearchValue(newInputValue);
            // }}
            // onChange={(e, newInputValue) => {
            //   set_leave_state({
            //     ...leave_state,
            //     employee: newInputValue?.id ? newInputValue.id : "",
            //   });
            // }}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {`${option.firstname} ${option.lastname} ${option.fathername}`}
                </li>
              );
            }}
          />
        </div> */}
      </div>
      {/* tarixler */}
      {/* <div style={{ display: "flex", marginTop: "20px" }}>
        <div className="w-25 ">
          <div>Başlama tarixi</div>
          <div className="mt-2">
            <DatePicker
              inputFormat="dd/MM/yyyy"
              value={formState?.startDate || null}
              maxDate={formState?.endDate}
              onChange={(date) => {
                setFormState({
                  ...formState,
                  startDate: date,
                });
              }}
              onError={(reason, value) => {
                if (reason === "invalidDate") {
                  setErrorStartDate("Keçərsiz tarix formatı");
                } else if (reason === "maxDate") {
                  setErrorStartDate(
                    "Başlama tarixi bitmə tarixindən böyük ola bilməz"
                  );
                } else {
                  setErrorStartDate("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errorStartDate}
                  helperText={errorStartDate || ""}
                  fullWidth
                />
              )}
            />
          </div>
        </div>
        <div className="w-25 ms-2">
          <div>Bitmə tarixi</div>
          <div className="mt-2">
            <DatePicker
              onError={(reason, value) => {
                if (reason === "invalidDate") {
                  setErrorEndDate("Keçərsiz tarix formatı");
                } else if (reason === "minDate") {
                  setErrorEndDate(
                    "Bitmə tarixi başlama tarixindən kiçik ola bilməz"
                  );
                } else {
                  setErrorEndDate("");
                }
              }}
              inputFormat="dd/MM/yyyy"
              value={formState?.endDate || null}
              minDate={formState?.startDate}
              onChange={(date) => {
                setFormState({
                  ...formState,
                  endDate: date,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errorEndDate}
                  helperText={errorEndDate || ""}
                  fullWidth
                />
              )}
            />
          </div>
        </div>
      </div> */}

      {/* table */}
      <Grid className="mt-4">
        <Table
          columns={column}
          data={data}
          totalRows={total}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={(filters) => setFilters(filters)}
          cancelAppliedFilters={() => setFilters([])}
          //   downloadHandler={(cols) => downlaodHandler(cols)}
        />
      </Grid>
    </>
  );
}
