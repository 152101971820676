export const validateSelect = (value) => {
  if (!value) return "Seçim edilməyib";
  return "";
};
export const validateNewContractNumber = (value) => {
  if (!value) return "Zəhmət olmasa yeni müqavilə nömrəsi əlavə edin";
  return "";
};
export const validateDate = (value) => {
  if (!value) return "Tarix seçilməyib";
  return "";
};

export const validateReasonforRecall = (value) => {
  if (!value) return "Zəhmət olmasa səbəb qeyd edəsiniz.";
  return "";
};
