import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const ResetGraphicDialog = ({
  id,
  reset,
  title,
  subTitle,
  resetDialog,
  setResetDialog,
}) => {
  const handleDelete = async (id) => {
    reset(id);
  };
  return (
    <Dialog
      open={resetDialog}
      onClose={() => setResetDialog(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {`${title} sıfırlamaq istədiyinizdən əminsiniz ?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`${subTitle} sıfırlanacaq!`}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setResetDialog(false)} color="primary">
          Ləğv et
        </Button>
        <Button
          onClick={() => {
            handleDelete(id);
            setResetDialog(false);
          }}
          color="primary"
        >
          Qrafiki sıfırla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetGraphicDialog;
