import { api } from "./axiosInterceptor";

// ⇩ ⇩ ⇩  Accounting table Requests; ⇩ ⇩ ⇩

export const getAccountingTable_API = async (
  year,
  month,
  structure,
  filter,
  limit,
  skip
) => {
  let url = filter
    ? `employee/time-table?structure=${structure}&year=${year}&month=${month}&filter=${filter}&limit=${limit}&skip=${skip}`
    : `employee/time-table?structure=${structure}&year=${year}&month=${month}&limit=${limit}&skip=${skip}`;
  try {
    return await api.get(url);
  } catch (err) {
    console.error("API Error:", err);
    throw err; // Let the service handle the error
  }
};
