import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bankDetailsErrors } from "../constants/errorMessages";
import { removeEmptyStrings } from "../utils/removeEmptyString";
import { api } from "./axiosInterceptor";

// Get users
export const getUsers = async (username) => {
  const params = {};

  if (username) {
    params.search = username;
  }

  const response = await api.get("users", {
    params: params,
  });
  return response;
};
// Chat section
export const postPrivateChat = async (type, members) => {
  try {
    const response = await api.post("chat", {
      type: type,
      members: members,
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const postGroupChat = async (type, name, members) => {
  try {
    const response = await api.post("chat", {
      type: type,
      name: name,
      members: members,
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getAllChats = async (type, offset) => {
  let limit = 9999;
  const params = {};

  if (type) {
    params.type = type;
  }

  if (offset) {
    params.offset = offset;
  }

  if (limit) {
    params.limit = limit;
  }

  const response = await api
    .get("chat", {
      params: params,
    })
    .catch((err) => {
      err?.response?.data?.errors.map((item) => {
        return toast.error(item.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    });
  return response;
};

export const getSingleChat = async (chatId) => {
  try {
    const response = await api.get(`chat/${chatId}`);
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getMessages = async (chatId, offset, limit) => {
  try {
    const response = await api.get(`chat/${chatId}/messages`, {
      params: {
        offset: offset,
        limit: limit,
      },
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const postMessage = async (chatId, content, attachment = []) => {
  try {
    const response = await api.post(`chat/${chatId}/message`, {
      content: content,
      attachments: attachment,
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const addGroupUsers = async (chatId, userIds) => {
  try {
    const response = await api.put(`chat/${chatId}/addUser`, {
      userIds: userIds,
    });
    toast.success("Uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteGroupUser = async (chatId, userId) => {
  try {
    const response = await api.delete(`chat/${chatId}/removeUser`, {
      data: {
        userId: userId,
      },
    });
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const makeGroupAdmin = async (chatId, content) => {
  try {
    const response = await api.patch(`chat/${chatId}/role`, content);
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const patchChatPicture = async (chatId, content) => {
  try {
    const response = await api.patch(`chat/${chatId}/image`, content);
    toast.success("Şəkil uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteChat = async (chatId) => {
  try {
    const response = await api.delete(`chat/${chatId}`);
    toast.success("Chat uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const markChatAsRead = async (chatId) => {
  try {
    const response = await api.put(`chat/${chatId}/markRead`);
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// All structures

export const getAllStructures = async () => {
  try {
    const response = await api.get("employee/s");
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getNestedChildren = async (structureId, organizationId) => {
  try {
    const response = await api.get("employee/s/child/nested", {
      params: {
        structureId: structureId,
        parentId: organizationId,
      },
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const setOrganizationInfo = async (id, data) => {
  try {
    const response = await api.put(`employee/s/${id}/orgInfo`, data);

    toast.success("Uğurla yeniləndi", {
      toastId: "success-toast-orgInfo",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      if (bankDetailsErrors[item.field[2]]) {
        return toast.error(bankDetailsErrors[item.field[2]].azMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (item.field[0] === "insuranceAccountNumber") {
        return toast.error(
          "Siğorta edənin uçot nömresi ən az 11 simvoldan ibarət olmalıdır",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
      } else if (item.field[0] === "VOEN") {
        return toast.error("VOEN 10 simvoldan ibarət olmalıdır", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }
};

export const setRecruiter = async (id, staffId) => {
  try {
    const response = await api.patch(`employee/s/${id}/recruiter`, {
      staffId: staffId,
    });
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getTopParts = async (params) => {
  try {
    const response = await api.get("employee/s/top-part-rels", {
      params: params,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getPositions = async (params) => {
  try {
    const response = await api.get("employee/s/position-rels", {
      params: params,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getAllStructuresEmptyStaff = async (payload) => {
  try {
    const response = await api.get("employee/s/staff", { params: payload });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error("Hazırda heç bir strukturda boş ştat yoxdur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getStructuresEmptyStaffs = async (payload) => {
  try {
    const response = await api.get("employee/s/staff", { params: payload });
    return response;
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Managers section
export const getManagers = async () => {
  const response = await api.get("employee/m");
  return response;
};

export const postManager = async (name, structure, title) => {
  let payload = {};
  if (!name) {
    payload = { structure: structure, title: title };
  } else {
    payload = { employee: name, structure: structure, title: title };
  }
  try {
    const response = await api.post("employee/m", payload);
    toast.success("Uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const putManager = async (
  managementId,
  structureId,
  employeeId,
  title
) => {
  let payload = {};
  if (!employeeId) {
    payload = { structure: structureId, title: title };
  } else {
    payload = { employee: employeeId, structure: structureId, title: title };
  }
  try {
    const response = await api.put(`employee/m/${managementId}`, payload);
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteManagerById = async (id) => {
  try {
    const response = await api.delete(`employee/m/${id}`);
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Structure naming section
export const getStructureNaming = async () => {
  const response = await api
    .get("employee/naming")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const getStructureByName = async (name) => {
  try {
    const response = await api.get(`employee/naming/n/${name}`);
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteStructureNaming = async (id) => {
  try {
    const response = await api.delete(`employee/naming/${id}`);
    toast.success("Uğurla silindi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateStructureNaming = async (id, name, level) => {
  try {
    const response = await api.put(`employee/naming/${id}`, {
      name,
      level,
    });
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const postStructureNaming = async (name, level) => {
  try {
    const response = await api.post("employee/naming", {
      name,
      level,
    });
    toast.success("Uğurla göndərildi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error("Bu ad artıq mövcudur!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Employee section
export const getAllEmployees = async (offset, limit, filter, forExcel) => {
  const params = {};

  if (offset) {
    params.offset = offset;
  }

  if (limit) {
    params.limit = limit;
  }

  if (filter) {
    params.filter = filter;
  }

  if (forExcel) {
    params.forExcel = forExcel;
  }

  try {
    const response = await api.get(`employee/e`, { params: params });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getEmployeeById = async (id) => {
  try {
    const response = await api.get(`employee/e/${id}`);
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      if (item?.message === "Employee not found") {
        return toast.error("Əməkdaş tapılmadı", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }
};

export const getEmployeeByUserId = async (id) => {
  try {
    const response = await api.get(`employee/e/user/${id}`);
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      console.log(item);
      if (item?.message === "Employee not found") {
        return toast.error("Əməkdaş tapılmadı", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }
};

export const addEmployee = async (values) => {
  try {
    const response = await api.post(`employee/e`, values);
    toast.success("Əməkdaş uğurla əlavə edildi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateEmployee = async (id, values) => {
  try {
    const response = await api.put(`employee/e/${id}`, values);
    toast.success("Əməkdaş uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteEmployeeById = async (id) => {
  try {
    const response = await api.delete(`employee/e/${id}`);
    toast.success("Əməkdaş uğurla silindi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const checkUniqueness = async (field, value, id) => {
  let params = {
    field: field,
    value: value,
    id: id,
  };

  params = removeEmptyStrings(params);

  try {
    const response = await api.get(`employee/e/checkUniqueness`, {
      params: params,
    });
    return response; // Return the response data
  } catch (err) {
    // err.response.data.errors.map((item) => {
    //   return toast.error(item.message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // });
  }
};

// data table

export const getCommonData = async (selectedField, filtered, parentName) => {
  const params = {};

  if (selectedField) {
    params.selectedField = selectedField;
  }

  if (filtered) {
    params.filtered = filtered;
  }

  if (parentName) {
    params.parentName = parentName;
  }
  try {
    const response = await api.get(`employee/common`, {
      params: params,
    });
    return response; // Return the response data
  } catch (err) {
    console.log(err);
    // err?.response?.data?.errors?.map((item) => {
    //   return toast.error("Xəta baş verdi", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // });
  }
};

export const addCommonData = async (payload) => {
  try {
    const response = await api.post(`employee/common`, {
      data: payload,
    });
    toast.success("Uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error("Xəta baş verdi", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
    return err;
  }
};

export const updateCommontData = async (payload) => {
  try {
    const response = await api.patch(`employee/common`, {
      data: payload,
    });
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteCommonData = async (field, value) => {
  const payload = {
    data: [
      {
        field: field,
        value: value,
      },
    ],
  };
  try {
    const response = await api.delete(`employee/common`, {
      data: payload,
    });
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Company parts section
export const getCompanyParts = async (type) => {
  const response = await api.get("employee/p/" + type);
  return response;
};

export const updateCompanyParts = async (name, description, type) => {
  try {
    const response = await api.post("employee/p", {
      name: name,
      description: description,
      type: type,
    });
    toast.success("Uğurla əlavə edildi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error("Bu struktur vahidi artıq mövcudur", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteCompanyPartsById = async (id) => {
  try {
    const response = await api.delete(`employee/p/${id}`);
    toast.success("Uğurla silindi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateCompanyPartsById = async (id, newName, newDescription) => {
  try {
    const response = await api.put(`employee/p/${id}`, {
      name: newName,
      description: newDescription,
    });
    toast.success("Uğurla yeniləndi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Decision Chain

export const getDecisionChains = async () => {
  try {
    const response = await api.get(`employee/decision-chain`);
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getDecisionChainEmployees = async () => {
  try {
    const response = await api.get(`employee/decision-chain/employees`);
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getSingleDecision = async (id) => {
  try {
    const response = await api.get(`employee/decision-chain/${id}`);
    console.log(response.data);

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const createDecisionChain = async (
  structureId,
  positionId,
  approvementChain
) => {
  try {
    const response = await api.post(`employee/decision-chain`, {
      structureId: structureId,
      positionPartRelId: positionId,
      approvementChain: approvementChain,
    });
    toast.success("Uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateDecisionChain = async (
  id,
  structureId,
  positionId,
  approvementChain
) => {
  try {
    const response = await api.put(`employee/decision-chain/${id}`, {
      structureId: structureId,
      positionPartRelId: positionId,
      approvementChain: approvementChain,
    });
    toast.success("Uğurla əlavə edildi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(
        "Təsdiqləm matrisində unikal işçi identifikatorları olmalıdır.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
    });
  }
};

export const deleteDecisionChain = async (id) => {
  try {
    const response = await api.delete(`employee/decision-chain/${id}`);
    toast.success("Uğurla silindi!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getChainlessPart = async (params) => {
  try {
    const response = await api.get(`employee/decision-chain/chainless-parts`, {
      params: params,
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Utils

export const getUtilsEmployees = async (structureId) => {
  try {
    const response = await api.get(`employee/s/${structureId}/employees`);
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Files

export const getFileById = async (fileId) => {
  try {
    const response = await api.get(`file/${fileId}`);
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
