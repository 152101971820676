import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import ButtonCustom from "../Button/ButtonCustom";

const AnchorMenuContainer = styled.div`
  position: relative;
  width: ${({ $width }) => ($width ? $width : "")};
  height: 100%;
`;

const AnchorSubMenu = styled.div`
  position: absolute;
  min-width: 300px;
  top: 100%;
  ${({ $right }) => ($right === undefined || $right !== 0) && "left: 0;"}
  right: ${({ $right }) => $right};
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 5px;
  z-index: 99;
  visibility: ${({ $show }) => ($show ? "visible" : "hidden")};
  background-color: #1b2635;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transform: scale(${({ $show }) => ($show ? "1" : "0")});
  overflow: visible;
  transform-origin: top left;
  animation: ${({ $show }) => ($show ? fadeIn : fadeOut)} 0.15s ease-in-out;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
`;

export default function ButtonDropdown({
  width,
  title,
  dropdownComponent,
  styleDropdown,
  styleContainer,
  styleButton,
  withPureIcon = false,
  styleBtnContainer,
  right = undefined,
  children,
  isShowing,
}) {
  const [showMenu, setShowMenu] = useState(false);

  const submenuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showMenu &&
        submenuRef.current &&
        !submenuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    isShowing && isShowing(showMenu);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showMenu]);

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  return (
    <AnchorMenuContainer width={width} style={styleContainer}>
      {withPureIcon ? (
        <button
          style={{ width: "100%", height: "100%" }}
          buttonRef={buttonRef}
          onClick={toggleMenu}
        >
          {title}
        </button>
      ) : (
        <ButtonCustom
          width={"100%"}
          buttonRef={buttonRef}
          onClick={toggleMenu}
          style={styleButton}
          styleContainer={styleBtnContainer}
        >
          {children ? children : title}
        </ButtonCustom>
      )}
      {
        <AnchorSubMenu
          $right={right}
          $show={showMenu}
          style={styleDropdown}
          ref={submenuRef}
        >
          {dropdownComponent}
        </AnchorSubMenu>
      }
    </AnchorMenuContainer>
  );
}
