import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Avatar,
  Backdrop,
  Box,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getEmployeeById } from "../../api/api";
import DeleteEmployeeDialog from "../../components/editEmployees/DeleteEmployeeDialog";
import ViewAcademicDegree from "../../components/viewEmployee/ViewAcademicDegree";
import ViewBankInfo from "../../components/viewEmployee/ViewBankInfo";
import ViewContactInfo from "../../components/viewEmployee/ViewContactInfo";
import ViewDriveLicenseInfo from "../../components/viewEmployee/ViewDriveLicenseInfo";
import ViewEducationInfo from "../../components/viewEmployee/ViewEducationInfo";
import ViewEmployeeDocuments from "../../components/viewEmployee/ViewEmployeeDocuments";
import ViewInventories from "../../components/viewEmployee/ViewInventories";
import ViewLaborActivity from "../../components/viewEmployee/ViewLaborActivity";
import ViewMilitaryInfo from "../../components/viewEmployee/ViewMilitaryInfo";
import ViewPersonalInformation from "../../components/viewEmployee/ViewPersonalInformation";
import ViewRelationshipInfo from "../../components/viewEmployee/ViewRelationshipInfo";
import ViewRewardReprimands from "../../components/viewEmployee/ViewRewardReprimands";
import ViewSalarySupplements from "../../components/viewEmployee/ViewSalarySupplements";
import ViewSocialStatus from "../../components/viewEmployee/ViewSocialStatus";
import ViewVehicles from "../../components/viewEmployee/ViewVehicles";
import ViewCertification from "../../components/viewEmployee/viewCertification";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Button = styled(MuiButton)(spacing);

function Public() {
  const [showLaborActivity, setShowLaborActivity] = useState(false);
  const [showBankInfo, setShowBankInfo] = useState(false);
  const [showMilitaryInfo, setShowMilitaryInfo] = useState(false);
  const [showDriveLicenseInfo, setShowDriveLicenseInfo] = useState(false);
  const [showRelationshipInfo, setShowRelationshipInfo] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showEducationInfo, setShowEducationInfo] = useState(false);
  const [showAcademicDegree, setShowAcademicDegree] = useState(false);
  const [showSocialStatus, setShowSocialStatus] = useState(false);
  const [showSalarySupplement, setShowSalarySupplement] = useState(false);
  const [showRewardReprimands, setShowRewardReprimands] = useState(false);
  const [showInventories, setShowInventories] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showVehicles, setShowVehicles] = useState(false);
  const [showCertification, setShowCertification] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [employeeById, setEmployeeById] = useState();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getEmployee = async (id) => {
      setLoader(true);

      try {
        const response = await getEmployeeById(id);
        setEmployeeById(response.data);
      } catch {
        setEmployeeById();
      }
      setLoader(false);
    };
    getEmployee(id);
  }, [submitted]);

  if (!employeeById) {
    return <>Loading...</>;
  }
  return (
    <>
      <Card mb={6}>
        <CardContent>
          {employeeById ? (
            <ViewPersonalInformation employeeById={employeeById} />
          ) : null}
        </CardContent>
      </Card>
      {/*Military*/}

      {employeeById?.isForeigner === false && (
        <Card mb={6}>
          <CardContent sx={{ "&:last-child": { pb: 4 } }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowMilitaryInfo(!showMilitaryInfo);
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography variant="h6">
                  {!showMilitaryInfo
                    ? "Hərbi mükəlləfiyyət"
                    : "Hərbi mükəlləfiyyət"}
                </Typography>
              </Box>

              <Box container spacing={2}>
                <Tooltip title="Hərbi mükəlləfiyyət">
                  {showMilitaryInfo ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                  {/* <Switch
                  checked={showBankInfo}
                  onChange={() => {
                    setShowBankInfo(!showBankInfo);
                  }}
                /> */}
                </Tooltip>
              </Box>
            </Box>

            {showMilitaryInfo && (
              <>
                {employeeById.militaryService.length === 0 ? (
                  <Typography>
                    Göstəriləcək hərbi mükəlləfiyyət yoxdur
                  </Typography>
                ) : (
                  <ViewMilitaryInfo employeeById={employeeById} />
                )}
              </>
            )}
          </CardContent>
        </Card>
      )}
      {/*DriveLicense*/}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowDriveLicenseInfo(!showDriveLicenseInfo);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showDriveLicenseInfo
                  ? "Sürücülük vəsiqəsi"
                  : "Sürücülük vəsiqəsi"}
              </Typography>
            </Box>

            <Box container spacing={2}>
              <Tooltip title="Sürücülük vəsiqəsi">
                {showDriveLicenseInfo ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
                {/* <Switch
                  checked={showBankInfo}
                  onChange={() => {
                    setShowBankInfo(!showBankInfo);
                  }}
                /> */}
              </Tooltip>
            </Box>
          </Box>

          {showDriveLicenseInfo && (
            <>
              {employeeById.driverLicense.licenseCategory.length === 0 ? (
                <Typography>Göstəriləcək sürücülük vəsiqəsi yoxdur</Typography>
              ) : (
                <ViewDriveLicenseInfo employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>
      {/* Bank infos */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowBankInfo(!showBankInfo);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showBankInfo ? "Bank məlumatları" : "Bank məlumatları"}
              </Typography>
            </Box>

            <Box container spacing={2}>
              <Tooltip title="Bank məlumatları">
                {showBankInfo ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
                {/* <Switch
                  checked={showBankInfo}
                  onChange={() => {
                    setShowBankInfo(!showBankInfo);
                  }}
                /> */}
              </Tooltip>
            </Box>
          </Box>

          {showBankInfo && (
            <>
              {employeeById.bankInfos.length === 0 ? (
                <Typography>Göstəriləcək bank məlumatı yoxdur</Typography>
              ) : (
                <ViewBankInfo employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Labor activity */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowLaborActivity(!showLaborActivity);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showLaborActivity ? "Əmək fəaliyyəti" : "Əmək fəaliyyəti "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Əmək fəaliyyətini göstər">
                {showLaborActivity ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showLaborActivity && (
            <>
              {employeeById.laborActivity.length === 0 ? (
                <Typography>Göstəriləcək əmək fəaliyyəti yoxdur</Typography>
              ) : (
                <ViewLaborActivity employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Relationship info */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowRelationshipInfo(!showRelationshipInfo);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showRelationshipInfo
                  ? "Qohumluq əlaqəsi"
                  : "Qohumluq əlaqəsi "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Qohumluq əlaqəsi göstər">
                {showRelationshipInfo ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showRelationshipInfo && (
            <>
              {employeeById.relationshipInfo.length === 0 ? (
                <Typography>Göstəriləcək qohumluq əlaqəsi yoxdur</Typography>
              ) : (
                <ViewRelationshipInfo employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Edit Contact Info */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowContactInfo(!showContactInfo);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showContactInfo ? "Əlaqə məlumatları" : "Əlaqə məlumatları "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Əlaqə məlumatlarını göstər">
                {showContactInfo ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showContactInfo && (
            <>
              <ViewContactInfo employeeById={employeeById} />
            </>
          )}
        </CardContent>
      </Card>

      {/* Edit Education Info */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowEducationInfo(!showEducationInfo);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showEducationInfo
                  ? "Təhsil məlumatları"
                  : "Təhsil məlumatları "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Təhsil məlumatlarını göstər">
                {showEducationInfo ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showEducationInfo && (
            <>
              {employeeById.education.length === 0 ? (
                <Typography>Göstəriləcək təhsil məlumatları yoxdur</Typography>
              ) : (
                <ViewEducationInfo employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Academic degree */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowAcademicDegree(!showAcademicDegree);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showBankInfo ? "Elmi dərəcələri" : "Elmi dərəcələri "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Elmi dərəcələrini ">
                {showAcademicDegree ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showAcademicDegree && (
            <>
              {employeeById.academicDegrees.length === 0 ? (
                <Typography>Göstəriləcək elmi dərəcə yoxdur</Typography>
              ) : (
                <ViewAcademicDegree employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Edit Social Status */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowSocialStatus(!showSocialStatus);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showSocialStatus ? "Sosial Status" : "Sosial Statusları "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Sosial Statusları göstər">
                {showSocialStatus ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showSocialStatus && (
            <>
              {employeeById.socialStatus.length === 0 ? (
                <Typography>Göstəriləcək sosial status yoxdur</Typography>
              ) : (
                <ViewSocialStatus employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Supplemental Wages */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowSalarySupplement(!showSalarySupplement);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showBankInfo
                  ? "Əməkhaqqına əlavələr"
                  : "Əməkhaqqına əlavələr "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Əməkhaqqına əlavələri ">
                {showSalarySupplement ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showSalarySupplement && (
            <>
              {employeeById.supplementalWages.length === 0 ? (
                <Typography>Göstəriləcək əməkhaqqına əlavə yoxdur</Typography>
              ) : (
                <ViewSalarySupplements employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Edit Rewards and reprimands*/}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowRewardReprimands(!showRewardReprimands);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showRewardReprimands
                  ? "Mükafat, təltif və tənbehləri"
                  : "Mükafat, təltif və tənbehləri "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Mükafat, təltif və tənbehlərini göstər">
                {showRewardReprimands ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showRewardReprimands && (
            <>
              {employeeById.reprimandsAndRewards.length === 0 ? (
                <Typography>
                  Göstəriləcək mükafat, təltif və tənbeh əlavə yoxdur
                </Typography>
              ) : (
                <ViewRewardReprimands employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Edit Inventories*/}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowInventories(!showInventories);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showRewardReprimands ? "İnventarları" : "İnventarları"}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="İnventarlarını göstər">
                {showInventories ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showInventories && (
            <>
              {employeeById.inventories.length === 0 ? (
                <Typography>Göstəriləcək inventar yoxdur</Typography>
              ) : (
                <ViewInventories employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Edit Documents*/}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowDocuments(!showDocuments);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showDocuments
                  ? "Şəxsi işi - Sənədlər və şəkilləri"
                  : "Şəxsi işi - Sənədlər və şəkilləri "}
              </Typography>
            </Box>

            <Box container spacing={2}>
              {/* <Grid item> */}
              <Tooltip title="Şəxsi işi - Sənədlər və şəkillərini göstər">
                {showDocuments ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showDocuments && (
            <>
              {employeeById.documents.length === 0 ? (
                <Typography>Göstəriləcək sənəd və şəkil yoxdur</Typography>
              ) : (
                <ViewEmployeeDocuments employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Show vehicles */}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowVehicles(!showVehicles);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showVehicles
                  ? "Nəqliyyat Vasitələri"
                  : "Nəqliyyat Vasitələri"}
              </Typography>
            </Box>

            <Box
              container
              spacing={2}
              alignItems="center"
              sx={{ justifyContent: "flex-end" }}
            >
              {/* <Grid item> */}
              <Tooltip title="Nəqliyyat Vasitələri göstər">
                {showVehicles ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showVehicles && (
            <>
              {employeeById.transportVehicles.length === 0 ? (
                <Typography>Göstəriləcək nəqliyyat vasitəsi yoxdur</Typography>
              ) : (
                <ViewVehicles employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/*Edit Certification*/}
      <Card mb={6}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowCertification(!showCertification);
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">
                {!showCertification ? "Sertifikasiya" : "Sertifikasiya"}
              </Typography>
            </Box>

            <Box
              container
              spacing={2}
              alignItems="center"
              sx={{ justifyContent: "flex-end" }}
            >
              {/* <Grid item> */}
              <Tooltip title="Sertifikasiya göstər">
                {showCertification ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Tooltip>
            </Box>
          </Box>

          {showCertification && (
            <>
              {employeeById.certifications.length === 0 ? (
                <Typography>Göstəriləcək sertifikasiya yoxdur</Typography>
              ) : (
                <ViewCertification employeeById={employeeById} />
              )}
            </>
          )}
        </CardContent>
      </Card>

      {/* Buttons */}
      <Grid sx={{ display: "flex", justifyContent: "flex-end", gap: 3 }}>
        <Button
          onClick={() => {
            setDeleteDialog(true);
          }}
          disabled={employeeById.isUsedInStaff}
          variant="outlined"
          color="error"
        >
          Əməkdaşı sil
        </Button>
        <Button
          onClick={() => {
            navigate(`/employees/${employeeById._id}`, { replace: true });
          }}
          variant="contained"
          color="primary"
        >
          Əməkdaş məlumatlarını dəyişdir
        </Button>
      </Grid>

      {/* Delete employee dialog */}
      <DeleteEmployeeDialog
        id={employeeById._id}
        setDeleteDialog={setDeleteDialog}
        deleteDialog={deleteDialog}
        setSubmitted={setSubmitted}
        submitted={submitted}
      />
      <Backdrop open={loader} sx={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

function ViewEmployee() {
  return (
    <React.Fragment>
      <Helmet title="Əməkdaş məlumatlarına bax" />
      <ToastContainer />
      <Typography variant="h3" gutterBottom display="inline">
        Əməkdaş məlumatlarına bax
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Public />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ViewEmployee;
