import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";

export const getContracts = async (limit) => {
  try {
    return await api.get(`/employee/doc-sign/?offset=0&limit=${limit}`);
  } catch (err) {
    err.response.data.errors?.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getContractforSigningDocs = async (
  id,
  groupBy = false,
  modalStatus
) => {
  groupBy = groupBy ? `?groupBy=${groupBy}` : "";
  modalStatus = modalStatus ? `&modalStatus=${modalStatus}` : "";
  const endpoint = `/employee/doc-sign/${id}${groupBy}${modalStatus}`;

  return await api.get(endpoint);
};

export const getFilteredContracts = async (offset, limit, status) => {
  try {
    return await api.get(
      `/employee/doc-sign/filter?offset=${offset}&limit=${limit}&status=${status}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const postCommentSignContract = async (data, id) => {
  return await api.post(`/employee/doc-sign/${id}`, data);
};

export const downloadContractRequestManager = async (params) => {
  return await api.get(`/employee/doc-sign/download`, { params: params });
};

export const getFieldsData = async () => {
  return await api.get(`/employee/common/pagesData`);
};

export const getQRforSima = async (params) => {
  return await api.get(
    `employee/sima/getQr?contractId=${params.id}&description=${params.value}`
  );
};
